import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { DollarSign, Briefcase, Home, Landmark } from 'lucide-react';

const COLORS = ['#4F46E5', '#10B981', '#F59E0B', '#6366F1'];

const mockData = [
  { name: 'Cash & Savings', value: 50000, icon: DollarSign },
  { name: 'Investments', value: 150000, icon: Briefcase },
  { name: 'Real Estate', value: 300000, icon: Home },
  { name: 'Retirement', value: 200000, icon: Landmark },
];

function AssetsSummary() {
  const totalAssets = mockData.reduce((sum, item) => sum + item.value, 0);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-xl font-semibold mb-6">Assets Overview</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={mockData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {mockData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip 
                formatter={(value: number) => [`$${value.toLocaleString()}`, 'Value']}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="space-y-4">
          {mockData.map((item, index) => (
            <div key={item.name} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
              <div className="flex items-center">
                <div className="p-2 rounded-full" style={{ backgroundColor: COLORS[index] + '20' }}>
                  <item.icon className="h-5 w-5" style={{ color: COLORS[index] }} />
                </div>
                <span className="ml-3 font-medium">{item.name}</span>
              </div>
              <div className="text-right">
                <p className="font-semibold">${item.value.toLocaleString()}</p>
                <p className="text-sm text-gray-500">
                  {((item.value / totalAssets) * 100).toFixed(1)}%
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AssetsSummary;