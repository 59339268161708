import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { DollarSign, TrendingUp, FileText, Calculator } from 'lucide-react';
import DownloadButton from './DownloadButton';
import QualitativeInsights from './QualitativeInsights';

interface TaxReportProps {
  timeFrame: string;
}

function TaxReport({ timeFrame }: TaxReportProps) {
  const taxData = [
    { month: 'Jan', income: 6500, taxWithheld: 1625 },
    { month: 'Feb', income: 6800, taxWithheld: 1700 },
    { month: 'Mar', income: 7200, taxWithheld: 1800 },
    { month: 'Apr', income: 6900, taxWithheld: 1725 },
  ];

  const deductionsData = [
    { name: 'Mortgage Interest', value: 12000 },
    { name: 'Charitable Giving', value: 5000 },
    { name: 'Retirement Contributions', value: 19500 },
    { name: 'Other', value: 3500 },
  ];

  const COLORS = ['#B100FF', '#10B981', '#F59E0B', '#6366F1'];

  const insights = [
    {
      type: 'positive' as const,
      title: 'Optimal Tax Withholding',
      description: 'Your current withholding rate is well-aligned with your projected tax liability.',
    },
    {
      type: 'warning' as const,
      title: 'Deduction Opportunity',
      description: 'You may be eligible for additional deductions through increased retirement contributions.',
    },
    {
      type: 'action' as const,
      title: 'Tax Planning Strategy',
      description: 'Consider tax-loss harvesting to offset capital gains and reduce tax liability.',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Tax Analysis</h3>
        <DownloadButton reportId="tax-report" fileName="tax-analysis" />
      </div>
      
      <div id="tax-report" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">YTD Income</h4>
            <p className="text-2xl font-bold">$27,400</p>
            <div className="flex items-center mt-2 text-green-500">
              <TrendingUp className="h-4 w-4 mr-1" />
              <span>+10.8% from last year</span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Tax Withheld</h4>
            <p className="text-2xl font-bold">$6,850</p>
            <p className="text-sm text-gray-500 mt-2">25% effective rate</p>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Total Deductions</h4>
            <p className="text-2xl font-bold">$40,000</p>
            <p className="text-sm text-gray-500 mt-2">Projected for year</p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Income & Tax Trend</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={taxData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="income" fill="#B100FF" name="Income" />
                  <Bar dataKey="taxWithheld" fill="#EF4444" name="Tax Withheld" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Deductions Breakdown</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={deductionsData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {deductionsData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="mt-4 space-y-2">
              {deductionsData.map((deduction, index) => (
                <div key={deduction.name} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      className="w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: COLORS[index % COLORS.length] }}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-300">{deduction.name}</span>
                  </div>
                  <span className="text-sm font-medium">${deduction.value.toLocaleString()}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Insights & Recommendations</h4>
          <QualitativeInsights insights={insights} />
        </div>
      </div>
    </div>
  );
}

export default TaxReport;