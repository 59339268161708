import { create } from 'zustand';
import { addDays } from 'date-fns';
import { DigitalPaymentState, DigitalAccount } from '../types/digitalPayment';

const mockAccounts: DigitalAccount[] = [
  {
    id: '1',
    platform: 'paypal',
    username: 'sarah.johnson',
    email: 'sarah@example.com',
    balance: 1250.75,
    currency: 'USD',
    status: 'active',
    lastUpdated: new Date().toISOString(),
    monthlyActivity: {
      incoming: 2500,
      outgoing: 1750,
    },
    recentTransactions: [
      {
        id: 't1',
        date: new Date().toISOString(),
        description: 'Online Purchase',
        amount: 89.99,
        type: 'outgoing',
        status: 'completed',
        category: 'Shopping',
        counterparty: {
          name: 'Amazon',
          handle: 'amazon',
        },
      },
      {
        id: 't2',
        date: addDays(new Date(), -1).toISOString(),
        description: 'Payment Received',
        amount: 250,
        type: 'incoming',
        status: 'completed',
        category: 'Transfer',
        counterparty: {
          name: 'John Smith',
          handle: 'jsmith',
        },
      },
    ],
    limits: {
      send: 5000,
      receive: 15000,
      daily: 5000,
      remaining: 4910.01,
    },
    linkedCards: [
      {
        id: 'c1',
        type: 'credit',
        last4: '4567',
        expiryDate: '12/25',
        isDefault: true,
      },
    ],
    linkedBanks: [
      {
        id: 'b1',
        bankName: 'Chase',
        accountType: 'checking',
        last4: '8901',
        isDefault: true,
      },
    ],
  },
  {
    id: '2',
    platform: 'venmo',
    username: '@sarahj',
    email: 'sarah@example.com',
    balance: 475.50,
    currency: 'USD',
    status: 'active',
    lastUpdated: new Date().toISOString(),
    monthlyActivity: {
      incoming: 1200,
      outgoing: 850,
    },
    recentTransactions: [
      {
        id: 't3',
        date: new Date().toISOString(),
        description: 'Dinner Split',
        amount: 45.50,
        type: 'incoming',
        status: 'completed',
        category: 'Food & Dining',
        counterparty: {
          name: 'Emily Parker',
          handle: '@emilyp',
          avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
        },
        note: '🍕 Pizza night!',
      },
    ],
    limits: {
      send: 3000,
      receive: 5000,
      daily: 3000,
      remaining: 2954.50,
    },
    linkedCards: [
      {
        id: 'c2',
        type: 'debit',
        last4: '5678',
        expiryDate: '09/26',
        isDefault: true,
      },
    ],
  },
];

export const useDigitalPaymentStore = create<DigitalPaymentState>((set) => ({
  accounts: mockAccounts,
  isLoading: false,
  error: null,

  addAccount: (account) => {
    const newAccount = {
      ...account,
      id: Math.random().toString(36).substr(2, 9),
    };
    set((state) => ({
      accounts: [...state.accounts, newAccount],
    }));
  },

  removeAccount: (id) => {
    set((state) => ({
      accounts: state.accounts.filter((account) => account.id !== id),
    }));
  },

  updateAccount: (id, data) => {
    set((state) => ({
      accounts: state.accounts.map((account) =>
        account.id === id ? { ...account, ...data } : account
      ),
    }));
  },
}));