import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAnalytics, isSupported } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBKDsD8Dmm8wsBLgVnOHhWLsvtqSvnvs8s",
  authDomain: "her-finances.firebaseapp.com",
  databaseURL: "https://her-finances-default-rtdb.firebaseio.com",
  projectId: "her-finances",
  storageBucket: "her-finances.firebasestorage.app",
  messagingSenderId: "270928568938",
  appId: "1:270928568938:web:35b894c089dbe661296013",
  measurementId: "G-7B15R03CXW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Initialize Analytics conditionally
// export const analytics = await isSupported().then((yes) =>
//   yes ? getAnalytics(app) : null
// );

// Connect to emulators in development
// if (import.meta.env.DEV) {
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectFirestoreEmulator(db, "localhost", 8080);
//   connectStorageEmulator(storage, "localhost", 9199);
// }

export default app;
