import React from 'react';
import { Plus } from 'lucide-react';
import { useRetirementStore } from '../../store/retirementStore';
import RetirementOverview from '../../components/retirement/RetirementOverview';
import RetirementAccountCard from '../../components/retirement/RetirementAccountCard';

function RetirementAccountsPage() {
  const { accounts, removeAccount } = useRetirementStore();

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Retirement Accounts</h1>
        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500">
          <Plus className="h-5 w-5 mr-2" />
          Link New Account
        </button>
      </div>

      <RetirementOverview accounts={accounts} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {accounts.map((account) => (
          <RetirementAccountCard
            key={account.id}
            account={account}
            onDelete={removeAccount}
          />
        ))}
      </div>
    </div>
  );
}

export default RetirementAccountsPage;