import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { MentorshipState, Coach, Message } from '../types/mentorship';
import { useAuthStore } from './authStore';

const mockCoach: Coach = {
  id: 'coach1',
  name: 'Emily Parker',
  title: 'Certified Financial Advisor',
  avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  specialties: ['Investment Planning', 'Retirement Strategy', 'Wealth Building'],
  bio: 'Dedicated financial advisor with 10+ years of experience helping women achieve their financial goals.',
  availability: {
    nextAvailable: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
    timezone: 'America/New_York',
  },
  rating: 4.9,
  reviewCount: 128,
};

// Initialize with mock messages for user1
const mockMessages: Message[] = [
  {
    id: '1',
    content: "Welcome to Her Finances! I'm Emily, your personal financial advisor. How can I help you today?",
    senderId: 'coach1',
    receiverId: '1',
    timestamp: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
    read: true,
  },
  {
    id: '2',
    content: "Hi Emily! I'm looking forward to working with you on my financial goals.",
    senderId: '1',
    receiverId: 'coach1',
    timestamp: new Date(Date.now() - 23 * 60 * 60 * 1000).toISOString(),
    read: true,
  },
];

export const useMentorshipStore = create(
  persist<MentorshipState>(
    (set, get) => ({
      messages: mockMessages,
      coach: mockCoach,
      isLoading: false,
      error: null,

      sendMessage: async (content: string, attachments?: File[]) => {
        try {
          set({ isLoading: true });
          // Simulate API delay
          await new Promise(resolve => setTimeout(resolve, 500));

          const { user } = useAuthStore.getState();
          if (!user) throw new Error('User not found');

          const newMessage: Message = {
            id: Math.random().toString(36).substr(2, 9),
            content,
            senderId: user.role === 'admin' ? 'coach1' : user.id,
            receiverId: user.role === 'admin' ? user.id : 'coach1',
            timestamp: new Date().toISOString(),
            read: false,
            attachments: attachments?.map(file => ({
              id: Math.random().toString(36).substr(2, 9),
              type: file.type.startsWith('image/') ? 'image' : 'document',
              url: URL.createObjectURL(file),
              name: file.name,
            })),
          };

          // Add message to the store
          set(state => ({
            messages: [...state.messages, newMessage],
            isLoading: false,
          }));

        } catch (error) {
          set({ error: 'Failed to send message', isLoading: false });
          throw error;
        }
      },

      markAsRead: (messageId: string) => {
        set(state => ({
          messages: state.messages.map(msg =>
            msg.id === messageId ? { ...msg, read: true } : msg
          ),
        }));
      },

      setCoach: (coach: Coach) => set({ coach }),
    }),
    {
      name: 'mentorship-storage',
    }
  )
);