import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { TrendingUp, TrendingDown, DollarSign } from 'lucide-react';
import DownloadButton from './DownloadButton';
import QualitativeInsights from './QualitativeInsights';

interface InvestmentReportProps {
  timeFrame: string;
}

function InvestmentReport({ timeFrame }: InvestmentReportProps) {
  const performanceData = [
    { month: 'Jan', value: 450000 },
    { month: 'Feb', value: 475000 },
    { month: 'Mar', value: 490000 },
    { month: 'Apr', value: 510000 },
  ];

  const allocationData = [
    { name: 'Stocks', value: 300000 },
    { name: 'Bonds', value: 100000 },
    { name: 'Real Estate', value: 80000 },
    { name: 'Cash', value: 30000 },
  ];

  const COLORS = ['#B100FF', '#10B981', '#F59E0B', '#6366F1'];

  const insights = [
    {
      type: 'positive' as const,
      title: 'Strong Portfolio Growth',
      description: 'Your investment portfolio has grown by 13.3% YTD, outperforming the market benchmark.',
    },
    {
      type: 'warning' as const,
      title: 'Asset Allocation',
      description: 'Current stock allocation (60%) is slightly above your target range. Consider rebalancing.',
    },
    {
      type: 'action' as const,
      title: 'Diversification Opportunity',
      description: 'Consider increasing bond allocation to reduce portfolio volatility.',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Investment Performance</h3>
        <DownloadButton reportId="investment-report" fileName="investment-analysis" />
      </div>
      
      <div id="investment-report" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Portfolio Value</h4>
            <p className="text-2xl font-bold">$510,000</p>
            <div className="flex items-center mt-2 text-green-500">
              <TrendingUp className="h-4 w-4 mr-1" />
              <span>+13.3% YTD</span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Today's Change</h4>
            <p className="text-2xl font-bold text-green-500">+$2,500</p>
            <p className="text-sm text-gray-500 mt-2">+0.49% today</p>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Total Return</h4>
            <p className="text-2xl font-bold">$60,000</p>
            <p className="text-sm text-gray-500 mt-2">Since inception</p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Portfolio Performance</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={performanceData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#B100FF" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Asset Allocation</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={allocationData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {allocationData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="mt-4 space-y-2">
              {allocationData.map((asset, index) => (
                <div key={asset.name} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      className="w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: COLORS[index % COLORS.length] }}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-300">{asset.name}</span>
                  </div>
                  <span className="text-sm font-medium">${asset.value.toLocaleString()}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Insights & Recommendations</h4>
          <QualitativeInsights insights={insights} />
        </div>
      </div>
    </div>
  );
}

export default InvestmentReport;