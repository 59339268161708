import React from 'react';
import { Plus } from 'lucide-react';
import { useCommunityStore } from '../../store/communityStore';

function StoriesList() {
  const { stories } = useCommunityStore();

  return (
    <div className="flex space-x-4 overflow-x-auto pb-4">
      <div className="flex-shrink-0">
        <button className="w-20 h-20 rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center hover:border-indigo-500">
          <Plus className="h-8 w-8 text-gray-400" />
        </button>
        <p className="text-xs text-center mt-1">Add Story</p>
      </div>

      {stories.map((story) => (
        <div key={story.id} className="flex-shrink-0">
          <div className="w-20 h-20 rounded-full border-2 border-indigo-500 p-1">
            <img
              src={story.author.avatar}
              alt={story.author.name}
              className="w-full h-full rounded-full object-cover"
            />
          </div>
          <p className="text-xs text-center mt-1 truncate w-20">
            {story.author.name}
          </p>
        </div>
      ))}
    </div>
  );
}

export default StoriesList;