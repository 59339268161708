import React from 'react';

interface CourseProgressBarProps {
  progress: number;
  showLabel?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

function CourseProgressBar({ progress, showLabel = true, size = 'md' }: CourseProgressBarProps) {
  const heights = {
    sm: 'h-1',
    md: 'h-2',
    lg: 'h-3',
  };

  return (
    <div className="w-full">
      {showLabel && (
        <div className="flex justify-between items-center mb-1">
          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Progress</span>
          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{progress}%</span>
        </div>
      )}
      <div className={`w-full bg-gray-200 dark:bg-gray-700 rounded-full ${heights[size]}`}>
        <div
          className="bg-primary-400 rounded-full transition-all duration-300 ease-in-out"
          style={{ width: `${progress}%`, height: '100%' }}
        />
      </div>
    </div>
  );
}

export default CourseProgressBar;