import React, { useState } from 'react';
import { Calculator, DollarSign, Percent, Building2, Briefcase } from 'lucide-react';

function PropertyCalculator() {
  const [purchasePrice, setPurchasePrice] = useState<number>(0);
  const [downPayment, setDownPayment] = useState<number>(20);
  const [interestRate, setInterestRate] = useState<number>(6.5);
  const [loanTerm, setLoanTerm] = useState<number>(30);
  const [monthlyRent, setMonthlyRent] = useState<number>(0);
  const [propertyTax, setPropertyTax] = useState<number>(1.2);
  const [insurance, setInsurance] = useState<number>(1200);
  const [maintenance, setMaintenance] = useState<number>(1);
  const [vacancy, setVacancy] = useState<number>(5);
  const [propertyManagement, setPropertyManagement] = useState<number>(8);
  const [appreciation, setAppreciation] = useState<number>(3);
  const [utilities, setUtilities] = useState<number>(0);

  const calculateInvestment = () => {
    // Calculate loan details
    const loanAmount = purchasePrice * (1 - downPayment / 100);
    const monthlyRate = (interestRate / 100) / 12;
    const numberOfPayments = loanTerm * 12;
    const monthlyMortgage = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / 
                           (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    // Calculate monthly expenses
    const monthlyTax = (purchasePrice * (propertyTax / 100)) / 12;
    const monthlyInsurance = insurance / 12;
    const monthlyMaintenance = (purchasePrice * (maintenance / 100)) / 12;
    const monthlyVacancy = monthlyRent * (vacancy / 100);
    const monthlyManagement = monthlyRent * (propertyManagement / 100);

    const totalMonthlyExpenses = 
      monthlyMortgage +
      monthlyTax +
      monthlyInsurance +
      monthlyMaintenance +
      monthlyVacancy +
      monthlyManagement +
      utilities;

    // Calculate cash flow
    const monthlyCashFlow = monthlyRent - totalMonthlyExpenses;
    const annualCashFlow = monthlyCashFlow * 12;

    // Calculate returns
    const totalInvestment = (purchasePrice * (downPayment / 100)) + (purchasePrice * 0.03); // Including closing costs
    const cashOnCashReturn = (annualCashFlow / totalInvestment) * 100;

    // Calculate 5-year projections
    const fiveYearProjection = Array.from({ length: 5 }, (_, year) => {
      const propertyValue = purchasePrice * Math.pow(1 + appreciation / 100, year + 1);
      const remainingLoanBalance = calculateRemainingLoan(loanAmount, monthlyRate, numberOfPayments, (year + 1) * 12);
      const equity = propertyValue - remainingLoanBalance;
      const yearlyRent = monthlyRent * 12 * Math.pow(1.02, year); // Assuming 2% annual rent increase
      return {
        year: year + 1,
        propertyValue,
        equity,
        yearlyRent
      };
    });

    return {
      monthlyMortgage,
      totalMonthlyExpenses,
      monthlyCashFlow,
      annualCashFlow,
      cashOnCashReturn,
      totalInvestment,
      fiveYearProjection
    };
  };

  const calculateRemainingLoan = (principal: number, monthlyRate: number, totalPayments: number, paymentsMade: number) => {
    return principal * 
           (Math.pow(1 + monthlyRate, totalPayments) - Math.pow(1 + monthlyRate, paymentsMade)) /
           (Math.pow(1 + monthlyRate, totalPayments) - 1);
  };

  const results = purchasePrice > 0 ? calculateInvestment() : null;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Purchase Price
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={purchasePrice}
                onChange={(e) => setPurchasePrice(Number(e.target.value))}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter purchase price"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Down Payment (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={downPayment}
                  onChange={(e) => setDownPayment(Number(e.target.value))}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter down payment"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Interest Rate (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(Number(e.target.value))}
                  step="0.1"
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter interest rate"
                />
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Monthly Rental Income
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={monthlyRent}
                onChange={(e) => setMonthlyRent(Number(e.target.value))}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter monthly rent"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Property Tax Rate (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={propertyTax}
                  onChange={(e) => setPropertyTax(Number(e.target.value))}
                  step="0.1"
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter property tax rate"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Annual Insurance
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={insurance}
                  onChange={(e) => setInsurance(Number(e.target.value))}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter annual insurance"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Maintenance (% of Value)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={maintenance}
                  onChange={(e) => setMaintenance(Number(e.target.value))}
                  step="0.1"
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter maintenance percentage"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Monthly Utilities
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={utilities}
                  onChange={(e) => setUtilities(Number(e.target.value))}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter monthly utilities"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Vacancy Rate (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={vacancy}
                  onChange={(e) => setVacancy(Number(e.target.value))}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter vacancy rate"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Property Management (%)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Percent className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={propertyManagement}
                  onChange={(e) => setPropertyManagement(Number(e.target.value))}
                  className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter management fee"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
          <h3 className="text-lg font-medium mb-6">Investment Analysis</h3>
          
          {results ? (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                    Monthly Cash Flow
                  </p>
                  <p className={`text-2xl font-bold ${
                    results.monthlyCashFlow >= 0 ? 'text-green-500' : 'text-red-500'
                  }`}>
                    ${results.monthlyCashFlow.toFixed(2)}
                  </p>
                </div>

                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                    Cash on Cash Return
                  </p>
                  <p className={`text-2xl font-bold ${
                    results.cashOnCashReturn >= 0 ? 'text-green-500' : 'text-red-500'
                  }`}>
                    {results.cashOnCashReturn.toFixed(2)}%
                  </p>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Monthly Expenses Breakdown
                </h4>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500 dark:text-gray-400">Mortgage</span>
                    <span className="font-medium">${results.monthlyMortgage.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500 dark:text-gray-400">Total Expenses</span>
                    <span className="font-medium">${results.totalMonthlyExpenses.toFixed(2)}</span>
                  </div>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  5-Year Projections
                </h4>
                <div className="space-y-3">
                  {results.fiveYearProjection.map((year) => (
                    <div key={year.year} className="bg-white dark:bg-gray-800 rounded-lg p-3">
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-medium">Year {year.year}</span>
                        <span className="text-sm text-gray-500">
                          Property Value: ${year.propertyValue.toFixed(0)}
                        </span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500">Equity</span>
                        <span className="font-medium">${year.equity.toFixed(0)}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500">Annual Rent</span>
                        <span className="font-medium">${year.yearlyRent.toFixed(0)}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <Building2 className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500 dark:text-gray-400">
                Enter property details to see investment analysis
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PropertyCalculator;