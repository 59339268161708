import React, { useState } from 'react';
import { useClientStore } from '../../store/clientStore';
import { JourneyPhase, ClientJourney } from '../../types/client';
import ClientCard from '../../components/admin/ClientCard';
import ClientDetailsModal from '../../components/admin/ClientDetailsModal';

const phases: JourneyPhase[] = ['onboarding', 'audit', 'strategy', 'implementation', 'review'];

const phaseLabels: Record<JourneyPhase, string> = {
  onboarding: 'Onboarding',
  audit: 'Financial Audit',
  strategy: 'Strategy Development',
  implementation: 'Implementation',
  review: 'Review & Adjust',
};

function ClientPortalPage() {
  const { journeys, updateJourneyPhase } = useClientStore();
  const [selectedJourney, setSelectedJourney] = useState<ClientJourney | null>(null);

  const getJourneysByPhase = (phase: JourneyPhase) => {
    return journeys.filter(journey => journey.phase === phase);
  };

  const handlePhaseChange = (userId: string, newPhase: JourneyPhase) => {
    updateJourneyPhase(userId, newPhase);
  };

  return (
    <div className="h-full">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Client Journey Board</h1>
        <p className="text-gray-600 dark:text-gray-400 mt-2">
          Track and manage client progress through their financial journey
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
        {phases.map((phase) => (
          <div key={phase} className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-lg font-medium">{phaseLabels[phase]}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {getJourneysByPhase(phase).length} clients
                </p>
              </div>
            </div>
            <div className="space-y-4 min-h-[200px]">
              {getJourneysByPhase(phase).map((journey) => (
                <ClientCard
                  key={journey.id}
                  journey={journey}
                  onClick={() => setSelectedJourney(journey)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {selectedJourney && (
        <ClientDetailsModal
          journey={selectedJourney}
          onClose={() => setSelectedJourney(null)}
          onPhaseChange={(newPhase) => {
            handlePhaseChange(selectedJourney.userId, newPhase);
            // Update the selected journey with the new phase
            setSelectedJourney({
              ...selectedJourney,
              phase: newPhase,
              lastUpdated: new Date().toISOString(),
            });
          }}
        />
      )}
    </div>
  );
}

export default ClientPortalPage;