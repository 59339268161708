import React, { useState } from 'react';
import { Play, CheckCircle, Clock, ChevronLeft, ChevronRight, Undo, ChevronsLeft, ChevronsRight } from 'lucide-react';
import { Course } from '../../types/courses';
import CourseProgressBar from './CourseProgressBar';
import VideoPlayer from './VideoPlayer';

interface CoursePlayerProps {
  course: Course;
  initialModuleIndex?: number;
  initialLessonIndex?: number;
  onLessonComplete: (moduleId: string, lessonId: string) => void;
  onClose: () => void;
}

function CoursePlayer({ 
  course, 
  initialModuleIndex = 0, 
  initialLessonIndex = 0,
  onLessonComplete, 
  onClose 
}: CoursePlayerProps) {
  const [currentModuleIndex, setCurrentModuleIndex] = useState(initialModuleIndex);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(initialLessonIndex);
  const [showUndoToast, setShowUndoToast] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const currentModule = course.modules[currentModuleIndex];
  const currentLesson = currentModule?.lessons[currentLessonIndex];

  const handleLessonComplete = () => {
    if (currentModule && currentLesson) {
      onLessonComplete(currentModule.id, currentLesson.id);
      
      if (!currentLesson.completed) {
        setShowUndoToast(true);
        setTimeout(() => setShowUndoToast(false), 3000);
      }
    }
  };

  const goToNextLesson = () => {
    if (currentLessonIndex < currentModule.lessons.length - 1) {
      setCurrentLessonIndex(currentLessonIndex + 1);
    } else if (currentModuleIndex < course.modules.length - 1) {
      setCurrentModuleIndex(currentModuleIndex + 1);
      setCurrentLessonIndex(0);
    }
  };

  const goToPreviousLesson = () => {
    if (currentLessonIndex > 0) {
      setCurrentLessonIndex(currentLessonIndex - 1);
    } else if (currentModuleIndex > 0) {
      setCurrentModuleIndex(currentModuleIndex - 1);
      setCurrentLessonIndex(course.modules[currentModuleIndex - 1].lessons.length - 1);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen flex bg-gray-100 dark:bg-gray-900">
      {/* Course Content Sidebar */}
      <div 
        className={`${
          isSidebarOpen ? 'w-80' : 'w-0'
        } bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 overflow-hidden transition-all duration-300`}
      >
        <div className="h-full overflow-y-auto">
          <div className="p-4">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Course Content</h3>
            <div className="space-y-4">
              {course.modules.map((module, moduleIdx) => (
                <div key={module.id} className="rounded-lg border border-gray-200 dark:border-gray-700">
                  <div className="p-4 bg-gray-50 dark:bg-gray-700/50">
                    <div className="flex items-center justify-between mb-2">
                      <h4 className="font-medium text-gray-900 dark:text-white">{module.title}</h4>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {module.lessons.filter(l => l.completed).length}/{module.lessons.length}
                      </span>
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{module.description}</p>
                  </div>
                  <div className="divide-y divide-gray-200 dark:divide-gray-700">
                    {module.lessons.map((lesson, lessonIdx) => (
                      <button
                        key={lesson.id}
                        onClick={() => {
                          setCurrentModuleIndex(moduleIdx);
                          setCurrentLessonIndex(lessonIdx);
                        }}
                        className={`w-full flex items-center p-3 text-left hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors ${
                          currentModuleIndex === moduleIdx && currentLessonIndex === lessonIdx
                            ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-400'
                            : ''
                        }`}
                      >
                        <div className="flex-1">
                          <div className="flex items-center">
                            {lesson.completed ? (
                              <CheckCircle className="h-4 w-4 text-green-500 mr-2 flex-shrink-0" />
                            ) : (
                              <Play className="h-4 w-4 text-gray-400 mr-2 flex-shrink-0" />
                            )}
                            <span className="text-sm font-medium text-gray-900 dark:text-white line-clamp-1">
                              {lesson.title}
                            </span>
                          </div>
                          <div className="flex items-center mt-1 text-xs text-gray-500 dark:text-gray-400">
                            <Clock className="h-3 w-3 mr-1" />
                            {lesson.duration}
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col min-w-0">
        {/* Header */}
        <div className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 px-4 py-4 flex items-center justify-between">
          <div className="flex items-center">
            <button
              onClick={onClose}
              className="mr-4 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition-colors"
            >
              <ChevronLeft className="h-6 w-6" />
            </button>
            <button
              onClick={toggleSidebar}
              className="mr-4 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition-colors"
            >
              {isSidebarOpen ? (
                <ChevronsLeft className="h-5 w-5" />
              ) : (
                <ChevronsRight className="h-5 w-5" />
              )}
            </button>
            <div>
              <h1 className="text-lg font-medium text-gray-900 dark:text-white">{course.title}</h1>
              <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                <span>{currentModule.title}</span>
                <span className="mx-2">•</span>
                <span>{currentLesson.title}</span>
              </div>
            </div>
          </div>
          <div className="w-48">
            <CourseProgressBar progress={course.progress} size="sm" />
          </div>
        </div>

        {/* Video Player */}
        <div className="relative bg-black aspect-video">
          <VideoPlayer
            videoUrl={currentLesson.videoUrl}
            onEnded={handleLessonComplete}
            poster={course.thumbnail}
          />
        </div>

        {/* Lesson Navigation */}
        <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={goToPreviousLesson}
            disabled={currentModuleIndex === 0 && currentLessonIndex === 0}
            className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 disabled:opacity-50 transition-colors"
          >
            <ChevronLeft className="h-5 w-5 mr-2" />
            Previous Lesson
          </button>
          <button
            onClick={handleLessonComplete}
            className={`px-4 py-2 text-sm font-medium rounded-md transition-colors ${
              currentLesson.completed
                ? 'text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600'
                : 'text-white bg-primary-400 hover:bg-primary-500 dark:hover:bg-primary-600'
            }`}
          >
            {currentLesson.completed ? (
              <span className="flex items-center">
                <Undo className="h-4 w-4 mr-2" />
                Mark as Incomplete
              </span>
            ) : (
              'Mark as Complete'
            )}
          </button>
          <button
            onClick={goToNextLesson}
            disabled={currentModuleIndex === course.modules.length - 1 && currentLessonIndex === currentModule.lessons.length - 1}
            className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 disabled:opacity-50 transition-colors"
          >
            Next Lesson
            <ChevronRight className="h-5 w-5 ml-2" />
          </button>
        </div>

        {/* Lesson Content */}
        <div className="flex-1 overflow-y-auto p-6 bg-white dark:bg-gray-800">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">{currentLesson.title}</h2>
          <p className="text-gray-600 dark:text-gray-300">{currentLesson.description}</p>
        </div>
      </div>

      {/* Undo Toast */}
      {showUndoToast && (
        <div className="fixed bottom-4 right-4 bg-gray-900 dark:bg-gray-700 text-white px-6 py-3 rounded-lg shadow-lg flex items-center space-x-4 animate-fade-in">
          <span>Lesson marked as complete</span>
          <button
            onClick={handleLessonComplete}
            className="text-primary-400 hover:text-primary-300 font-medium"
          >
            Undo
          </button>
        </div>
      )}
    </div>
  );
}

export default CoursePlayer;