import React from 'react';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { BudgetItem } from '../../types/budget';
import { format } from 'date-fns';

interface BudgetCategoryListProps {
  budgetItems: BudgetItem[];
}

function BudgetCategoryList({ budgetItems }: BudgetCategoryListProps) {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Budget Categories</h2>
      
      <div className="space-y-4">
        {budgetItems.map((item) => {
          const percentage = (item.spent / item.limit) * 100;
          const isOverBudget = item.spent > item.limit;

          return (
            <div key={item.id} className="space-y-2">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white capitalize">
                    {item.name}
                  </h3>
                  <p className="text-sm text-gray-500">
                    ${item.spent.toLocaleString()} of ${item.limit.toLocaleString()}
                  </p>
                </div>
                <span className={`text-sm font-medium ${
                  isOverBudget ? 'text-red-500' : 'text-green-500'
                }`}>
                  {item.remaining >= 0 ? 
                    `$${item.remaining.toLocaleString()} left` : 
                    `$${Math.abs(item.remaining).toLocaleString()} over`
                  }
                </span>
              </div>

              <div className="relative">
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className={`h-2 rounded-full ${
                      percentage >= 100
                        ? 'bg-red-500'
                        : percentage >= 80
                        ? 'bg-yellow-500'
                        : 'bg-green-500'
                    }`}
                    style={{ width: `${Math.min(percentage, 100)}%` }}
                  />
                </div>
              </div>

              {item.transactions.length > 0 && (
                <div className="mt-2 space-y-1">
                  {item.transactions.slice(0, 3).map((transaction) => (
                    <div
                      key={transaction.id}
                      className="flex items-center justify-between text-sm px-2 py-1 hover:bg-gray-50 dark:hover:bg-gray-700 rounded"
                    >
                      <div className="flex items-center space-x-2">
                        {transaction.type === 'expense' ? (
                          <ArrowUpRight className="h-4 w-4 text-red-500" />
                        ) : (
                          <ArrowDownRight className="h-4 w-4 text-green-500" />
                        )}
                        <span className="text-gray-600 dark:text-gray-300">
                          {transaction.description}
                        </span>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className={`font-medium ${
                          transaction.type === 'expense' ? 'text-red-500' : 'text-green-500'
                        }`}>
                          {transaction.type === 'expense' ? '-' : '+'}
                          ${transaction.amount.toLocaleString()}
                        </span>
                        <span className="text-gray-400">
                          {format(new Date(transaction.date), 'MMM d')}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BudgetCategoryList;