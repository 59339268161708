import React, { useState } from 'react';
import { format } from 'date-fns';
import { X, Clock, CheckCircle, ChevronDown, MessageCircle } from 'lucide-react';
import { ClientJourney, JourneyPhase } from '../../types/client';
import { useNavigate } from 'react-router-dom';
import { useClientStore } from '../../store/clientStore';

interface ClientDetailsModalProps {
  journey: ClientJourney;
  onClose: () => void;
  onPhaseChange: (newPhase: JourneyPhase) => void;
}

const phases: JourneyPhase[] = ['onboarding', 'audit', 'strategy', 'implementation', 'review'];

const phaseLabels: Record<JourneyPhase, string> = {
  onboarding: 'Onboarding',
  audit: 'Financial Audit',
  strategy: 'Strategy Development',
  implementation: 'Implementation',
  review: 'Review & Adjust',
};

function ClientDetailsModal({ journey: initialJourney, onClose, onPhaseChange }: ClientDetailsModalProps) {
  const navigate = useNavigate();
  const { updateTask, journeys } = useClientStore();
  const [localJourney, setLocalJourney] = useState(initialJourney);

  // Get the latest journey data from the store
  const currentJourney = journeys.find(j => j.id === initialJourney.id) || localJourney;

  const handleViewFullProfile = () => {
    navigate(`/admin/users/${currentJourney.userId}`);
  };

  const handleViewChat = () => {
    navigate(`/admin/mentorship?userId=${currentJourney.userId}`);
  };

  const handlePhaseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPhase = event.target.value as JourneyPhase;
    onPhaseChange(newPhase);
    // Update local state immediately for smooth UI update
    setLocalJourney(prev => ({
      ...prev,
      phase: newPhase,
      lastUpdated: new Date().toISOString(),
    }));
  };

  const handleTaskToggle = (taskId: string, completed: boolean) => {
    updateTask(currentJourney.userId, taskId, completed);
    // Update local state immediately
    setLocalJourney(prev => ({
      ...prev,
      tasks: prev.tasks.map(task =>
        task.id === taskId ? { ...task, completed } : task
      ),
    }));
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />
        
        <div 
          className="relative w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center space-x-4">
              <img
                src={currentJourney.user.avatar}
                alt={currentJourney.user.name}
                className="h-12 w-12 rounded-full"
              />
              <div>
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {currentJourney.user.name}
                </h2>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {currentJourney.user.email}
                </p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          {/* Content */}
          <div className="p-6 space-y-6">
            {/* Quick Actions */}
            <div className="flex justify-between items-center">
              <div className="relative">
                <select
                  value={currentJourney.phase}
                  onChange={handlePhaseChange}
                  className="appearance-none bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-lg px-4 py-2 pr-10 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-primary-400"
                >
                  {phases.map((phase) => (
                    <option key={phase} value={phase}>
                      {phaseLabels[phase]}
                    </option>
                  ))}
                </select>
                <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
              </div>
              <div className="flex space-x-3">
                <button
                  onClick={handleViewChat}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400"
                >
                  <MessageCircle className="h-5 w-5 mr-2" />
                  View Chat
                </button>
                <button
                  onClick={handleViewFullProfile}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500"
                >
                  View Full Profile
                </button>
              </div>
            </div>

            {/* Progress */}
            <div>
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-medium">Journey Progress</h3>
                <span className="text-sm font-medium">{currentJourney.progress}%</span>
              </div>
              <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                <div
                  className="bg-primary-400 rounded-full h-2 transition-all duration-300"
                  style={{ width: `${currentJourney.progress}%` }}
                />
              </div>
            </div>

            {/* Journey Details */}
            <div className="grid grid-cols-2 gap-6">
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Started Journey
                </h3>
                <p className="text-base font-medium">
                  {format(new Date(currentJourney.startDate), 'MMM d, yyyy')}
                </p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Last Activity
                </h3>
                <p className="text-base font-medium">
                  {format(new Date(currentJourney.lastUpdated), 'MMM d, yyyy')}
                </p>
              </div>
            </div>

            {/* Next Milestone */}
            <div>
              <h3 className="text-lg font-medium mb-4">Next Milestone</h3>
              <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <span className="font-medium">{currentJourney.nextMilestone.title}</span>
                  <span className="text-sm text-gray-500">
                    Due {format(new Date(currentJourney.nextMilestone.dueDate), 'MMM d, yyyy')}
                  </span>
                </div>
              </div>
            </div>

            {/* Tasks */}
            <div>
              <h3 className="text-lg font-medium mb-4">Current Tasks</h3>
              <div className="space-y-3">
                {currentJourney.tasks.map((task) => (
                  <div
                    key={task.id}
                    className="flex items-center justify-between bg-gray-50 dark:bg-gray-700 rounded-lg p-4"
                  >
                    <div className="flex items-center space-x-3">
                      <button
                        onClick={() => handleTaskToggle(task.id, !task.completed)}
                        className="focus:outline-none"
                      >
                        {task.completed ? (
                          <CheckCircle className="h-5 w-5 text-green-500" />
                        ) : (
                          <Clock className="h-5 w-5 text-yellow-500" />
                        )}
                      </button>
                      <span className="font-medium">{task.title}</span>
                    </div>
                    <span className="text-sm text-gray-500">
                      Due {format(new Date(task.dueDate), 'MMM d')}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Notes */}
            <div>
              <h3 className="text-lg font-medium mb-4">Journey Notes</h3>
              <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                <p className="whitespace-pre-line">{currentJourney.notes}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailsModal;