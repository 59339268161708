import React from 'react';
import { format } from 'date-fns';
import { useAuthStore } from '../../store/authStore';

interface ChatMessageProps {
  message: {
    id: string;
    content: string;
    senderId: string;
    receiverId: string;
    timestamp: string;
    read: boolean;
  };
}

function ChatMessage({ message }: ChatMessageProps) {
  const { user } = useAuthStore();
  const isAdmin = user?.role === 'admin';
  
  // For admin view: messages from coach1 should be on the right
  // For user view: messages from the user should be on the right
  const isOwnMessage = isAdmin ? 
    message.senderId === 'coach1' : 
    message.senderId === user?.id;

  return (
    <div className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'} mb-4`}>
      <div className={`flex ${isOwnMessage ? 'flex-row-reverse' : 'flex-row'} items-end max-w-md`}>
        {!isOwnMessage && (
          <img
            src={message.senderId === 'coach1' ? 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80' : ''}
            alt="Avatar"
            className="h-8 w-8 rounded-full mx-2"
          />
        )}
        <div
          className={`${
            isOwnMessage
              ? 'bg-primary-400 text-white rounded-l-xl rounded-tr-xl'
              : 'bg-gray-100 text-gray-900 rounded-r-xl rounded-tl-xl'
          } px-4 py-2 shadow-sm`}
        >
          <p className="text-sm">{message.content}</p>
          <p className={`text-xs mt-1 ${isOwnMessage ? 'text-indigo-100' : 'text-gray-500'}`}>
            {format(new Date(message.timestamp), 'h:mm a')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;