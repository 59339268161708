import { create } from 'zustand';
import { AdminState } from '../types/admin';

// Mock users data
const mockUsers = [
  {
    id: '1',
    name: 'Sarah Johnson',
    email: 'sarah@example.com',
    role: 'user',
    status: 'active',
    createdAt: '2024-01-15T00:00:00.000Z',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: '2',
    name: 'Emily Parker',
    email: 'emily@example.com',
    role: 'admin',
    status: 'active',
    createdAt: '2024-02-01T00:00:00.000Z',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
];

export const useAdminStore = create<AdminState>((set) => ({
  users: mockUsers,
  stats: {
    totalUsers: 1250,
    activeUsers: 876,
    totalRevenue: 125000,
    monthlyGrowth: 15.7,
    courseEnrollments: 450,
    averageEngagement: 78.5,
    ticketsOpen: 12,
    ticketsResolved: 48,
  },
  roles: [],
  activities: [],
  contentReports: [],
  financialReports: [],
  supportTickets: [],
  isLoading: false,
  error: null,

  addUser: async (userData) => {
    try {
      set({ isLoading: true });
      // Simulate API delay
      await new Promise(resolve => setTimeout(resolve, 500));

      const newUser = {
        id: Math.random().toString(36).substr(2, 9),
        ...userData,
        status: 'active',
        createdAt: new Date().toISOString(),
        avatar: `https://images.unsplash.com/photo-${Math.random().toString(36).substr(2, 9)}?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`,
      };

      set(state => ({
        users: [...state.users, newUser],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: 'Failed to add user', isLoading: false });
      throw error;
    }
  },

  updateUserRole: async (userId, roleId) => {
    try {
      set({ isLoading: true });
      set(state => ({
        users: state.users.map(user =>
          user.id === userId ? { ...user, role: roleId } : user
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: 'Failed to update user role', isLoading: false });
      throw error;
    }
  },

  deleteUser: async (userId) => {
    try {
      set({ isLoading: true });
      set(state => ({
        users: state.users.filter(user => user.id !== userId),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: 'Failed to delete user', isLoading: false });
      throw error;
    }
  },

  updateUserStatus: async (userId, status) => {
    try {
      set({ isLoading: true });
      set(state => ({
        users: state.users.map(user =>
          user.id === userId ? { ...user, status } : user
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: 'Failed to update user status', isLoading: false });
      throw error;
    }
  },
}));