import { z } from 'zod';

export type BudgetCategory = 
  | 'housing'
  | 'transportation'
  | 'food'
  | 'utilities'
  | 'healthcare'
  | 'insurance'
  | 'entertainment'
  | 'shopping'
  | 'savings'
  | 'debt'
  | 'other';

export interface BudgetItem {
  id: string;
  category: BudgetCategory;
  name: string;
  limit: number;
  spent: number;
  remaining: number;
  transactions: Transaction[];
}

export interface Transaction {
  id: string;
  date: string;
  description: string;
  amount: number;
  category: BudgetCategory;
  type: 'expense' | 'income';
}

export type SavingsGoalType = 
  | 'emergency_fund'
  | 'large_purchase'
  | 'debt_payoff'
  | 'vacation'
  | 'education'
  | 'wedding'
  | 'home'
  | 'other';

export interface SavingsGoal {
  id: string;
  name: string;
  type: SavingsGoalType;
  targetAmount: number;
  currentAmount: number;
  targetDate: string;
  progress: number;
  createdAt: string;
  status: 'active' | 'completed' | 'on_hold';
  automate: boolean;
  contributions: {
    amount: number;
    frequency: 'weekly' | 'bi_weekly' | 'monthly';
    nextContribution?: string;
  };
}

export interface BudgetState {
  budgetItems: BudgetItem[];
  savingsGoals: SavingsGoal[];
  transactions: Transaction[];
  isLoading: boolean;
  error: string | null;
  addBudgetItem: (item: Omit<BudgetItem, 'id' | 'spent' | 'remaining' | 'transactions'>) => void;
  updateBudgetItem: (id: string, data: Partial<BudgetItem>) => void;
  deleteBudgetItem: (id: string) => void;
  addSavingsGoal: (goal: Omit<SavingsGoal, 'id' | 'progress' | 'createdAt'>) => void;
  updateSavingsGoal: (id: string, data: Partial<SavingsGoal>) => void;
  deleteSavingsGoal: (id: string) => void;
  addTransaction: (transaction: Omit<Transaction, 'id'>) => void;
}

// Form Schemas
export const budgetItemSchema = z.object({
  category: z.enum(['housing', 'transportation', 'food', 'utilities', 'healthcare', 'insurance', 'entertainment', 'shopping', 'savings', 'debt', 'other']),
  name: z.string().min(2, 'Name must be at least 2 characters'),
  limit: z.number().min(0, 'Limit must be greater than 0'),
});

export const savingsGoalSchema = z.object({
  name: z.string().min(2, 'Name must be at least 2 characters'),
  type: z.enum(['emergency_fund', 'large_purchase', 'debt_payoff', 'vacation', 'education', 'wedding', 'home', 'other']),
  targetAmount: z.number().min(0, 'Target amount must be greater than 0'),
  targetDate: z.string(),
  automate: z.boolean().optional(),
  contributions: z.object({
    amount: z.number().min(0, 'Contribution amount must be greater than 0'),
    frequency: z.enum(['weekly', 'bi_weekly', 'monthly']),
  }),
});

export type BudgetItemFormData = z.infer<typeof budgetItemSchema>;
export type SavingsGoalFormData = z.infer<typeof savingsGoalSchema>;