import React, { useState } from 'react';
import { Building2, DollarSign, TrendingUp, Search, Filter, ArrowUpDown, Home, MapPin, Receipt, Wrench } from 'lucide-react';
import PropertyCard from './components/PropertyCard';
import PropertyDetailsModal from './components/PropertyDetailsModal';
import { Property } from '../../types/property';

function RealEstatePage() {
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);

  const properties: Property[] = [
    {
      id: '1',
      address: '123 Main Street',
      city: 'San Francisco',
      state: 'CA',
      zipCode: '94105',
      type: 'residential',
      propertyType: 'single_family',
      purchaseDate: '2020-06-15',
      purchasePrice: 850000,
      currentValue: 975000,
      squareFeet: 2200,
      yearBuilt: 2005,
      bedrooms: 3,
      bathrooms: 2.5,
      parking: 2,
      lotSize: '0.25 acres',
      status: 'owned',
      mortgage: {
        lender: 'Wells Fargo',
        originalAmount: 680000,
        currentBalance: 620000,
        interestRate: 3.25,
        monthlyPayment: 2950,
        escrow: 850,
        nextPaymentDate: '2024-04-01',
        maturityDate: '2050-06-15',
      },
      rental: {
        isRented: true,
        monthlyRent: 4500,
        leaseStart: '2023-07-01',
        leaseEnd: '2024-06-30',
        securityDeposit: 9000,
        tenantName: 'John Smith',
        tenantContact: 'john.smith@email.com',
      },
      expenses: {
        propertyTax: 8500,
        insurance: 2400,
        utilities: 200,
        maintenance: 3600,
        management: 2700,
        other: 1200,
      },
      improvements: [
        {
          id: 'imp1',
          description: 'Kitchen Renovation',
          date: '2022-08-15',
          cost: 45000,
          category: 'renovation',
          contractor: 'ABC Renovations',
          warranty: '5 years',
        },
        {
          id: 'imp2',
          description: 'HVAC Replacement',
          date: '2023-03-20',
          cost: 12000,
          category: 'maintenance',
          contractor: 'Cool Air Systems',
          warranty: '10 years',
        },
      ],
      documents: [
        {
          id: 'doc1',
          name: 'Purchase Agreement',
          type: 'legal',
          date: '2020-06-15',
          url: '#',
        },
        {
          id: 'doc2',
          name: 'Insurance Policy',
          type: 'insurance',
          date: '2024-01-01',
          url: '#',
        },
      ],
      images: [
        {
          id: 'img1',
          url: 'https://images.unsplash.com/photo-1518780664697-55e3ad937233',
          type: 'exterior',
          date: '2024-01-15',
        },
      ],
    },
    {
      id: '2',
      address: '456 Oak Avenue',
      city: 'San Francisco',
      state: 'CA',
      zipCode: '94110',
      type: 'residential',
      propertyType: 'single_family',
      purchaseDate: '2021-03-15',
      purchasePrice: 1200000,
      currentValue: 1350000,
      squareFeet: 2800,
      yearBuilt: 2010,
      bedrooms: 4,
      bathrooms: 3,
      parking: 2,
      lotSize: '0.3 acres',
      status: 'owned',
      mortgage: {
        lender: 'Chase',
        originalAmount: 960000,
        currentBalance: 920000,
        interestRate: 2.875,
        monthlyPayment: 3985,
        escrow: 1200,
        nextPaymentDate: '2024-04-01',
        maturityDate: '2051-03-15',
      },
      rental: null, // Primary residence, not rented
      expenses: {
        propertyTax: 12000,
        insurance: 3600,
        utilities: 450,
        maintenance: 4800,
        management: 0, // No property management since it's owner-occupied
        other: 1500,
      },
      improvements: [
        {
          id: 'imp3',
          description: 'Solar Panel Installation',
          date: '2022-05-10',
          cost: 35000,
          category: 'upgrade',
          contractor: 'Solar Solutions Inc',
          warranty: '25 years',
        },
        {
          id: 'imp4',
          description: 'Bathroom Remodel',
          date: '2023-09-15',
          cost: 28000,
          category: 'renovation',
          contractor: 'Luxury Baths Co',
          warranty: '5 years',
        },
        {
          id: 'imp5',
          description: 'Smart Home System',
          date: '2023-11-20',
          cost: 15000,
          category: 'upgrade',
          contractor: 'Smart Living Technologies',
          warranty: '2 years',
        },
      ],
      documents: [
        {
          id: 'doc3',
          name: 'Purchase Agreement',
          type: 'legal',
          date: '2021-03-15',
          url: '#',
        },
        {
          id: 'doc4',
          name: 'Home Insurance Policy',
          type: 'insurance',
          date: '2024-01-01',
          url: '#',
        },
        {
          id: 'doc5',
          name: 'Property Tax Assessment',
          type: 'tax',
          date: '2024-01-15',
          url: '#',
        },
        {
          id: 'doc6',
          name: 'Solar Panel Warranty',
          type: 'other',
          date: '2022-05-10',
          url: '#',
        },
      ],
      images: [
        {
          id: 'img2',
          url: 'https://images.unsplash.com/photo-1564013799919-ab600027ffc6',
          type: 'exterior',
          date: '2024-01-15',
        },
        {
          id: 'img3',
          url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c',
          type: 'interior',
          date: '2024-01-15',
        },
      ],
    },
  ];

  const totalValue = properties.reduce((sum, prop) => sum + prop.currentValue, 0);
  const totalEquity = properties.reduce((sum, prop) => sum + (prop.currentValue - (prop.mortgage?.currentBalance || 0)), 0);
  const totalRentalIncome = properties.reduce((sum, prop) => sum + (prop.rental?.monthlyRent || 0), 0);
  const totalExpenses = properties.reduce((sum, prop) => {
    const expenses = prop.expenses;
    return sum + Object.values(expenses).reduce((a, b) => a + b, 0);
  }, 0);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search properties..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <Filter className="h-5 w-5 mr-2" />
            Filter
          </button>
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <ArrowUpDown className="h-5 w-5 mr-2" />
            Sort
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Portfolio Value</h3>
            <Building2 className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalValue.toLocaleString()}</p>
          <div className="flex items-center mt-2 text-green-500">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+14.7% YTD</span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Equity</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalEquity.toLocaleString()}</p>
          <p className="text-sm text-gray-500 mt-2">Across all properties</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Monthly Income</h3>
            <Receipt className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalRentalIncome.toLocaleString()}</p>
          <p className="text-sm text-gray-500 mt-2">Total rental income</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Annual Expenses</h3>
            <Wrench className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalExpenses.toLocaleString()}</p>
          <p className="text-sm text-gray-500 mt-2">Maintenance & fees</p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {properties.map((property) => (
          <PropertyCard
            key={property.id}
            property={property}
            onClick={() => setSelectedProperty(property)}
          />
        ))}
      </div>

      {selectedProperty && (
        <PropertyDetailsModal
          property={selectedProperty}
          onClose={() => setSelectedProperty(null)}
        />
      )}
    </div>
  );
}

export default RealEstatePage;