import React from 'react';
import { TrendingUp, TrendingDown, Search, Filter, ArrowUpDown, Bitcoin, DollarSign, Clock } from 'lucide-react';

function CryptoPage() {
  const cryptoHoldings = [
    {
      id: '1',
      symbol: 'BTC',
      name: 'Bitcoin',
      quantity: 2.5,
      avgCost: 35000,
      currentPrice: 68500,
      value: 171250,
      gain: 84750,
      gainPercent: 98.14,
      wallet: 'Hardware Wallet',
      platform: 'Coinbase',
      lastTransaction: '2024-02-15',
    },
    {
      id: '2',
      symbol: 'ETH',
      name: 'Ethereum',
      quantity: 15,
      avgCost: 2200,
      currentPrice: 3850,
      value: 57750,
      gain: 24750,
      gainPercent: 75.00,
      wallet: 'MetaMask',
      platform: 'Binance',
      lastTransaction: '2024-03-01',
    },
    {
      id: '3',
      symbol: 'SOL',
      name: 'Solana',
      quantity: 250,
      avgCost: 85,
      currentPrice: 145,
      value: 36250,
      gain: 15000,
      gainPercent: 70.59,
      wallet: 'Phantom',
      platform: 'FTX',
      lastTransaction: '2024-03-10',
    },
  ];

  const totalValue = cryptoHoldings.reduce((sum, crypto) => sum + crypto.value, 0);
  const totalGain = cryptoHoldings.reduce((sum, crypto) => sum + crypto.gain, 0);
  const totalCost = cryptoHoldings.reduce((sum, crypto) => sum + (crypto.avgCost * crypto.quantity), 0);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search cryptocurrencies..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <Filter className="h-5 w-5 mr-2" />
            Filter
          </button>
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <ArrowUpDown className="h-5 w-5 mr-2" />
            Sort
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Portfolio Value</h3>
            <Bitcoin className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalValue.toLocaleString()}</p>
          <div className="flex items-center mt-2 text-green-500">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+${totalGain.toLocaleString()} Total Return</span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">24h Change</h3>
            <Clock className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold text-green-500">+$12,450</p>
          <p className="text-sm text-gray-500 mt-2">+4.8% Today</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Cost Basis</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalCost.toLocaleString()}</p>
          <p className="text-sm text-gray-500 mt-2">
            ROI: +{((totalValue - totalCost) / totalCost * 100).toFixed(2)}%
          </p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium">Crypto Holdings</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Asset
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Holdings
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Avg Cost
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Price
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Market Value
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Wallet/Platform
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Total Return
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {cryptoHoldings.map((crypto) => (
                <tr key={crypto.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="font-medium text-gray-900 dark:text-white">{crypto.symbol}</div>
                      <div className="text-sm text-gray-500">{crypto.name}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {crypto.quantity.toLocaleString()} {crypto.symbol}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${crypto.avgCost.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${crypto.currentPrice.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${crypto.value.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm text-gray-900 dark:text-white">{crypto.wallet}</div>
                      <div className="text-sm text-gray-500">{crypto.platform}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex flex-col items-end">
                      <div className="flex items-center text-green-500">
                        <TrendingUp className="h-4 w-4 mr-1" />
                        <span>${crypto.gain.toLocaleString()}</span>
                      </div>
                      <span className="text-sm text-green-500">
                        +{crypto.gainPercent.toFixed(2)}%
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CryptoPage;