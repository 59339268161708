import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { TrendingUp, TrendingDown, DollarSign, PiggyBank, CreditCard, Target } from 'lucide-react';
import DownloadButton from './DownloadButton';
import QualitativeInsights from './QualitativeInsights';

interface ReportsOverviewProps {
  timeFrame: string;
}

function ReportsOverview({ timeFrame }: ReportsOverviewProps) {
  const netWorthData = [
    { month: 'Jan', assets: 450000, liabilities: 250000, netWorth: 200000 },
    { month: 'Feb', assets: 475000, liabilities: 245000, netWorth: 230000 },
    { month: 'Mar', assets: 490000, liabilities: 240000, netWorth: 250000 },
    { month: 'Apr', assets: 510000, liabilities: 235000, netWorth: 275000 },
  ];

  const assetAllocation = [
    { name: 'Real Estate', value: 350000 },
    { name: 'Investments', value: 100000 },
    { name: 'Cash', value: 50000 },
    { name: 'Other', value: 10000 },
  ];

  const COLORS = ['#B100FF', '#10B981', '#F59E0B', '#6366F1'];

  const insights = [
    {
      type: 'positive' as const,
      title: 'Strong Net Worth Growth',
      description: 'Your net worth has increased by 37.5% over the past quarter.',
    },
    {
      type: 'warning' as const,
      title: 'Spending Pattern',
      description: 'Discretionary spending has increased by 15% this month.',
    },
    {
      type: 'action' as const,
      title: 'Investment Opportunity',
      description: 'Consider rebalancing portfolio to maintain target allocation.',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Financial Overview</h3>
        <DownloadButton reportId="reports-overview" fileName="financial-overview" />
      </div>
      
      <div id="reports-overview" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Net Worth</h4>
            <p className="text-2xl font-bold">$275,000</p>
            <div className="flex items-center mt-2 text-green-500">
              <TrendingUp className="h-4 w-4 mr-1" />
              <span>+37.5% YTD</span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Monthly Savings</h4>
            <p className="text-2xl font-bold">$3,700</p>
            <p className="text-sm text-gray-500 mt-2">46.25% savings rate</p>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Investment Returns</h4>
            <p className="text-2xl font-bold text-green-500">+12.5%</p>
            <p className="text-sm text-gray-500 mt-2">YTD Performance</p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Net Worth Trend</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={netWorthData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="assets" stroke="#10B981" name="Assets" />
                  <Line type="monotone" dataKey="liabilities" stroke="#EF4444" name="Liabilities" />
                  <Line type="monotone" dataKey="netWorth" stroke="#B100FF" name="Net Worth" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Asset Allocation</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={assetAllocation}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {assetAllocation.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="mt-4 space-y-2">
              {assetAllocation.map((asset, index) => (
                <div key={asset.name} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      className="w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: COLORS[index % COLORS.length] }}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-300">{asset.name}</span>
                  </div>
                  <span className="text-sm font-medium">${asset.value.toLocaleString()}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Key Metrics</h4>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <DollarSign className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-600 dark:text-gray-300">Debt-to-Income Ratio</span>
                </div>
                <span className="text-sm font-medium">28%</span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <PiggyBank className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-600 dark:text-gray-300">Emergency Fund Coverage</span>
                </div>
                <span className="text-sm font-medium">4.5 months</span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <CreditCard className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-600 dark:text-gray-300">Credit Utilization</span>
                </div>
                <span className="text-sm font-medium">15%</span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <Target className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-sm text-gray-600 dark:text-gray-300">Investment Goals Progress</span>
                </div>
                <span className="text-sm font-medium">65%</span>
              </div>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Insights & Recommendations</h4>
            <QualitativeInsights insights={insights} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsOverview;