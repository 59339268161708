import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { Loan } from '../../types/loan';
import { format } from 'date-fns';

interface LoanOverviewProps {
  loans: Loan[];
}

function LoanOverview({ loans }: LoanOverviewProps) {
  const totalBalance = loans.reduce((sum, loan) => sum + loan.currentBalance, 0);
  const totalPrincipal = loans.reduce((sum, loan) => sum + loan.principalBalance, 0);
  const totalInterest = loans.reduce((sum, loan) => sum + loan.interestBalance, 0);
  const monthlyPayments = loans.reduce((sum, loan) => sum + loan.paymentAmount, 0);

  const chartData = loans.map(loan => ({
    name: loan.type.charAt(0).toUpperCase() + loan.type.slice(1),
    value: loan.currentBalance,
  }));

  const COLORS = ['#B100FF', '#10B981', '#F59E0B', '#6366F1'];

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Loans Overview</h2>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value: number) => [
                  `$${value.toLocaleString()}`,
                  'Balance'
                ]}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-500 dark:text-gray-400">Total Balance</p>
              <p className="text-xl font-bold text-gray-900 dark:text-white">
                ${totalBalance.toLocaleString()}
              </p>
            </div>
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-500 dark:text-gray-400">Monthly Payments</p>
              <p className="text-xl font-bold text-gray-900 dark:text-white">
                ${monthlyPayments.toLocaleString()}
              </p>
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">Principal vs Interest</p>
              <p className="text-sm font-medium text-gray-900 dark:text-white">
                {((totalPrincipal / totalBalance) * 100).toFixed(1)}% Principal
              </p>
            </div>
            <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
              <div
                className="bg-primary-400 h-2 rounded-full"
                style={{ width: `${(totalPrincipal / totalBalance) * 100}%` }}
              />
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <p className="text-sm text-gray-500 dark:text-gray-400">Upcoming Payments</p>
            <div className="mt-2 space-y-2">
              {loans.map((loan) => (
                <div key={loan.id} className="flex justify-between items-center">
                  <span className="text-sm text-gray-900 dark:text-white capitalize">
                    {loan.type} Loan
                  </span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    ${loan.paymentAmount.toLocaleString()} on {format(new Date(loan.nextPaymentDate), 'MMM d')}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanOverview;