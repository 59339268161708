import React from 'react';
import { format } from 'date-fns';
import { CreditCard, ExternalLink, Trash2, DollarSign } from 'lucide-react';
import { BankAccount } from '../../types/bank';

interface AccountCardProps {
  account: BankAccount;
  onDelete: (id: string) => void;
}

function AccountCard({ account, onDelete }: AccountCardProps) {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transition-all hover:shadow-xl">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
            <CreditCard className="h-6 w-6 text-primary-400" />
          </div>
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">{account.bankName}</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">{account.accountName}</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onDelete(account.id)}
            className="p-2 text-gray-400 hover:text-red-500 rounded-lg transition-colors"
          >
            <Trash2 className="h-5 w-5" />
          </button>
          <button className="p-2 text-gray-400 hover:text-primary-500 rounded-lg transition-colors">
            <ExternalLink className="h-5 w-5" />
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <p className="text-sm text-gray-500 dark:text-gray-400">Current Balance</p>
          <div className="flex items-center mt-1">
            <DollarSign className="h-5 w-5 text-gray-400" />
            <p className="text-xl font-bold text-gray-900 dark:text-white">
              {account.balance.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 text-sm">
          <div>
            <p className="text-gray-500 dark:text-gray-400">Account Number</p>
            <p className="font-medium text-gray-900 dark:text-white">{account.accountNumber}</p>
          </div>
          <div>
            <p className="text-gray-500 dark:text-gray-400">Type</p>
            <p className="font-medium text-gray-900 dark:text-white capitalize">
              {account.type.replace('_', ' ')}
            </p>
          </div>
          {account.interestRate && (
            <div>
              <p className="text-gray-500 dark:text-gray-400">Interest Rate</p>
              <p className="font-medium text-gray-900 dark:text-white">{account.interestRate}%</p>
            </div>
          )}
          <div>
            <p className="text-gray-500 dark:text-gray-400">Last Updated</p>
            <p className="font-medium text-gray-900 dark:text-white">
              {format(new Date(account.lastUpdated), 'MMM d, yyyy')}
            </p>
          </div>
        </div>

        <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-500 dark:text-gray-400">Status</span>
            <span className={`px-2 py-1 rounded-full text-xs font-medium ${
              account.status === 'active'
                ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400'
                : 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-400'
            }`}>
              {account.status.charAt(0).toUpperCase() + account.status.slice(1)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountCard;