import React from 'react';
import RealEstateTracker from '../components/real-estate/RealEstateTracker';

function RealEstatePage() {
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Real Estate Portfolio</h1>
      <RealEstateTracker />
    </div>
  );
}

export default RealEstatePage;