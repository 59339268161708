import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { TrendingUp, Wallet, CreditCard } from 'lucide-react';
import DownloadButton from './DownloadButton';
import QualitativeInsights from './QualitativeInsights';

interface NetWorthReportProps {
  timeFrame: string;
}

function NetWorthReport({ timeFrame }: NetWorthReportProps) {
  const netWorthData = [
    { month: 'Jan', assets: 450000, liabilities: 250000 },
    { month: 'Feb', assets: 475000, liabilities: 245000 },
    { month: 'Mar', assets: 490000, liabilities: 240000 },
    { month: 'Apr', assets: 510000, liabilities: 235000 },
  ];

  const assetBreakdown = [
    { name: 'Real Estate', value: 350000 },
    { name: 'Investments', value: 100000 },
    { name: 'Cash', value: 50000 },
    { name: 'Other', value: 10000 },
  ];

  const COLORS = ['#B100FF', '#10B981', '#F59E0B', '#6366F1'];

  const insights = [
    {
      type: 'positive' as const,
      title: 'Strong Net Worth Growth',
      description: 'Your net worth has increased by 15% over the past year.',
    },
    {
      type: 'warning' as const,
      title: 'Asset Concentration',
      description: 'Consider diversifying assets as real estate represents 70% of total assets.',
    },
    {
      type: 'action' as const,
      title: 'Debt Reduction Opportunity',
      description: 'Accelerating mortgage payments could significantly improve net worth.',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Net Worth Analysis</h3>
        <DownloadButton reportId="net-worth-report" fileName="net-worth-analysis" />
      </div>
      
      <div id="net-worth-report" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Total Assets</h4>
            <p className="text-2xl font-bold">$510,000</p>
            <div className="flex items-center mt-2 text-green-500">
              <TrendingUp className="h-4 w-4 mr-1" />
              <span>+13.3% YTD</span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Total Liabilities</h4>
            <p className="text-2xl font-bold">$235,000</p>
            <div className="flex items-center mt-2 text-green-500">
              <TrendingUp className="h-4 w-4 mr-1" />
              <span>-6% YTD</span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Net Worth</h4>
            <p className="text-2xl font-bold">$275,000</p>
            <p className="text-sm text-gray-500 mt-2">+32.5% YTD</p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Net Worth Trend</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={netWorthData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="assets" stroke="#10B981" name="Assets" />
                  <Line type="monotone" dataKey="liabilities" stroke="#EF4444" name="Liabilities" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Asset Breakdown</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={assetBreakdown}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {assetBreakdown.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="mt-4 space-y-2">
              {assetBreakdown.map((asset, index) => (
                <div key={asset.name} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      className="w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: COLORS[index % COLORS.length] }}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-300">{asset.name}</span>
                  </div>
                  <span className="text-sm font-medium">${asset.value.toLocaleString()}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Insights & Recommendations</h4>
          <QualitativeInsights insights={insights} />
        </div>
      </div>
    </div>
  );
}

export default NetWorthReport;