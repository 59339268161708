import React from 'react';
import { format } from 'date-fns';
import { Wallet, ExternalLink, Trash2, CreditCard, Building2, ArrowUpRight, ArrowDownLeft } from 'lucide-react';
import { DigitalAccount, Transaction } from '../../types/digitalPayment';

interface DigitalAccountCardProps {
  account: DigitalAccount;
  onDelete: (id: string) => void;
}

function DigitalAccountCard({ account, onDelete }: DigitalAccountCardProps) {
  const platformIcons = {
    paypal: '💰',
    venmo: '📱',
    cashapp: '💵',
    zelle: '⚡',
    apple_pay: '🍎',
    google_pay: '🤖',
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transition-all hover:shadow-xl">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg text-2xl">
            {platformIcons[account.platform]}
          </div>
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white capitalize">
              {account.platform.replace('_', ' ')}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">{account.username}</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onDelete(account.id)}
            className="p-2 text-gray-400 hover:text-red-500 rounded-lg transition-colors"
          >
            <Trash2 className="h-5 w-5" />
          </button>
          <button className="p-2 text-gray-400 hover:text-primary-500 rounded-lg transition-colors">
            <ExternalLink className="h-5 w-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <p className="text-sm text-gray-500 dark:text-gray-400">Available Balance</p>
          <p className="text-xl font-bold text-gray-900 dark:text-white">
            ${account.balance.toLocaleString()}
          </p>
        </div>
        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <p className="text-sm text-gray-500 dark:text-gray-400">Monthly Activity</p>
          <div className="flex justify-between mt-1">
            <span className="text-sm text-green-500">+${account.monthlyActivity.incoming.toLocaleString()}</span>
            <span className="text-sm text-red-500">-${account.monthlyActivity.outgoing.toLocaleString()}</span>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {(account.linkedCards?.length > 0 || account.linkedBanks?.length > 0) && (
          <div className="grid grid-cols-2 gap-4">
            {account.linkedCards?.map((card) => (
              <div key={card.id} className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                <div className="flex items-center space-x-2">
                  <CreditCard className="h-4 w-4 text-gray-400" />
                  <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white capitalize">
                      {card.type} Card
                    </p>
                    <p className="text-xs text-gray-500">
                      ****{card.last4} • Exp: {card.expiryDate}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            {account.linkedBanks?.map((bank) => (
              <div key={bank.id} className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                <div className="flex items-center space-x-2">
                  <Building2 className="h-4 w-4 text-gray-400" />
                  <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {bank.bankName}
                    </p>
                    <p className="text-xs text-gray-500">
                      {bank.accountType} ****{bank.last4}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
          <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
            Recent Transactions
          </h4>
          <div className="space-y-2">
            {account.recentTransactions.map((transaction) => (
              <div
                key={transaction.id}
                className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <div className="flex items-center space-x-3">
                  {transaction.type === 'incoming' ? (
                    <ArrowDownLeft className="h-4 w-4 text-green-500" />
                  ) : (
                    <ArrowUpRight className="h-4 w-4 text-red-500" />
                  )}
                  <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {transaction.description}
                    </p>
                    <div className="flex items-center text-xs text-gray-500">
                      <span>{format(new Date(transaction.date), 'MMM d, yyyy')}</span>
                      {transaction.category && (
                        <>
                          <span className="mx-1">•</span>
                          <span>{transaction.category}</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <p className={`text-sm font-medium ${
                  transaction.type === 'incoming' ? 'text-green-500' : 'text-red-500'
                }`}>
                  {transaction.type === 'incoming' ? '+' : '-'}$
                  {transaction.amount.toLocaleString()}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex justify-between items-center text-sm">
            <span className="text-gray-500 dark:text-gray-400">Daily Limit Remaining</span>
            <span className="font-medium text-gray-900 dark:text-white">
              ${account.limits.remaining.toLocaleString()}
            </span>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2 mt-2">
            <div
              className="bg-primary-400 h-2 rounded-full"
              style={{
                width: `${(account.limits.remaining / account.limits.daily) * 100}%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DigitalAccountCard;