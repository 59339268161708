import { create } from 'zustand';
import { addMonths, addDays } from 'date-fns';
import { LoanState, Loan } from '../types/loan';

const mockLoans: Loan[] = [
  {
    id: '1',
    type: 'mortgage',
    lender: 'Wells Fargo',
    accountNumber: '****7890',
    originalAmount: 350000,
    currentBalance: 325000,
    principalBalance: 315000,
    interestBalance: 10000,
    interestRate: 4.5,
    apr: 4.75,
    originationDate: '2023-01-15',
    termLength: 360, // 30 years
    termRemaining: 348,
    paymentAmount: 1774.08,
    paymentFrequency: 'monthly',
    nextPaymentDate: addDays(new Date(), 15).toISOString(),
    status: 'active',
    paymentHistory: [
      {
        id: 'p1',
        date: addMonths(new Date(), -1).toISOString(),
        amount: 1774.08,
        principalPaid: 1200,
        interestPaid: 574.08,
        totalRemaining: 325000,
        status: 'paid',
      }
    ],
    additionalDetails: {
      prepaymentPenalty: false,
      gracePeriod: 15,
    },
  },
  {
    id: '2',
    type: 'student',
    lender: 'Sallie Mae',
    accountNumber: '****4567',
    originalAmount: 50000,
    currentBalance: 35000,
    principalBalance: 32000,
    interestBalance: 3000,
    interestRate: 5.8,
    apr: 6.1,
    originationDate: '2020-09-01',
    termLength: 120, // 10 years
    termRemaining: 80,
    paymentAmount: 550.33,
    paymentFrequency: 'monthly',
    nextPaymentDate: addDays(new Date(), 10).toISOString(),
    status: 'active',
    paymentHistory: [
      {
        id: 'p2',
        date: addMonths(new Date(), -1).toISOString(),
        amount: 550.33,
        principalPaid: 450,
        interestPaid: 100.33,
        totalRemaining: 35000,
        status: 'paid',
      }
    ],
    additionalDetails: {
      defermentEligible: true,
      forbearanceEligible: true,
    },
  }
];

export const useLoanStore = create<LoanState>((set, get) => ({
  loans: mockLoans,
  isLoading: false,
  error: null,

  addLoan: (loan) => {
    const newLoan = {
      ...loan,
      id: Math.random().toString(36).substr(2, 9),
    };
    set((state) => ({
      loans: [...state.loans, newLoan],
    }));
  },

  removeLoan: (id) => {
    set((state) => ({
      loans: state.loans.filter((loan) => loan.id !== id),
    }));
  },

  updateLoan: (id, data) => {
    set((state) => ({
      loans: state.loans.map((loan) =>
        loan.id === id ? { ...loan, ...data } : loan
      ),
    }));
  },

  makePayment: (loanId, amount) => {
    const loan = get().loans.find((l) => l.id === loanId);
    if (!loan) return;

    const interestPortion = (loan.interestRate / 100 / 12) * loan.currentBalance;
    const principalPortion = amount - interestPortion;
    const newBalance = loan.currentBalance - principalPortion;

    const payment: LoanPayment = {
      id: Math.random().toString(36).substr(2, 9),
      date: new Date().toISOString(),
      amount,
      principalPaid: principalPortion,
      interestPaid: interestPortion,
      totalRemaining: newBalance,
      status: 'paid',
    };

    set((state) => ({
      loans: state.loans.map((l) =>
        l.id === loanId
          ? {
              ...l,
              currentBalance: newBalance,
              principalBalance: l.principalBalance - principalPortion,
              interestBalance: l.interestBalance - interestPortion,
              paymentHistory: [payment, ...l.paymentHistory],
            }
          : l
      ),
    }));
  },
}));