import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Home,
  Wallet,
  LineChart,
  PiggyBank,
  BarChart2,
  Wrench,
  Crown,
  Settings,
  BookOpen,
  MessageCircle,
  Users,
  CreditCard,
  Building,
  Landmark,
  Briefcase,
  LayoutDashboard,
  TrendingUp,
  Coins,
  Building2,
  Bitcoin,
} from 'lucide-react';
import { User } from '../../types/user';

interface SidebarProps {
  userOverride?: User;
  onCloseMobileMenu?: () => void;
}

function Sidebar({ userOverride, onCloseMobileMenu }: SidebarProps) {
  const location = useLocation();

  const navigation = [
    { name: 'Home', icon: Home, href: '/' },
    {
      name: 'Accounts',
      icon: Wallet,
      href: '#',
      submenu: [
        { name: 'Overview', icon: LayoutDashboard, href: '/accounts' },
        { name: 'Bank Accounts', icon: Wallet, href: '/accounts/bank' },
        { name: 'Credit Cards', icon: CreditCard, href: '/accounts/credit' },
        { name: 'Loans', icon: Building, href: '/accounts/loans' },
        { name: 'Retirement Accounts', icon: Landmark, href: '/accounts/retirement' },
        { name: 'Other Accounts', icon: Briefcase, href: '/accounts/other' },
      ],
    },
    {
      name: 'Investments',
      icon: LineChart,
      href: '#',
      submenu: [
        { name: 'Overview', icon: LayoutDashboard, href: '/investments' },
        { name: 'Stocks', icon: TrendingUp, href: '/investments/stocks' },
        { name: 'Bonds', icon: Landmark, href: '/investments/bonds' },
        { name: 'Commodities', icon: Coins, href: '/investments/commodities' },
        { name: 'Real Estate', icon: Building2, href: '/investments/real-estate' },
        { name: 'Crypto', icon: Bitcoin, href: '/investments/crypto' },
        { name: 'Other', icon: Briefcase, href: '/investments/other' },
      ],
    },
    { name: 'Budgeting & Savings', icon: PiggyBank, href: '/budget' },
    { name: 'Reports & Insights', icon: BarChart2, href: '/reports' },
    { name: 'Tools', icon: Wrench, href: '/tools' },
    {
      name: 'Premium Features',
      icon: Crown,
      href: '#',
      submenu: [
        { name: 'Courses', icon: BookOpen, href: '/courses' },
        { name: 'Mentorship', icon: MessageCircle, href: '/mentorship' },
        { name: 'Community', icon: Users, href: '/community' },
      ],
    },
  ];

  const [openSubmenu, setOpenSubmenu] = React.useState<string | null>(
    navigation.find(item => 
      item.submenu?.some(subItem => location.pathname.startsWith(subItem.href))
    )?.name || null
  );

  const toggleSubmenu = (name: string) => {
    setOpenSubmenu(openSubmenu === name ? null : name);
  };

  const isActive = (href: string) => location.pathname === href;
  const isSubmenuActive = (submenu: any[]) => 
    submenu?.some(item => location.pathname.startsWith(item.href));

  const handleClick = () => {
    if (onCloseMobileMenu) {
      onCloseMobileMenu();
    }
  };

  return (
    <div className="h-full w-72 bg-white dark:bg-gray-900 border-r border-gray-200 dark:border-gray-800 overflow-y-auto">
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-center flex-shrink-0 px-6 py-8">
          <img 
            src="https://i.imghippo.com/files/dl9858rW.png"
            alt="Her Finances Logo"
            className="h-10 w-auto"
          />
        </div>
        
        <div className="flex-1 px-3 space-y-1">
          {navigation.map((item) => (
            <div key={item.name}>
              {item.submenu ? (
                <>
                  <button
                    onClick={() => toggleSubmenu(item.name)}
                    className={`
                      w-full flex items-center justify-between px-4 py-3 text-sm font-medium rounded-lg transition-colors duration-150 ease-in-out
                      ${isSubmenuActive(item.submenu)
                        ? 'bg-primary-50 dark:bg-gray-800 text-primary-400'
                        : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800'
                      }
                    `}
                  >
                    <div className="flex items-center">
                      <item.icon className={`h-5 w-5 mr-3 ${
                        isSubmenuActive(item.submenu) ? 'text-primary-400' : 'text-gray-400'
                      }`} />
                      {item.name}
                    </div>
                    <svg
                      className={`w-4 h-4 transition-transform ${
                        openSubmenu === item.name ? 'transform rotate-180' : ''
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  <div className={`pl-11 mt-2 space-y-1 ${openSubmenu === item.name ? 'block' : 'hidden'}`}>
                    {item.submenu.map((subItem) => (
                      <Link
                        key={subItem.name}
                        to={subItem.href}
                        onClick={handleClick}
                        className={`
                          flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-150 ease-in-out
                          ${isActive(subItem.href)
                            ? 'bg-primary-50 dark:bg-gray-800 text-primary-400'
                            : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800'
                          }
                        `}
                      >
                        <subItem.icon className={`h-4 w-4 mr-3 ${
                          isActive(subItem.href) ? 'text-primary-400' : 'text-gray-400'
                        }`} />
                        {subItem.name}
                      </Link>
                    ))}
                  </div>
                </>
              ) : (
                <Link
                  to={item.href}
                  onClick={handleClick}
                  className={`
                    flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors duration-150 ease-in-out
                    ${isActive(item.href)
                      ? 'bg-primary-50 dark:bg-gray-800 text-primary-400'
                      : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800'
                    }
                  `}
                >
                  <item.icon className={`h-5 w-5 mr-3 ${
                    isActive(item.href) ? 'text-primary-400' : 'text-gray-400'
                  }`} />
                  {item.name}
                </Link>
              )}
            </div>
          ))}
        </div>

        <div className="mt-auto px-3 pb-6">
          <Link
            to="/settings"
            onClick={handleClick}
            className={`
              flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors duration-150 ease-in-out
              ${isActive('/settings')
                ? 'bg-primary-50 dark:bg-gray-800 text-primary-400'
                : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800'
              }
            `}
          >
            <Settings className={`h-5 w-5 mr-3 ${
              isActive('/settings') ? 'text-primary-400' : 'text-gray-400'
            }`} />
            Settings
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;