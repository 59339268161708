import React, { useState } from 'react';
import { useCourseStore } from '../../store/courseStore';
import { Plus, Edit, Trash2, BookOpen } from 'lucide-react';
import AddCourseModal from '../../components/admin/AddCourseModal';

function CourseManagementPage() {
  const { courses, deleteCourse } = useCourseStore();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Course Management</h1>
        <button 
          onClick={() => setIsAddModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Course
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {courses.map((course) => (
          <div
            key={course.id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
          >
            <div className="relative h-48">
              <img
                src={course.thumbnail}
                alt={course.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute top-4 right-4 flex space-x-2">
                <button 
                  onClick={() => setEditingCourse(course)}
                  className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
                >
                  <Edit className="h-4 w-4 text-gray-600" />
                </button>
                <button 
                  onClick={() => deleteCourse(course.id)}
                  className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
                >
                  <Trash2 className="h-4 w-4 text-red-600" />
                </button>
              </div>
            </div>

            <div className="p-6">
              <div className="flex items-center mb-2">
                <span className="text-sm font-medium text-primary-400 bg-primary-50 dark:bg-primary-900/20 px-2 py-1 rounded">
                  {course.category}
                </span>
              </div>
              <h3 className="text-lg font-semibold mb-2">{course.title}</h3>
              <p className="text-gray-600 dark:text-gray-300 text-sm mb-4">{course.description}</p>

              <div className="flex items-center justify-between pt-4 mt-4 border-t border-gray-200 dark:border-gray-700">
                <div className="flex items-center">
                  <img
                    src={course.instructor.avatar}
                    alt={course.instructor.name}
                    className="h-8 w-8 rounded-full"
                  />
                  <div className="ml-3">
                    <p className="text-sm font-medium">{course.instructor.name}</p>
                    <p className="text-xs text-gray-500">{course.instructor.title}</p>
                  </div>
                </div>
                <div className="flex items-center text-sm text-gray-500">
                  <BookOpen className="h-4 w-4 mr-1" />
                  {course.modules.length} modules
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <AddCourseModal 
        isOpen={isAddModalOpen} 
        onClose={() => setIsAddModalOpen(false)}
        editingCourse={editingCourse}
        onEdit={() => setEditingCourse(null)}
      />
    </div>
  );
}

export default CourseManagementPage;