import { create } from 'zustand';
import { ClientState, ClientJourney, JourneyPhase } from '../types/client';

// Define progress weights for each phase
const phaseProgressWeights: Record<JourneyPhase, number> = {
  onboarding: 20,
  audit: 40,
  strategy: 60,
  implementation: 80,
  review: 100,
};

const mockJourneys: ClientJourney[] = [
  {
    id: '1',
    userId: '1',
    user: {
      id: '1',
      name: 'Sarah Johnson',
      email: 'sarah@example.com',
      role: 'user',
      createdAt: '2024-01-15T00:00:00.000Z',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    phase: 'audit',
    startDate: '2024-01-15T00:00:00.000Z',
    lastUpdated: '2024-03-15T00:00:00.000Z',
    progress: 45,
    notes: 'Client has completed initial financial assessment. Ready for strategy development.',
    nextMilestone: {
      title: 'Investment Strategy Review',
      dueDate: '2024-03-20T00:00:00.000Z',
      completed: false,
    },
    tasks: [
      {
        id: 't1',
        title: 'Complete Risk Assessment',
        completed: true,
        dueDate: '2024-03-10T00:00:00.000Z',
      },
      {
        id: 't2',
        title: 'Review Investment Portfolio',
        completed: false,
        dueDate: '2024-03-18T00:00:00.000Z',
      },
    ],
  },
];

export const useClientStore = create<ClientState>((set, get) => ({
  journeys: mockJourneys,
  isLoading: false,
  error: null,

  updateJourneyPhase: (userId, phase) => {
    set((state) => {
      const journey = state.journeys.find(j => j.userId === userId);
      if (!journey) return state;

      // Calculate task completion progress
      const completedTasks = journey.tasks.filter(task => task.completed).length;
      const totalTasks = journey.tasks.length;
      const taskProgress = Math.round((completedTasks / totalTasks) * 20); // Tasks contribute up to 20%

      // Calculate total progress based on phase and tasks
      const baseProgress = phaseProgressWeights[phase];
      const totalProgress = Math.min(baseProgress + taskProgress, phaseProgressWeights[phase]);

      return {
        journeys: state.journeys.map((j) =>
          j.userId === userId
            ? {
                ...j,
                phase,
                progress: totalProgress,
                lastUpdated: new Date().toISOString(),
              }
            : j
        ),
      };
    });
  },

  updateProgress: (userId, progress) => {
    set((state) => ({
      journeys: state.journeys.map((journey) =>
        journey.userId === userId
          ? { ...journey, progress, lastUpdated: new Date().toISOString() }
          : journey
      ),
    }));
  },

  addNote: (userId, note) => {
    set((state) => ({
      journeys: state.journeys.map((journey) =>
        journey.userId === userId
          ? { ...journey, notes: journey.notes + '\n' + note, lastUpdated: new Date().toISOString() }
          : journey
      ),
    }));
  },

  updateTask: (userId, taskId, completed) => {
    set((state) => {
      const journey = state.journeys.find(j => j.userId === userId);
      if (!journey) return state;

      const updatedTasks = journey.tasks.map((task) =>
        task.id === taskId ? { ...task, completed } : task
      );
      
      // Calculate task completion progress
      const completedTasks = updatedTasks.filter((task) => task.completed).length;
      const totalTasks = updatedTasks.length;
      const taskProgress = Math.round((completedTasks / totalTasks) * 20); // Tasks contribute up to 20%
      
      // Calculate total progress based on phase and tasks
      const baseProgress = phaseProgressWeights[journey.phase];
      const totalProgress = Math.min(baseProgress + taskProgress, phaseProgressWeights[journey.phase]);

      return {
        journeys: state.journeys.map((j) =>
          j.userId === userId
            ? {
                ...j,
                tasks: updatedTasks,
                progress: totalProgress,
                lastUpdated: new Date().toISOString(),
              }
            : j
        ),
      };
    });
  },
}));