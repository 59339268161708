import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCourseStore } from '../store/courseStore';
import { Play, Clock, CheckCircle } from 'lucide-react';
import CourseProgressBar from '../components/courses/CourseProgressBar';
import CoursePlayer from '../components/courses/CoursePlayer';

function CourseDetailsPage() {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const { courses, setActiveLesson, markLessonComplete } = useCourseStore();
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState<string | null>(null);
  const [selectedLessonId, setSelectedLessonId] = useState<string | null>(null);
  
  const course = courses.find((c) => c.id === courseId);

  if (!course) {
    return <div>Course not found</div>;
  }

  const handleLessonClick = (moduleId: string, lessonId: string) => {
    setSelectedModuleId(moduleId);
    setSelectedLessonId(lessonId);
    setIsPlaying(true);
  };

  // Find first incomplete lesson for "Start Learning" button
  const findFirstIncompleteLesson = () => {
    for (const module of course.modules) {
      for (const lesson of module.lessons) {
        if (!lesson.completed) {
          return { moduleId: module.id, lessonId: lesson.id };
        }
      }
    }
    // If all lessons are complete, return first lesson
    return {
      moduleId: course.modules[0].id,
      lessonId: course.modules[0].lessons[0].id,
    };
  };

  const handleStartCourse = () => {
    const { moduleId, lessonId } = findFirstIncompleteLesson();
    handleLessonClick(moduleId, lessonId);
  };

  if (isPlaying) {
    const moduleIndex = course.modules.findIndex(m => m.id === selectedModuleId);
    const lessonIndex = course.modules[moduleIndex].lessons.findIndex(l => l.id === selectedLessonId);

    return (
      <CoursePlayer
        course={course}
        initialModuleIndex={moduleIndex}
        initialLessonIndex={lessonIndex}
        onLessonComplete={(moduleId, lessonId) => {
          markLessonComplete(course.id, moduleId, lessonId);
        }}
        onClose={() => setIsPlaying(false)}
      />
    );
  }

  return (
    <div className="max-w-6xl mx-auto space-y-8">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="relative h-64">
          <img
            src={course.thumbnail}
            alt={course.title}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-8">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-3xl font-bold dark:text-white">{course.title}</h1>
            <div className="w-48">
              <CourseProgressBar progress={course.progress} size="lg" />
            </div>
          </div>
          <p className="text-gray-600 dark:text-gray-300 mb-6">{course.description}</p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="col-span-2">
              <div className="space-y-6">
                <div>
                  <h2 className="text-xl font-semibold dark:text-white mb-4">What You'll Learn</h2>
                  <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {course.learningObjectives.map((objective, index) => (
                      <li key={index} className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                        <span className="dark:text-gray-300">{objective}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h2 className="text-xl font-semibold dark:text-white mb-4">Course Content</h2>
                  <div className="space-y-4">
                    {course.modules.map((module) => (
                      <div key={module.id} className="border dark:border-gray-700 rounded-lg">
                        <div className="p-4 bg-gray-50 dark:bg-gray-700 border-b dark:border-gray-600">
                          <h3 className="font-medium dark:text-white">{module.title}</h3>
                          <p className="text-sm text-gray-500 dark:text-gray-400">{module.description}</p>
                        </div>
                        <div className="divide-y dark:divide-gray-700">
                          {module.lessons.map((lesson) => (
                            <button
                              key={lesson.id}
                              onClick={() => handleLessonClick(module.id, lesson.id)}
                              className="w-full p-4 flex items-center justify-between hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
                            >
                              <div className="flex items-center">
                                {lesson.completed ? (
                                  <CheckCircle className="h-5 w-5 text-green-500 mr-3" />
                                ) : (
                                  <Play className="h-5 w-5 text-gray-400 dark:text-gray-500 mr-3" />
                                )}
                                <div className="text-left">
                                  <p className="font-medium dark:text-white">{lesson.title}</p>
                                  <p className="text-sm text-gray-500 dark:text-gray-400">
                                    {lesson.description}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center text-gray-500 dark:text-gray-400">
                                <Clock className="h-4 w-4 mr-1" />
                                <span className="text-sm">{lesson.duration}</span>
                              </div>
                            </button>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6 sticky top-6">
                <div className="flex items-center mb-6">
                  <img
                    src={course.instructor.avatar}
                    alt={course.instructor.name}
                    className="h-12 w-12 rounded-full"
                  />
                  <div className="ml-3">
                    <p className="font-medium dark:text-white">{course.instructor.name}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{course.instructor.title}</p>
                  </div>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-300 mb-6">{course.instructor.bio}</p>
                <div className="space-y-4">
                  <button
                    onClick={handleStartCourse}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 dark:hover:bg-primary-600 transition-colors"
                  >
                    <Play className="h-5 w-5 mr-2" />
                    {course.progress > 0 ? 'Continue Learning' : 'Start Learning'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseDetailsPage;