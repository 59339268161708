import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { useFinanceStore } from '../store/financeStore';
import { useBudgetStore } from '../store/budgetStore';
import { useWealthStore } from '../store/wealthStore';
import { format } from 'date-fns';
import {
  TrendingUp,
  TrendingDown,
  DollarSign,
  PiggyBank,
  Target,
  AlertTriangle,
  CheckCircle,
  Activity,
  ArrowUpRight,
  ArrowDownRight,
  Building2,
  Wallet,
  CreditCard,
  Landmark
} from 'lucide-react';

function Dashboard() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { calculateNetWorth, getAssetAllocation } = useWealthStore();
  const { budgetItems, savingsGoals } = useBudgetStore();

  // Get first name only
  const firstName = user?.name.split(' ')[0];

  const netWorth = calculateNetWorth();
  const assetAllocation = getAssetAllocation();

  // Calculate financial health score
  const calculateHealthScore = () => {
    let score = 0;
    
    // Net worth factor (30 points)
    if (netWorth > 0) {
      score += 30;
    } else if (netWorth === 0) {
      score += 15;
    }

    // Budget adherence (20 points)
    const budgetAdherence = budgetItems.reduce((acc, item) => {
      return acc + (item.spent <= item.limit ? 1 : 0);
    }, 0) / budgetItems.length || 0;
    score += budgetAdherence * 20;

    // Savings goals progress (20 points)
    const savingsProgress = savingsGoals.reduce((acc, goal) => {
      return acc + (goal.progress / 100);
    }, 0) / savingsGoals.length || 0;
    score += savingsProgress * 20;

    // Asset diversification (30 points)
    const { cash, investments, realEstate, retirement } = assetAllocation;
    const totalAssets = cash + investments + realEstate + retirement;
    if (totalAssets > 0) {
      const diversificationScore = Math.min(
        (investments / totalAssets) * 15 +
        (retirement / totalAssets) * 10 +
        (realEstate / totalAssets) * 5,
        30
      );
      score += diversificationScore;
    }

    return Math.round(score);
  };

  const healthScore = calculateHealthScore();

  return (
    <div className="space-y-6">
      {/* Welcome Section */}
      <div className="card">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
              Welcome Back, {firstName}
            </h1>
            <p className="text-gray-500 dark:text-dark-secondary mt-1">
              Here's your financial overview as of {format(new Date(), 'MMMM d, yyyy')}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <div className={`px-4 py-2 rounded-full ${
              healthScore >= 80 ? 'bg-green-100 dark:bg-green-900/20 text-green-800 dark:text-green-300' :
              healthScore >= 60 ? 'bg-yellow-100 dark:bg-yellow-900/20 text-yellow-800 dark:text-yellow-300' :
              'bg-red-100 dark:bg-red-900/20 text-red-800 dark:text-red-300'
            }`}>
              <span className="text-sm font-medium">Financial Health Score: {healthScore}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="card">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium dark:text-white">Net Worth</h3>
            <Activity className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold dark:text-white">${netWorth.toLocaleString()}</p>
          <div className="flex items-center mt-2 text-green-500 dark:text-green-400">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+12.5% from last month</span>
          </div>
        </div>

        <div className="card">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium dark:text-white">Monthly Savings</h3>
            <PiggyBank className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold dark:text-white">$2,500</p>
          <div className="flex items-center mt-2 text-green-500 dark:text-green-400">
            <Target className="h-4 w-4 mr-1" />
            <span>25% of income</span>
          </div>
        </div>

        <div className="card">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium dark:text-white">Investment Returns</h3>
            <TrendingUp className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold text-green-500 dark:text-green-400">+15.8%</p>
          <p className="text-sm text-gray-500 dark:text-dark-secondary mt-2">YTD Performance</p>
        </div>
      </div>

      {/* Recent Activity and Insights */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="card">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium dark:text-white">Recent Activities</h3>
            <button
              onClick={() => navigate('/reports')}
              className="text-sm text-primary-400 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300"
            >
              View All →
            </button>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-secondary rounded-lg">
              <div>
                <p className="font-medium dark:text-white">Stock Purchase</p>
                <p className="text-sm text-gray-500 dark:text-dark-secondary">AAPL - 10 shares</p>
              </div>
              <span className="text-red-500 dark:text-red-400">-$1,750.00</span>
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-secondary rounded-lg">
              <div>
                <p className="font-medium dark:text-white">Salary Deposit</p>
                <p className="text-sm text-gray-500 dark:text-dark-secondary">Direct Deposit</p>
              </div>
              <span className="text-green-500 dark:text-green-400">+$5,000.00</span>
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-dark-secondary rounded-lg">
              <div>
                <p className="font-medium dark:text-white">401k Contribution</p>
                <p className="text-sm text-gray-500 dark:text-dark-secondary">Retirement Savings</p>
              </div>
              <span className="text-green-500 dark:text-green-400">+$750.00</span>
            </div>
          </div>
        </div>

        <div className="card">
          <h3 className="text-lg font-medium dark:text-white mb-4">Financial Insights</h3>
          <div className="space-y-4">
            <div className="p-4 bg-green-50 dark:bg-green-900/20 rounded-lg">
              <div className="flex items-center">
                <CheckCircle className="h-5 w-5 text-green-500 dark:text-green-400 mr-2" />
                <p className="font-medium text-green-700 dark:text-green-300">
                  On Track for Retirement
                </p>
              </div>
              <p className="mt-1 text-sm text-green-600 dark:text-green-400">
                Your retirement savings are 15% ahead of schedule
              </p>
            </div>
            <div className="p-4 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg">
              <div className="flex items-center">
                <AlertTriangle className="h-5 w-5 text-yellow-500 dark:text-yellow-400 mr-2" />
                <p className="font-medium text-yellow-700 dark:text-yellow-300">
                  Budget Alert
                </p>
              </div>
              <p className="mt-1 text-sm text-yellow-600 dark:text-yellow-400">
                Dining out expenses are 85% of monthly budget
              </p>
            </div>
            <div className="p-4 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
              <div className="flex items-center">
                <Target className="h-5 w-5 text-primary-400 mr-2" />
                <p className="font-medium text-primary-700 dark:text-primary-300">
                  Goal Progress
                </p>
              </div>
              <p className="mt-1 text-sm text-primary-600 dark:text-primary-400">
                Emergency fund is 75% complete
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;