import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const generatePDF = async (elementId: string, fileName: string) => {
  try {
    const element = document.getElementById(elementId);
    if (!element) throw new Error('Element not found');

    // Wait for any animations/transitions to complete
    await new Promise(resolve => setTimeout(resolve, 500));

    // Temporarily modify element for better PDF capture
    const originalPosition = element.style.position;
    const originalZIndex = element.style.zIndex;
    element.style.position = 'relative';
    element.style.zIndex = '9999';

    const canvas = await html2canvas(element, {
      scale: 2, // Higher quality
      useCORS: true, // Enable loading cross-origin images
      allowTaint: true, // Allow tainted canvas
      logging: false,
      backgroundColor: '#ffffff', // Ensure white background
      onclone: (clonedDoc) => {
        // Ensure all elements are visible in cloned document
        const clonedElement = clonedDoc.getElementById(elementId);
        if (clonedElement) {
          clonedElement.style.height = 'auto';
          clonedElement.style.overflow = 'visible';
        }
      }
    });

    // Restore original styles
    element.style.position = originalPosition;
    element.style.zIndex = originalZIndex;

    // Calculate dimensions
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    const pdf = new jsPDF('p', 'mm', 'a4');
    let firstPage = true;

    while (heightLeft >= 0) {
      if (!firstPage) {
        pdf.addPage();
      }
      pdf.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      position -= pageHeight;
      firstPage = false;
    }

    pdf.save(`${fileName}.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};