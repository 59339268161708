import React, { useState } from 'react';
import { Calculator, DollarSign, Briefcase, Home, FileText } from 'lucide-react';

function TaxPlanner() {
  const [income, setIncome] = useState<number>(0);
  const [filingStatus, setFilingStatus] = useState<string>('single');
  const [deductions, setDeductions] = useState<{
    mortgage?: number;
    charitable?: number;
    medical?: number;
    retirement?: number;
    other?: number;
  }>({});

  // 2024 Tax Brackets
  const taxBrackets = {
    single: [
      { rate: 0.10, limit: 11600 },
      { rate: 0.12, limit: 47150 },
      { rate: 0.22, limit: 100525 },
      { rate: 0.24, limit: 191950 },
      { rate: 0.32, limit: 243725 },
      { rate: 0.35, limit: 609350 },
      { rate: 0.37, limit: Infinity }
    ],
    married: [
      { rate: 0.10, limit: 23200 },
      { rate: 0.12, limit: 94300 },
      { rate: 0.22, limit: 201050 },
      { rate: 0.24, limit: 383900 },
      { rate: 0.32, limit: 487450 },
      { rate: 0.35, limit: 731200 },
      { rate: 0.37, limit: Infinity }
    ]
  };

  const calculateTax = () => {
    const brackets = taxBrackets[filingStatus as keyof typeof taxBrackets];
    let remainingIncome = income;
    let totalTax = 0;
    let previousLimit = 0;

    const totalDeductions = Object.values(deductions).reduce((sum, val) => sum + (val || 0), 0);
    const taxableIncome = Math.max(0, income - totalDeductions);
    remainingIncome = taxableIncome;

    for (const bracket of brackets) {
      const taxableAmount = Math.min(Math.max(0, remainingIncome), bracket.limit - previousLimit);
      totalTax += taxableAmount * bracket.rate;
      remainingIncome -= taxableAmount;
      previousLimit = bracket.limit;
      if (remainingIncome <= 0) break;
    }

    const effectiveRate = (totalTax / income) * 100;
    const marginRate = brackets.find(b => b.limit > taxableIncome)?.rate || 0;

    return {
      totalTax,
      effectiveRate,
      marginRate: marginRate * 100,
      taxableIncome,
      takeHome: income - totalTax
    };
  };

  const results = income > 0 ? calculateTax() : null;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Annual Income
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={income}
                onChange={(e) => setIncome(Number(e.target.value))}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter annual income"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Filing Status
            </label>
            <select
              value={filingStatus}
              onChange={(e) => setFilingStatus(e.target.value)}
              className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
            >
              <option value="single">Single</option>
              <option value="married">Married Filing Jointly</option>
            </select>
          </div>

          <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
            <h3 className="text-lg font-medium mb-4">Deductions</h3>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Mortgage Interest
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Home className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    value={deductions.mortgage || ''}
                    onChange={(e) => setDeductions({ ...deductions, mortgage: Number(e.target.value) })}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter mortgage interest"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Charitable Contributions
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    value={deductions.charitable || ''}
                    onChange={(e) => setDeductions({ ...deductions, charitable: Number(e.target.value) })}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter charitable contributions"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Medical Expenses
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    value={deductions.medical || ''}
                    onChange={(e) => setDeductions({ ...deductions, medical: Number(e.target.value) })}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter medical expenses"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Retirement Contributions
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Briefcase className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    value={deductions.retirement || ''}
                    onChange={(e) => setDeductions({ ...deductions, retirement: Number(e.target.value) })}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter retirement contributions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
          <h3 className="text-lg font-medium mb-6">Tax Summary</h3>
          
          {results ? (
            <div className="space-y-6">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Taxable Income
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  ${results.taxableIncome.toFixed(2 )}
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Total Tax
                </p>
                <p className="text-2xl font-bold text-red-500">
                  ${results.totalTax.toFixed(2)}
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Take-Home Amount
                </p>
                <p className="text-2xl font-bold text-green-500">
                  ${results.takeHome.toFixed(2)}
                </p>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <div className="flex justify-between mb-2">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Effective Tax Rate
                  </p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {results.effectiveRate.toFixed(1)}%
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Marginal Tax Rate
                  </p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {results.marginRate.toFixed(1)}%
                  </p>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Income Breakdown
                </p>
                <div className="mt-2 w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2.5">
                  <div
                    className="bg-primary-400 h-2.5 rounded-full"
                    style={{
                      width: `${(results.takeHome / income) * 100}%`
                    }}
                  />
                </div>
                <div className="flex justify-between mt-2 text-xs text-gray-500 dark:text-gray-400">
                  <span>Take-Home: {((results.takeHome / income) * 100).toFixed(1)}%</span>
                  <span>Tax: {((results.totalTax / income) * 100).toFixed(1)}%</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <Calculator className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500 dark:text-gray-400">
                Enter income details to see your tax estimate
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TaxPlanner;