import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import DownloadButton from './DownloadButton';
import QualitativeInsights from './QualitativeInsights';

interface SpendingReportProps {
  timeFrame: string;
}

function SpendingReport({ timeFrame }: SpendingReportProps) {
  const spendingData = [
    { category: 'Housing', amount: 2000, percentage: 35 },
    { category: 'Transportation', amount: 500, percentage: 15 },
    { category: 'Food', amount: 600, percentage: 20 },
    { category: 'Utilities', amount: 300, percentage: 10 },
    { category: 'Entertainment', amount: 400, percentage: 12 },
    { category: 'Other', amount: 200, percentage: 8 },
  ];

  const monthlyTrends = [
    { month: 'Jan', spending: 3800 },
    { month: 'Feb', spending: 4200 },
    { month: 'Mar', spending: 3900 },
    { month: 'Apr', spending: 4100 },
  ];

  const COLORS = ['#B100FF', '#10B981', '#F59E0B', '#6366F1', '#EC4899', '#8B5CF6'];

  const insights = [
    {
      type: 'warning' as const,
      title: 'Housing Expenses Above Target',
      description: 'Housing costs are 35% of income, above the recommended 30%. Consider ways to reduce housing expenses.',
    },
    {
      type: 'positive' as const,
      title: 'Food Spending Optimized',
      description: 'Food expenses are within target range at 20% of total spending.',
    },
    {
      type: 'action' as const,
      title: 'Potential Savings Opportunity',
      description: 'Reducing entertainment spending by 10% could save $40 monthly.',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Spending Analysis</h3>
        <DownloadButton reportId="spending-report" fileName="spending-analysis" />
      </div>
      
      <div id="spending-report" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Total Spending</h4>
            <p className="text-2xl font-bold">$4,000</p>
            <div className="flex items-center mt-2 text-red-500">
              <ArrowUpRight className="h-4 w-4 mr-1" />
              <span>+5.2% from last month</span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Largest Category</h4>
            <p className="text-2xl font-bold">Housing</p>
            <p className="text-sm text-gray-500 mt-2">35% of total spending</p>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Monthly Average</h4>
            <p className="text-2xl font-bold">$4,100</p>
            <div className="flex items-center mt-2 text-green-500">
              <ArrowDownRight className="h-4 w-4 mr-1" />
              <span>-2.5% trend</span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Spending by Category</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={spendingData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="amount"
                  >
                    {spendingData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="mt-4 space-y-2">
              {spendingData.map((category, index) => (
                <div key={category.category} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      className="w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: COLORS[index % COLORS.length] }}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-300">{category.category}</span>
                  </div>
                  <span className="text-sm font-medium">${category.amount}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Monthly Trend</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={monthlyTrends}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="spending" fill="#B100FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Insights & Recommendations</h4>
          <QualitativeInsights insights={insights} />
        </div>
      </div>
    </div>
  );
}

export default SpendingReport;