import { create } from 'zustand';
import { Course, CourseState } from '../types/courses';

// Mock course data
const mockCourses: Course[] = [
  {
    id: '1',
    title: 'Investment Fundamentals',
    description: 'Learn the basics of investing and building wealth for the long term.',
    thumbnail: 'https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    duration: '6 hours',
    modules: [
      {
        id: 'm1',
        title: 'Understanding Markets',
        description: 'Introduction to financial markets',
        lessons: [
          {
            id: 'l1',
            title: 'What are Financial Markets?',
            duration: '30 min',
            completed: false,
            description: 'Overview of different financial markets',
            videoUrl: 'https://example.com/lesson1.mp4',
          },
          {
            id: 'l2',
            title: 'Types of Markets',
            duration: '25 min',
            completed: false,
            description: 'Deep dive into various market types',
            videoUrl: 'https://example.com/lesson2.mp4',
          },
        ],
      },
      {
        id: 'm2',
        title: 'Investment Vehicles',
        description: 'Understanding different investment options',
        lessons: [
          {
            id: 'l3',
            title: 'Stocks and Bonds',
            duration: '35 min',
            completed: false,
            description: 'Introduction to stocks and bonds',
            videoUrl: 'https://example.com/lesson3.mp4',
          },
          {
            id: 'l4',
            title: 'Mutual Funds and ETFs',
            duration: '40 min',
            completed: false,
            description: 'Understanding pooled investments',
            videoUrl: 'https://example.com/lesson4.mp4',
          },
        ],
      },
    ],
    progress: 0,
    category: 'investing',
    instructor: {
      name: 'Sarah Johnson',
      title: 'Investment Advisor',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      bio: '15+ years of investment experience',
    },
    learningObjectives: [
      'Understand basic investment concepts',
      'Learn about different asset classes',
      'Build a diversified portfolio',
    ],
    requirements: [
      'No prior investment experience needed',
      'Basic understanding of math',
    ],
  },
];

export const useCourseStore = create<CourseState>((set, get) => ({
  courses: mockCourses,
  activeCourse: null,
  activeLesson: null,

  setActiveCourse: (course) => set({ activeCourse: course }),
  
  setActiveLesson: (lesson) => set({ activeLesson: lesson }),
  
  markLessonComplete: (courseId, moduleId, lessonId) => {
    set((state) => ({
      courses: state.courses.map((course) => {
        if (course.id === courseId) {
          const updatedModules = course.modules.map((module) => {
            if (module.id === moduleId) {
              const updatedLessons = module.lessons.map((lesson) => {
                if (lesson.id === lessonId) {
                  return { ...lesson, completed: !lesson.completed }; // Toggle completion status
                }
                return lesson;
              });
              return { ...module, lessons: updatedLessons };
            }
            return module;
          });

          // Recalculate progress
          const totalLessons = course.modules.reduce(
            (sum, module) => sum + module.lessons.length,
            0
          );
          const completedLessons = updatedModules.reduce(
            (sum, module) =>
              sum + module.lessons.filter((lesson) => lesson.completed).length,
            0
          );
          const progress = Math.round((completedLessons / totalLessons) * 100);

          return { ...course, modules: updatedModules, progress };
        }
        return course;
      }),
    }));
  },

  addCourse: (courseData) => {
    const newCourse: Course = {
      id: Math.random().toString(36).substr(2, 9),
      ...courseData,
      progress: 0,
    };
    set((state) => ({
      courses: [...state.courses, newCourse],
    }));
  },

  updateCourse: (courseId, courseData) => {
    set((state) => ({
      courses: state.courses.map((course) =>
        course.id === courseId ? { ...course, ...courseData } : course
      ),
    }));
  },

  deleteCourse: (courseId) => {
    set((state) => ({
      courses: state.courses.filter((course) => course.id !== courseId),
    }));
  },
}));