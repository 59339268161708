import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CommunityState, Post, Story } from '../types/community';

const mockUser = {
  id: 'user1',
  name: 'Sarah Johnson',
  email: 'sarah@example.com',
  avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};

const mockPosts: Post[] = [
  {
    id: '1',
    author: mockUser,
    content: 'Just achieved my first financial milestone! 🎉 Successfully built my emergency fund. Remember ladies, financial independence starts with small steps. #WomenInFinance #FinancialGoals',
    media: [
      {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
      },
    ],
    likes: 42,
    comments: [],
    createdAt: new Date().toISOString(),
    tags: ['WomenInFinance', 'FinancialGoals'],
  },
];

const mockStories: Story[] = [
  {
    id: '1',
    author: mockUser,
    media: {
      type: 'image',
      url: 'https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    },
    createdAt: new Date().toISOString(),
    views: 156,
    expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
  },
];

export const useCommunityStore = create(
  persist<CommunityState>(
    (set, get) => ({
      posts: mockPosts,
      stories: mockStories,
      isLoading: false,
      error: null,

      createPost: async (content: string, media?: File[]) => {
        try {
          set({ isLoading: true });
          // Simulate API delay
          await new Promise(resolve => setTimeout(resolve, 1000));

          const newPost: Post = {
            id: Math.random().toString(36).substr(2, 9),
            author: mockUser,
            content,
            media: media?.map(file => ({
              type: file.type.startsWith('video/') ? 'video' : 'image',
              url: URL.createObjectURL(file),
            })),
            likes: 0,
            comments: [],
            createdAt: new Date().toISOString(),
            tags: content.match(/#\w+/g)?.map(tag => tag.slice(1)) || [],
          };

          set(state => ({
            posts: [newPost, ...state.posts],
            isLoading: false,
          }));
        } catch (error) {
          set({ error: 'Failed to create post', isLoading: false });
        }
      },

      createStory: async (media: File) => {
        try {
          set({ isLoading: true });
          await new Promise(resolve => setTimeout(resolve, 1000));

          const newStory: Story = {
            id: Math.random().toString(36).substr(2, 9),
            author: mockUser,
            media: {
              type: media.type.startsWith('video/') ? 'video' : 'image',
              url: URL.createObjectURL(media),
            },
            createdAt: new Date().toISOString(),
            views: 0,
            expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
          };

          set(state => ({
            stories: [newStory, ...state.stories],
            isLoading: false,
          }));
        } catch (error) {
          set({ error: 'Failed to create story', isLoading: false });
        }
      },

      likePost: (postId: string) => {
        set(state => ({
          posts: state.posts.map(post =>
            post.id === postId
              ? { ...post, likes: post.likes + 1 }
              : post
          ),
        }));
      },

      addComment: (postId: string, content: string) => {
        const comment = {
          id: Math.random().toString(36).substr(2, 9),
          author: mockUser,
          content,
          likes: 0,
          createdAt: new Date().toISOString(),
        };

        set(state => ({
          posts: state.posts.map(post =>
            post.id === postId
              ? { ...post, comments: [...post.comments, comment] }
              : post
          ),
        }));
      },

      deletePost: (postId: string) => {
        set(state => ({
          posts: state.posts.filter(post => post.id !== postId),
        }));
      },
    }),
    {
      name: 'community-storage',
    }
  )
);