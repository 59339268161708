import React, { useState } from 'react';
import { Calculator, DollarSign, Calendar, PiggyBank, TrendingDown, AlertTriangle, CheckCircle } from 'lucide-react';
import { useWealthStore } from '../../store/wealthStore';
import { useBudgetStore } from '../../store/budgetStore';

function AffordabilityCalculator() {
  const [purchaseAmount, setPurchaseAmount] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<'cash' | 'credit' | 'loan'>('cash');
  const [monthlyPayment, setMonthlyPayment] = useState<string>('');
  const [interestRate, setInterestRate] = useState<string>('');
  const [loanTerm, setLoanTerm] = useState<string>('');
  const [monthlyIncome, setMonthlyIncome] = useState<string>('');
  const [monthlyExpenses, setMonthlyExpenses] = useState<string>('');

  const { calculateNetWorth } = useWealthStore();
  const { budgetItems } = useBudgetStore();

  const calculateAffordability = () => {
    if (!purchaseAmount) return null;

    const amount = parseFloat(purchaseAmount);
    const income = parseFloat(monthlyIncome) || 0;
    const expenses = parseFloat(monthlyExpenses) || 0;
    const currentNetWorth = calculateNetWorth();
    
    let totalCost = amount;
    let monthlyImpact = 0;
    
    if (paymentMethod === 'credit' || paymentMethod === 'loan') {
      const rate = parseFloat(interestRate) || 0;
      const term = parseInt(loanTerm) || 12;
      const monthlyRate = (rate / 100) / 12;
      const payment = parseFloat(monthlyPayment) || 
        (amount * monthlyRate * Math.pow(1 + monthlyRate, term)) / 
        (Math.pow(1 + monthlyRate, term) - 1);
      
      totalCost = payment * term;
      monthlyImpact = payment;
    }

    const monthlyDisposableIncome = income - expenses;
    const affordabilityRatio = monthlyImpact / monthlyDisposableIncome;
    const netWorthImpact = (totalCost / currentNetWorth) * 100;
    
    const isAffordable = affordabilityRatio < 0.28 && netWorthImpact < 20;
    const isRisky = affordabilityRatio > 0.4 || netWorthImpact > 30;

    return {
      totalCost,
      monthlyImpact,
      affordabilityRatio,
      netWorthImpact,
      isAffordable,
      isRisky,
      newNetWorth: currentNetWorth - totalCost,
      monthlyDisposableIncome,
      monthsToRecover: totalCost / monthlyDisposableIncome,
    };
  };

  const results = purchaseAmount ? calculateAffordability() : null;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Purchase Amount
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={purchaseAmount}
                onChange={(e) => setPurchaseAmount(e.target.value)}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter purchase amount"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Payment Method
            </label>
            <select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value as 'cash' | 'credit' | 'loan')}
              className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
            >
              <option value="cash">Cash/Savings</option>
              <option value="credit">Credit Card</option>
              <option value="loan">Loan</option>
            </select>
          </div>

          {(paymentMethod === 'credit' || paymentMethod === 'loan') && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Monthly Payment (if known)
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    value={monthlyPayment}
                    onChange={(e) => setMonthlyPayment(e.target.value)}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Optional"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Interest Rate (%)
                </label>
                <input
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(e.target.value)}
                  className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter interest rate"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Term (months)
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Calendar className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    value={loanTerm}
                    onChange={(e) => setLoanTerm(e.target.value)}
                    className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter term in months"
                  />
                </div>
              </div>
            </>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Monthly Income
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={monthlyIncome}
                onChange={(e) => setMonthlyIncome(e.target.value)}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter monthly income"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Monthly Expenses
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={monthlyExpenses}
                onChange={(e) => setMonthlyExpenses(e.target.value)}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter monthly expenses"
              />
            </div>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
          <h3 className="text-lg font-medium mb-6">Affordability Analysis</h3>
          
          {results ? (
            <div className="space-y-6">
              <div className={`p-4 rounded-lg ${
                results.isAffordable
                  ? 'bg-green-50 dark:bg-green-900/20'
                  : results.isRisky
                  ? 'bg-red-50 dark:bg-red-900/20'
                  : 'bg-yellow-50 dark:bg-yellow-900/20'
              }`}>
                <div className="flex items-center">
                  {results.isAffordable ? (
                    <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                  ) : results.isRisky ? (
                    <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
                  ) : (
                    <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2" />
                  )}
                  <p className={`font-medium ${
                    results.isAffordable
                      ? 'text-green-700 dark:text-green-300'
                      : results.isRisky
                      ? 'text-red-700 dark:text-red-300'
                      : 'text-yellow-700 dark:text-yellow-300'
                  }`}>
                    {results.isAffordable
                      ? 'This purchase appears affordable'
                      : results.isRisky
                      ? 'This purchase may be risky'
                      : 'Consider this purchase carefully'}
                  </p>
                </div>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Total Cost
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  ${results.totalCost.toFixed(2)}
                </p>
                {paymentMethod !== 'cash' && (
                  <p className="text-sm text-gray-500 mt-1">
                    Monthly payment: ${results.monthlyImpact.toFixed(2)}
                  </p>
                )}
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Impact on Net Worth</p>
                  <p className="text-lg font-semibold mt-1">
                    -{results.netWorthImpact.toFixed(1)}%
                  </p>
                  <p className="text-sm text-gray-500">
                    New net worth: ${results.newNetWorth.toFixed(2)}
                  </p>
                </div>

                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Monthly Budget Impact</p>
                  <p className="text-lg font-semibold mt-1">
                    {(results.affordabilityRatio * 100).toFixed(1)}%
                  </p>
                  <p className="text-sm text-gray-500">
                    of disposable income
                  </p>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Financial Impact
                </h4>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Recovery Time</span>
                    <span className="font-medium">
                      {results.monthsToRecover.toFixed(1)} months
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Monthly Disposable Income</span>
                    <span className="font-medium">
                      ${results.monthlyDisposableIncome.toFixed(2)}
                    </span>
                  </div>
                  {paymentMethod !== 'cash' && (
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Total Interest Cost</span>
                      <span className="font-medium">
                        ${(results.totalCost - parseFloat(purchaseAmount)).toFixed(2)}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="bg-primary-50 dark:bg-primary-900/20 rounded-lg p-4">
                <h4 className="text-sm font-medium text-primary-700 dark:text-primary-300 mb-2">
                  Recommendations
                </h4>
                <ul className="space-y-2 text-sm text-primary-600 dark:text-primary-400">
                  {results.isRisky && (
                    <>
                      <li>• Consider saving more before making this purchase</li>
                      <li>• Look for less expensive alternatives</li>
                      <li>• Review your budget for potential savings</li>
                    </>
                  )}
                  {!results.isAffordable && !results.isRisky && (
                    <>
                      <li>• Save for a larger down payment</li>
                      <li>• Shop around for better interest rates</li>
                      <li>• Consider a longer term to reduce monthly payments</li>
                    </>
                  )}
                  {results.isAffordable && (
                    <>
                      <li>• Consider setting aside an emergency fund first</li>
                      <li>• Look into cash-back or rewards if using credit</li>
                      <li>• Plan for any additional maintenance costs</li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <Calculator className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500 dark:text-gray-400">
                Enter purchase details to see affordability analysis
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AffordabilityCalculator;