import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { X } from 'lucide-react';
import { useBudgetStore } from '../../store/budgetStore';
import { savingsGoalSchema, SavingsGoalFormData, SavingsGoal } from '../../types/budget';

interface EditSavingsGoalModalProps {
  isOpen: boolean;
  onClose: () => void;
  goal: SavingsGoal;
}

function EditSavingsGoalModal({ isOpen, onClose, goal }: EditSavingsGoalModalProps) {
  const { updateSavingsGoal } = useBudgetStore();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SavingsGoalFormData>({
    resolver: zodResolver(savingsGoalSchema),
    defaultValues: {
      name: goal.name,
      type: goal.type,
      targetAmount: goal.targetAmount,
      targetDate: goal.targetDate.split('T')[0],
      automate: goal.automate,
      contributions: {
        amount: goal.contributions.amount,
        frequency: goal.contributions.frequency,
      },
    },
  });

  const onSubmit = async (data: SavingsGoalFormData) => {
    try {
      await updateSavingsGoal(goal.id, {
        ...data,
        currentAmount: goal.currentAmount,
        progress: goal.progress,
      });
      onClose();
    } catch (error) {
      console.error('Failed to update savings goal:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose} />
        
        <div className="relative w-full max-w-md rounded-lg bg-white dark:bg-gray-800 p-6 shadow-xl">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Edit Savings Goal</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Goal Name
              </label>
              <input
                type="text"
                {...register('name')}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Goal Type
              </label>
              <select
                {...register('type')}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              >
                <option value="emergency_fund">Emergency Fund</option>
                <option value="large_purchase">Large Purchase</option>
                <option value="debt_payoff">Debt Payoff</option>
                <option value="vacation">Vacation</option>
                <option value="education">Education</option>
                <option value="wedding">Wedding</option>
                <option value="home">Home</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Target Amount
              </label>
              <div className="relative mt-1">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  {...register('targetAmount', { valueAsNumber: true })}
                  className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  placeholder="0.00"
                  step="0.01"
                />
              </div>
              {errors.targetAmount && (
                <p className="mt-1 text-sm text-red-600">{errors.targetAmount.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Target Date
              </label>
              <input
                type="date"
                {...register('targetDate')}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              />
              {errors.targetDate && (
                <p className="mt-1 text-sm text-red-600">{errors.targetDate.message}</p>
              )}
            </div>

            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                Contribution Plan
              </h3>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Frequency
                </label>
                <select
                  {...register('contributions.frequency')}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="weekly">Weekly</option>
                  <option value="bi_weekly">Bi-weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Contribution Amount
                </label>
                <div className="relative mt-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    {...register('contributions.amount', { valueAsNumber: true })}
                    className="block w-full rounded-md border border-gray-300 dark:border-gray-600 pl-7 pr-12 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                    placeholder="0.00"
                    step="0.01"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                {...register('automate')}
                className="h-4 w-4 text-primary-400 focus:ring-primary-400 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                Automate contributions
              </label>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md disabled:opacity-50"
              >
                {isSubmitting ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditSavingsGoalModal;