import React from 'react';
import { MapPin } from 'lucide-react';
import { Property } from '../../../types/property';

interface PropertyCardProps {
  property: Property;
  onClick: () => void;
}

function PropertyCard({ property, onClick }: PropertyCardProps) {
  const equity = property.currentValue - (property.mortgage?.currentBalance || 0);
  const monthlyIncome = property.rental?.monthlyRent || 0;
  const monthlyExpenses = 
    (property.mortgage?.monthlyPayment || 0) +
    (property.expenses.propertyTax / 12) +
    (property.expenses.insurance / 12) +
    (property.expenses.utilities) +
    (property.expenses.maintenance / 12) +
    (property.expenses.management / 12);
  const monthlyCashFlow = monthlyIncome - monthlyExpenses;

  return (
    <div
      onClick={onClick}
      className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden cursor-pointer transition-transform hover:scale-[1.02]"
    >
      <div className="relative h-48">
        <img
          src={property.images[0]?.url || 'https://images.unsplash.com/photo-1518780664697-55e3ad937233'}
          alt={property.address}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-4 right-4 bg-white dark:bg-gray-800 px-3 py-1 rounded-full text-sm font-medium">
          {property.propertyType.replace('_', ' ')}
        </div>
      </div>

      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">{property.address}</h3>
            <div className="flex items-center text-gray-500 text-sm mt-1">
              <MapPin className="h-4 w-4 mr-1" />
              {property.city}, {property.state} {property.zipCode}
            </div>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              ${property.currentValue.toLocaleString()}
            </span>
            <span className="text-xs text-gray-500">Current Value</span>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-500">Monthly Cash Flow</p>
            <p className={`text-lg font-semibold ${
              monthlyCashFlow >= 0 ? 'text-green-500' : 'text-red-500'
            }`}>
              ${Math.abs(monthlyCashFlow).toLocaleString()}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Equity</p>
            <p className="text-lg font-semibold text-gray-900 dark:text-white">
              ${equity.toLocaleString()}
            </p>
          </div>
        </div>

        <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
          <div className="grid grid-cols-3 gap-2 text-sm">
            <div>
              <span className="text-gray-500">Beds</span>
              <p className="font-medium">{property.bedrooms}</p>
            </div>
            <div>
              <span className="text-gray-500">Baths</span>
              <p className="font-medium">{property.bathrooms}</p>
            </div>
            <div>
              <span className="text-gray-500">SqFt</span>
              <p className="font-medium">{property.squareFeet.toLocaleString()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyCard;