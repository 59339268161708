import React from 'react';
import { FileText, Upload } from 'lucide-react';

function EstatePlanningPage() {
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Estate Planning</h1>
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-medium">Documents</h2>
          <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
            <Upload className="h-5 w-5 mr-2" />
            Upload Document
          </button>
        </div>
      </div>
    </div>
  );
}

export default EstatePlanningPage;