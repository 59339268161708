import { create } from 'zustand';
import { Asset, Investment, RealEstate, RetirementAccount } from '../types/wealth';

interface WealthState {
  assets: Asset[];
  investments: Investment[];
  realEstate: RealEstate[];
  retirementAccounts: RetirementAccount[];
  addAsset: (asset: Omit<Asset, 'id'>) => void;
  updateAsset: (id: string, asset: Partial<Asset>) => void;
  removeAsset: (id: string) => void;
  calculateNetWorth: (userId?: string) => number;
  getAssetAllocation: (userId?: string) => {
    cash: number;
    investments: number;
    realEstate: number;
    retirement: number;
  };
}

// Mock data for different users
const mockUserData: Record<string, {
  assets: Asset[];
  investments: Investment[];
  realEstate: RealEstate[];
  retirementAccounts: RetirementAccount[];
}> = {
  '1': {
    assets: [
      {
        id: '1',
        name: 'Savings Account',
        value: 50000,
        type: 'cash',
        details: {},
      },
    ],
    investments: [
      {
        id: '1',
        name: 'Tech Stocks Portfolio',
        type: 'stocks',
        value: 75000,
        purchasePrice: 50000,
        purchaseDate: '2023-01-01',
        returns: 50,
        risk: 'medium',
      },
    ],
    realEstate: [
      {
        id: '1',
        address: '123 Main St',
        type: 'primary',
        purchasePrice: 400000,
        currentValue: 450000,
        mortgage: 300000,
        equity: 150000,
      },
    ],
    retirementAccounts: [
      {
        id: '1',
        type: '401k',
        provider: 'Fidelity',
        balance: 200000,
        contributions: {
          annual: 19500,
          ytd: 10000,
          employerMatch: 5000,
        },
        investments: [],
      },
    ],
  },
  // Add more mock data for other users as needed
};

export const useWealthStore = create<WealthState>((set, get) => ({
  assets: [],
  investments: [],
  realEstate: [],
  retirementAccounts: [],

  addAsset: (asset) => {
    const newAsset = { ...asset, id: Math.random().toString(36).substr(2, 9) };
    set((state) => ({
      assets: [...state.assets, newAsset],
    }));
  },

  updateAsset: (id, assetUpdate) => {
    set((state) => ({
      assets: state.assets.map((asset) =>
        asset.id === id ? { ...asset, ...assetUpdate } : asset
      ),
    }));
  },

  removeAsset: (id) => {
    set((state) => ({
      assets: state.assets.filter((asset) => asset.id !== id),
    }));
  },

  calculateNetWorth: (userId) => {
    const data = userId ? mockUserData[userId] : get();
    if (!data) return 0;

    const totalAssets = data.assets.reduce((sum, asset) => sum + asset.value, 0);
    const totalRealEstateEquity = data.realEstate.reduce(
      (sum, property) => sum + property.equity,
      0
    );
    const totalInvestments = data.investments.reduce(
      (sum, investment) => sum + investment.value,
      0
    );
    const totalRetirement = data.retirementAccounts.reduce(
      (sum, account) => sum + account.balance,
      0
    );
    return totalAssets + totalRealEstateEquity + totalInvestments + totalRetirement;
  },

  getAssetAllocation: (userId) => {
    const data = userId ? mockUserData[userId] : get();
    if (!data) return { cash: 0, investments: 0, realEstate: 0, retirement: 0 };

    const cash = data.assets
      .filter((asset) => asset.type === 'cash')
      .reduce((sum, asset) => sum + asset.value, 0);
    const investments = data.investments.reduce(
      (sum, investment) => sum + investment.value,
      0
    );
    const realEstate = data.realEstate.reduce(
      (sum, property) => sum + property.equity,
      0
    );
    const retirement = data.retirementAccounts.reduce(
      (sum, account) => sum + account.balance,
      0
    );

    return { cash, investments, realEstate, retirement };
  },
}));