import { create } from 'zustand';
import { BudgetState } from '../types/budget';

const mockBudgetItems = [
  {
    id: '1',
    category: 'housing',
    name: 'Rent',
    limit: 2000,
    spent: 1800,
    remaining: 200,
    transactions: []
  },
  {
    id: '2',
    category: 'food',
    name: 'Groceries',
    limit: 600,
    spent: 450,
    remaining: 150,
    transactions: []
  }
];

const mockSavingsGoals = [
  {
    id: '1',
    name: 'Emergency Fund',
    type: 'emergency_fund',
    targetAmount: 25000,
    currentAmount: 15000,
    targetDate: '2024-12-31',
    progress: 60,
    createdAt: '2024-01-01',
    status: 'active',
    automate: true,
    contributions: {
      amount: 500,
      frequency: 'monthly',
      nextContribution: '2024-04-01',
    },
  }
];

export const useBudgetStore = create<BudgetState>((set) => ({
  budgetItems: mockBudgetItems,
  savingsGoals: mockSavingsGoals,
  transactions: [],
  isLoading: false,
  error: null,

  addBudgetItem: (item) => {
    const newItem = {
      ...item,
      id: Math.random().toString(36).substr(2, 9),
      spent: 0,
      remaining: item.limit,
      transactions: []
    };
    set((state) => ({
      budgetItems: [...state.budgetItems, newItem]
    }));
  },

  updateBudgetItem: (id, data) => {
    set((state) => ({
      budgetItems: state.budgetItems.map((item) =>
        item.id === id ? { ...item, ...data } : item
      )
    }));
  },

  deleteBudgetItem: (id) => {
    set((state) => ({
      budgetItems: state.budgetItems.filter((item) => item.id !== id)
    }));
  },

  addSavingsGoal: (goal) => {
    const newGoal = {
      ...goal,
      id: Math.random().toString(36).substr(2, 9),
      currentAmount: 0,
      progress: 0,
      createdAt: new Date().toISOString(),
      status: 'active',
    };
    set((state) => ({
      savingsGoals: [...state.savingsGoals, newGoal]
    }));
  },

  updateSavingsGoal: (id, data) => {
    set((state) => ({
      savingsGoals: state.savingsGoals.map((goal) =>
        goal.id === id ? { ...goal, ...data } : goal
      )
    }));
  },

  deleteSavingsGoal: (id) => {
    set((state) => ({
      savingsGoals: state.savingsGoals.filter((goal) => goal.id !== id)
    }));
  },

  addTransaction: (transaction) => {
    const newTransaction = {
      ...transaction,
      id: Math.random().toString(36).substr(2, 9),
      date: new Date().toISOString(),
    };
    set((state) => ({
      transactions: [...state.transactions, newTransaction]
    }));
  }
}));