import React from 'react';
import ReactPlayer from 'react-player';
import { Play } from 'lucide-react';

interface VideoPlayerProps {
  videoUrl?: string;
  onEnded?: () => void;
  poster?: string;
}

function VideoPlayer({ videoUrl, onEnded, poster }: VideoPlayerProps) {
  if (!videoUrl) {
    return (
      <div className="absolute inset-0 flex items-center justify-center bg-gray-900">
        <Play className="h-16 w-16 text-gray-400" />
      </div>
    );
  }

  return (
    <div className="relative w-full h-full">
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="100%"
        controls
        playing
        playsinline
        onEnded={onEnded}
        config={{
          file: {
            attributes: {
              poster: poster,
            },
          },
        }}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
    </div>
  );
}

export default VideoPlayer;