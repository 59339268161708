import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { SettingsState } from '../types/settings';

export const useSettingsStore = create(
  persist<SettingsState>(
    (set) => ({
      notifications: {
        email: {
          accountUpdates: true,
          marketingEmails: false,
          newCourses: true,
          mentorshipMessages: true,
          communityActivity: true,
        },
        push: {
          accountAlerts: true,
          goalReminders: true,
          mentorshipMessages: true,
          courseUpdates: true,
        },
      },
      security: {
        twoFactorEnabled: false,
        lastPasswordChange: new Date().toISOString(),
        loginHistory: [
          {
            date: new Date().toISOString(),
            device: 'Chrome on MacOS',
            location: 'San Francisco, CA',
            status: 'success',
          },
        ],
      },
      preferences: {
        language: 'en',
        currency: 'USD',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      isLoading: false,
      error: null,

      updateNotifications: (settings) =>
        set((state) => ({
          notifications: {
            ...state.notifications,
            ...settings,
          },
        })),

      updateSecurity: (settings) =>
        set((state) => ({
          security: {
            ...state.security,
            ...settings,
          },
        })),

      updatePreferences: (settings) =>
        set((state) => ({
          preferences: {
            ...state.preferences,
            ...settings,
          },
        })),
    }),
    {
      name: 'settings-storage',
    }
  )
);