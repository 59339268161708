import React from 'react';
import { Plus } from 'lucide-react';
import { useLoanStore } from '../../store/loanStore';
import LoanOverview from '../../components/loans/LoanOverview';
import LoanCard from '../../components/loans/LoanCard';

function LoansPage() {
  const { loans, removeLoan } = useLoanStore();

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Loans</h1>
        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500">
          <Plus className="h-5 w-5 mr-2" />
          Add Loan
        </button>
      </div>

      <LoanOverview loans={loans} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {loans.map((loan) => (
          <LoanCard
            key={loan.id}
            loan={loan}
            onDelete={removeLoan}
          />
        ))}
      </div>
    </div>
  );
}

export default LoansPage;