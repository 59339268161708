import React from 'react';
import { LineChart, TrendingUp, DollarSign } from 'lucide-react';

function InvestmentsPage() {
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Investment Portfolio</h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Investments</h3>
            <DollarSign className="h-5 w-5 text-indigo-600" />
          </div>
          <p className="text-2xl font-bold">$350,000</p>
          <p className="text-sm text-green-600 mt-2">↑ 8.5% this year</p>
        </div>
      </div>
    </div>
  );
}

export default InvestmentsPage;