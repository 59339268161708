import React from 'react';
import { Plus } from 'lucide-react';
import AssetsSummary from '../components/dashboard/AssetsSummary';

function AssetsPage() {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Assets</h1>
        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
          <Plus className="h-5 w-5 mr-2" />
          Add Asset
        </button>
      </div>
      <AssetsSummary />
    </div>
  );
}

export default AssetsPage;