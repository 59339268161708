import React from 'react';
import { Link } from 'react-router-dom';
import { useCourseStore } from '../store/courseStore';
import { Play, Clock, BookOpen } from 'lucide-react';
import CourseProgressBar from '../components/courses/CourseProgressBar';

function CoursesPage() {
  const { courses } = useCourseStore();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Financial Education</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {courses.map((course) => (
          <Link
            key={course.id}
            to={`/courses/${course.id}`}
            className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-[1.02] hover:shadow-xl"
          >
            <div className="relative h-48">
              <img
                src={course.thumbnail}
                alt={course.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                <div className="bg-white rounded-full p-3 shadow-lg">
                  <Play className="h-6 w-6 text-indigo-600" />
                </div>
              </div>
            </div>

            <div className="p-6">
              <div className="flex items-center mb-2">
                <span className="text-sm font-medium text-indigo-600 bg-indigo-50 px-2 py-1 rounded">
                  {course.category}
                </span>
              </div>
              <h3 className="text-lg font-semibold mb-2">{course.title}</h3>
              <p className="text-gray-600 text-sm mb-4">{course.description}</p>

              <CourseProgressBar progress={course.progress} size="sm" />

              <div className="flex items-center justify-between pt-4 mt-4 border-t border-gray-200">
                <div className="flex items-center">
                  <img
                    src={course.instructor.avatar}
                    alt={course.instructor.name}
                    className="h-8 w-8 rounded-full"
                  />
                  <div className="ml-3">
                    <p className="text-sm font-medium">{course.instructor.name}</p>
                    <p className="text-xs text-gray-500">{course.instructor.title}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4 text-sm text-gray-500">
                  <div className="flex items-center">
                    <Clock className="h-4 w-4 mr-1" />
                    {course.duration}
                  </div>
                  <div className="flex items-center">
                    <BookOpen className="h-4 w-4 mr-1" />
                    {course.modules.length} modules
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default CoursesPage;