import React from 'react';
import { TrendingUp, TrendingDown, Search, Filter, ArrowUpDown, Calendar, DollarSign, Percent } from 'lucide-react';

function BondsPage() {
  const bondHoldings = [
    {
      id: '1',
      name: 'US Treasury Note',
      type: 'Government',
      quantity: 10,
      faceValue: 10000,
      purchasePrice: 9850,
      currentPrice: 9920,
      couponRate: 2.75,
      yieldToMaturity: 2.85,
      maturityDate: '2026-03-15',
      nextCouponDate: '2024-09-15',
      couponFrequency: 'Semi-Annual',
      totalValue: 99200,
      gain: 700,
      gainPercent: 0.71,
    },
    {
      id: '2',
      name: 'Corporate Bond - Microsoft',
      type: 'Corporate',
      quantity: 5,
      faceValue: 10000,
      purchasePrice: 10200,
      currentPrice: 10450,
      couponRate: 3.5,
      yieldToMaturity: 3.25,
      maturityDate: '2028-06-15',
      nextCouponDate: '2024-06-15',
      couponFrequency: 'Semi-Annual',
      totalValue: 52250,
      gain: 1250,
      gainPercent: 2.45,
    },
    {
      id: '3',
      name: 'Municipal Bond - CA',
      type: 'Municipal',
      quantity: 8,
      faceValue: 5000,
      purchasePrice: 5100,
      currentPrice: 5250,
      couponRate: 4.0,
      yieldToMaturity: 3.75,
      maturityDate: '2029-12-15',
      nextCouponDate: '2024-06-15',
      couponFrequency: 'Semi-Annual',
      totalValue: 42000,
      gain: 1200,
      gainPercent: 2.94,
    },
  ];

  const totalValue = bondHoldings.reduce((sum, bond) => sum + bond.totalValue, 0);
  const totalGain = bondHoldings.reduce((sum, bond) => sum + bond.gain, 0);
  const averageYield = bondHoldings.reduce((sum, bond) => sum + bond.yieldToMaturity, 0) / bondHoldings.length;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search bonds..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <Filter className="h-5 w-5 mr-2" />
            Filter
          </button>
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <ArrowUpDown className="h-5 w-5 mr-2" />
            Sort
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Bond Value</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalValue.toLocaleString()}</p>
          <div className="flex items-center mt-2 text-green-500">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+${totalGain.toLocaleString()} Total Return</span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Average Yield</h3>
            <Percent className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">{averageYield.toFixed(2)}%</p>
          <p className="text-sm text-gray-500 mt-2">Yield to Maturity</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Next Coupon Payment</h3>
            <Calendar className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">Jun 15, 2024</p>
          <p className="text-sm text-gray-500 mt-2">Multiple bonds due</p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium">Bond Holdings</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Bond Name/Type
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Face Value
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Price
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Coupon Rate
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  YTM
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Maturity
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Total Value
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {bondHoldings.map((bond) => (
                <tr key={bond.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="font-medium text-gray-900 dark:text-white">{bond.name}</div>
                      <div className="text-sm text-gray-500">{bond.type}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">{bond.quantity}</span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">${bond.faceValue.toLocaleString()}</span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">${bond.currentPrice.toLocaleString()}</span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">{bond.couponRate}%</span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">{bond.yieldToMaturity}%</span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div>
                      <div className="text-sm text-gray-900 dark:text-white">
                        {new Date(bond.maturityDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                      </div>
                      <div className="text-xs text-gray-500">
                        Next Coupon: {new Date(bond.nextCouponDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex flex-col items-end">
                      <span className="text-sm text-gray-900 dark:text-white">${bond.totalValue.toLocaleString()}</span>
                      <span className="text-xs text-green-500">+{bond.gainPercent.toFixed(2)}%</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default BondsPage;