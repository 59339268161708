import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { RetirementAccount } from '../../types/retirement';

interface RetirementOverviewProps {
  accounts: RetirementAccount[];
}

function RetirementOverview({ accounts }: RetirementOverviewProps) {
  const totalBalance = accounts.reduce((sum, account) => sum + account.balance, 0);
  const totalContributions = accounts.reduce(
    (sum, account) => sum + account.contributions.lifetime,
    0
  );
  const totalYTDContributions = accounts.reduce(
    (sum, account) => sum + account.contributions.ytd,
    0
  );
  const totalEmployerMatch = accounts.reduce(
    (sum, account) => sum + account.contributions.employerMatch,
    0
  );

  const chartData = accounts.map((account) => ({
    name: account.type.toUpperCase().replace('_', ' '),
    value: account.balance,
  }));

  const COLORS = ['#B100FF', '#10B981', '#F59E0B', '#6366F1'];

  // Calculate weighted average performance
  const weightedPerformance = accounts.reduce(
    (acc, account) => {
      const weight = account.balance / totalBalance;
      return {
        year: acc.year + account.performance.year * weight,
        allTime: acc.allTime + account.performance.allTime * weight,
      };
    },
    { year: 0, allTime: 0 }
  );

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
        Retirement Overview
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value: number) => [
                  `$${value.toLocaleString()}`,
                  'Balance',
                ]}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-500 dark:text-gray-400">Total Balance</p>
              <p className="text-xl font-bold text-gray-900 dark:text-white">
                ${totalBalance.toLocaleString()}
              </p>
            </div>
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-500 dark:text-gray-400">YTD Contributions</p>
              <p className="text-xl font-bold text-gray-900 dark:text-white">
                ${totalYTDContributions.toLocaleString()}
              </p>
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">Performance</p>
              <div className="text-right">
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  YTD: {weightedPerformance.year.toFixed(1)}%
                </p>
                <p className="text-xs text-gray-500">
                  All Time: {weightedPerformance.allTime.toFixed(1)}%
                </p>
              </div>
            </div>
            <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
              <div
                className={`h-2 rounded-full ${
                  weightedPerformance.year >= 0 ? 'bg-green-500' : 'bg-red-500'
                }`}
                style={{
                  width: `${Math.min(Math.abs(weightedPerformance.year), 100)}%`,
                }}
              />
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
              Contribution Summary
            </p>
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-900 dark:text-white">Lifetime Contributions</span>
                <span className="font-medium text-gray-900 dark:text-white">
                  ${totalContributions.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-900 dark:text-white">Employer Match (YTD)</span>
                <span className="font-medium text-gray-900 dark:text-white">
                  ${totalEmployerMatch.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RetirementOverview;