import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { TrendingUp, TrendingDown } from 'lucide-react';
import DownloadButton from './DownloadButton';
import QualitativeInsights from './QualitativeInsights';

interface IncomeExpenseReportProps {
  timeFrame: string;
}

function IncomeExpenseReport({ timeFrame }: IncomeExpenseReportProps) {
  const monthlyData = [
    { month: 'Jan', income: 6500, expenses: 4200 },
    { month: 'Feb', income: 6800, expenses: 4500 },
    { month: 'Mar', income: 7200, expenses: 4100 },
    { month: 'Apr', income: 6900, expenses: 4300 },
  ];

  const categoryData = [
    { category: 'Salary', amount: 6500 },
    { category: 'Investments', amount: 500 },
    { category: 'Side Hustle', amount: 800 },
    { category: 'Other', amount: 200 },
  ];

  const insights = [
    {
      type: 'positive' as const,
      title: 'Positive Savings Rate',
      description: "You're saving 35% of your income, exceeding the recommended 20% target.",
    },
    {
      type: 'warning' as const,
      title: 'Income Concentration',
      description: 'Over 80% of income comes from salary. Consider diversifying income sources.',
    },
    {
      type: 'action' as const,
      title: 'Investment Opportunity',
      description: 'With consistent surplus, consider increasing investment contributions.',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Income vs Expenses</h3>
        <DownloadButton reportId="income-expense-report" fileName="income-expense-analysis" />
      </div>
      
      <div id="income-expense-report" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Monthly Income</h4>
            <p className="text-2xl font-bold">$8,000</p>
            <div className="flex items-center mt-2 text-green-500">
              <TrendingUp className="h-4 w-4 mr-1" />
              <span>+10.5% from last month</span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Monthly Expenses</h4>
            <p className="text-2xl font-bold">$4,300</p>
            <div className="flex items-center mt-2 text-red-500">
              <TrendingDown className="h-4 w-4 mr-1" />
              <span>+4.8% from last month</span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Monthly Savings</h4>
            <p className="text-2xl font-bold">$3,700</p>
            <p className="text-sm text-gray-500 mt-2">46.25% savings rate</p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Income vs Expenses Trend</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={monthlyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="income" stackId="1" stroke="#10B981" fill="#10B981" fillOpacity={0.3} />
                  <Area type="monotone" dataKey="expenses" stackId="2" stroke="#EF4444" fill="#EF4444" fillOpacity={0.3} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Income Sources</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={categoryData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="category" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="amount" fill="#B100FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Insights & Recommendations</h4>
          <QualitativeInsights insights={insights} />
        </div>
      </div>
    </div>
  );
}

export default IncomeExpenseReport;