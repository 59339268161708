import React, { useState } from 'react';
import { Search, Filter, ArrowUpDown, TrendingUp, Watch, PaintBrush, Crown, DollarSign, Plus } from 'lucide-react';
import AddOtherInvestmentModal from '../../components/investments/AddOtherInvestmentModal';

function OtherInvestmentsPage() {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const alternativeInvestments = [
    {
      id: '1',
      name: 'Rolex Daytona',
      category: 'Watches',
      purchaseDate: '2023-06-15',
      purchasePrice: 35000,
      currentValue: 42000,
      location: 'Safety Deposit Box - Chase Bank',
      condition: 'Excellent',
      lastAppraisal: '2024-02-15',
      gain: 7000,
      gainPercent: 20,
      insurance: {
        provider: 'Jewelers Mutual',
        coverage: 45000,
        premium: 850,
      },
    },
    {
      id: '2',
      name: 'Banksy Print - Girl with Balloon',
      category: 'Art',
      purchaseDate: '2022-11-30',
      purchasePrice: 85000,
      currentValue: 115000,
      location: 'Private Storage Facility',
      condition: 'Mint',
      lastAppraisal: '2024-01-20',
      gain: 30000,
      gainPercent: 35.29,
      insurance: {
        provider: 'ArtGuard Insurance',
        coverage: 120000,
        premium: 2400,
      },
    },
    {
      id: '3',
      name: 'Macallan 25 Year Collection',
      category: 'Wine & Spirits',
      purchaseDate: '2023-03-15',
      purchasePrice: 25000,
      currentValue: 28500,
      location: 'Climate Controlled Wine Cellar',
      condition: 'Mint',
      lastAppraisal: '2024-02-28',
      gain: 3500,
      gainPercent: 14,
      insurance: {
        provider: 'Specialty Collections Inc',
        coverage: 30000,
        premium: 600,
      },
    },
  ];

  const totalValue = alternativeInvestments.reduce((sum, inv) => sum + inv.currentValue, 0);
  const totalGain = alternativeInvestments.reduce((sum, inv) => sum + inv.gain, 0);
  const totalCost = alternativeInvestments.reduce((sum, inv) => sum + inv.purchasePrice, 0);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search alternative investments..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <Filter className="h-5 w-5 mr-2" />
            Filter
          </button>
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <ArrowUpDown className="h-5 w-5 mr-2" />
            Sort
          </button>
          <button 
            onClick={() => setIsAddModalOpen(true)}
            className="flex items-center px-4 py-2 text-white bg-primary-400 hover:bg-primary-500 rounded-lg"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Investment
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Value</h3>
            <Crown className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalValue.toLocaleString()}</p>
          <div className="flex items-center mt-2 text-green-500">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+${totalGain.toLocaleString()} Total Return</span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Cost Basis</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalCost.toLocaleString()}</p>
          <p className="text-sm text-gray-500 mt-2">Initial Investment</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Return</h3>
            <TrendingUp className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold text-green-500">
            +{((totalValue - totalCost) / totalCost * 100).toFixed(2)}%
          </p>
          <p className="text-sm text-gray-500 mt-2">Overall Performance</p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium">Alternative Investments</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Asset/Category
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Purchase Price
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Value
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Location
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Insurance
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Total Return
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {alternativeInvestments.map((investment) => (
                <tr key={investment.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="font-medium text-gray-900 dark:text-white">{investment.name}</div>
                      <div className="text-sm text-gray-500">{investment.category}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${investment.purchasePrice.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${investment.currentValue.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm text-gray-900 dark:text-white">{investment.location}</div>
                      <div className="text-xs text-gray-500">Condition: {investment.condition}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm text-gray-900 dark:text-white">{investment.insurance.provider}</div>
                      <div className="text-xs text-gray-500">
                        Coverage: ${investment.insurance.coverage.toLocaleString()}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex flex-col items-end">
                      <div className="flex items-center text-green-500">
                        <TrendingUp className="h-4 w-4 mr-1" />
                        <span>${investment.gain.toLocaleString()}</span>
                      </div>
                      <span className="text-sm text-green-500">
                        +{investment.gainPercent.toFixed(2)}%
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AddOtherInvestmentModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={(investment) => {
          console.log('New investment:', investment);
          setIsAddModalOpen(false);
        }}
      />
    </div>
  );
}

export default OtherInvestmentsPage;