import React from 'react';
import { ClientJourney } from '../../types/client';

interface ClientCardProps {
  journey: ClientJourney;
  onClick?: () => void;
}

function ClientCard({ journey, onClick }: ClientCardProps) {
  return (
    <div 
      onClick={onClick}
      className="bg-white dark:bg-gray-700 rounded-lg shadow p-3 hover:shadow-md transition-shadow cursor-pointer"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <img
            src={journey.user.avatar}
            alt={journey.user.name}
            className="h-10 w-10 rounded-full"
          />
          <div>
            <h4 className="font-medium text-gray-900 dark:text-white">
              {journey.user.name}
            </h4>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Progress: {journey.progress}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientCard;