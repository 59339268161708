import { create } from 'zustand';
import { CreditState, CreditCard } from '../types/credit';
import { addDays } from 'date-fns';

const mockCards: CreditCard[] = [
  {
    id: '1',
    issuer: 'Chase',
    cardName: 'Sapphire Preferred',
    cardNumber: '****5678',
    cardType: 'visa',
    currentBalance: 2450.75,
    statementBalance: 2200.00,
    availableCredit: 12549.25,
    creditLimit: 15000,
    minimumPayment: 35.00,
    dueDate: addDays(new Date(), 15).toISOString(),
    apr: 16.99,
    rewardsBalance: 50000,
    lastStatementDate: addDays(new Date(), -15).toISOString(),
    status: 'active',
    cardHolder: 'Sarah Johnson',
    expiryDate: '12/25',
    recentCharges: [
      {
        id: 'c1',
        date: new Date().toISOString(),
        merchant: 'Whole Foods Market',
        amount: 89.24,
        category: 'Groceries',
      },
      {
        id: 'c2',
        date: addDays(new Date(), -1).toISOString(),
        merchant: 'Amazon.com',
        amount: 156.73,
        category: 'Shopping',
      },
    ],
  },
  {
    id: '2',
    issuer: 'American Express',
    cardName: 'Gold Card',
    cardNumber: '****1234',
    cardType: 'amex',
    currentBalance: 3750.50,
    statementBalance: 3500.00,
    availableCredit: 16249.50,
    creditLimit: 20000,
    minimumPayment: 45.00,
    dueDate: addDays(new Date(), 20).toISOString(),
    apr: 18.99,
    rewardsBalance: 75000,
    lastStatementDate: addDays(new Date(), -10).toISOString(),
    status: 'active',
    cardHolder: 'Sarah Johnson',
    expiryDate: '09/26',
    recentCharges: [
      {
        id: 'c3',
        date: new Date().toISOString(),
        merchant: 'Delta Airlines',
        amount: 450.00,
        category: 'Travel',
      },
    ],
  },
];

export const useCreditStore = create<CreditState>((set) => ({
  cards: mockCards,
  isLoading: false,
  error: null,

  addCard: (card) => {
    const newCard = {
      ...card,
      id: Math.random().toString(36).substr(2, 9),
    };
    set((state) => ({
      cards: [...state.cards, newCard],
    }));
  },

  removeCard: (id) => {
    set((state) => ({
      cards: state.cards.filter((card) => card.id !== id),
    }));
  },

  updateCard: (id, data) => {
    set((state) => ({
      cards: state.cards.map((card) =>
        card.id === id ? { ...card, ...data } : card
      ),
    }));
  },
}));