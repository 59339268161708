import React from 'react';
import { format } from 'date-fns';
import { Heart, MessageCircle, Share2, MoreHorizontal } from 'lucide-react';
import { useCommunityStore } from '../../store/communityStore';

function PostsList() {
  const { posts, likePost, addComment } = useCommunityStore();

  return (
    <div className="space-y-6">
      {posts.map((post) => (
        <div key={post.id} className="bg-white rounded-lg shadow">
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <img
                  src={post.author.avatar}
                  alt={post.author.name}
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <p className="font-medium">{post.author.name}</p>
                  <p className="text-sm text-gray-500">
                    {format(new Date(post.createdAt), 'MMM d, yyyy')}
                  </p>
                </div>
              </div>
              <button className="text-gray-400 hover:text-gray-500">
                <MoreHorizontal className="h-5 w-5" />
              </button>
            </div>

            <p className="mt-4">{post.content}</p>

            {post.tags.length > 0 && (
              <div className="mt-2 flex flex-wrap gap-2">
                {post.tags.map((tag) => (
                  <span
                    key={tag}
                    className="text-indigo-600 text-sm hover:underline"
                  >
                    #{tag}
                  </span>
                ))}
              </div>
            )}

            {post.media && post.media.length > 0 && (
              <div className="mt-4">
                {post.media.map((media, index) => (
                  <div key={index} className="rounded-lg overflow-hidden">
                    {media.type === 'image' ? (
                      <img
                        src={media.url}
                        alt=""
                        className="w-full h-auto"
                      />
                    ) : (
                      <video
                        src={media.url}
                        controls
                        className="w-full h-auto"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}

            <div className="mt-4 flex items-center justify-between pt-4 border-t">
              <button
                onClick={() => likePost(post.id)}
                className="flex items-center space-x-2 text-gray-500 hover:text-red-500"
              >
                <Heart className="h-5 w-5" />
                <span>{post.likes}</span>
              </button>
              <button className="flex items-center space-x-2 text-gray-500 hover:text-gray-700">
                <MessageCircle className="h-5 w-5" />
                <span>{post.comments.length}</span>
              </button>
              <button className="flex items-center space-x-2 text-gray-500 hover:text-gray-700">
                <Share2 className="h-5 w-5" />
              </button>
            </div>
          </div>

          {post.comments.length > 0 && (
            <div className="bg-gray-50 p-4 border-t">
              {post.comments.map((comment) => (
                <div key={comment.id} className="flex space-x-3 mb-4">
                  <img
                    src={comment.author.avatar}
                    alt={comment.author.name}
                    className="h-8 w-8 rounded-full"
                  />
                  <div>
                    <div className="bg-white rounded-lg px-4 py-2">
                      <p className="font-medium">{comment.author.name}</p>
                      <p className="text-sm">{comment.content}</p>
                    </div>
                    <div className="flex items-center space-x-4 mt-1 text-sm text-gray-500">
                      <button className="hover:text-gray-700">Like</button>
                      <button className="hover:text-gray-700">Reply</button>
                      <span>{format(new Date(comment.createdAt), 'MMM d, yyyy')}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default PostsList;