import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { io, Socket } from 'socket.io-client';
import { Message } from '../types/mentorship';
import { useAuthStore } from './authStore';

interface ChatState {
  messages: Message[];
  socket: Socket | null;
  isConnected: boolean;
  isLoading: boolean;
  error: string | null;
  initialize: () => void;
  disconnect: () => void;
  sendMessage: (content: string, receiverId: string, attachments?: File[]) => Promise<void>;
  markAsRead: (messageId: string) => void;
  loadMessages: (userId: string) => Promise<void>;
}

export const useChatStore = create<ChatState>()(
  persist(
    (set, get) => ({
      messages: [],
      socket: null,
      isConnected: false,
      isLoading: false,
      error: null,

      initialize: () => {
        const { user } = useAuthStore.getState();
        if (!user) return;

        // Create socket connection
        const socket = io('http://localhost:3000', {
          auth: {
            token: user.token
          },
          transports: ['polling', 'websocket'], // Try polling first, then upgrade to websocket
          reconnection: true,
          reconnectionAttempts: 5,
          reconnectionDelay: 1000,
          timeout: 10000
        });

        socket.on('connect', () => {
          console.log('Socket connected');
          set({ isConnected: true, error: null });
        });

        socket.on('connect_error', (error) => {
          console.error('Socket connection error:', error);
          set({ error: 'Connection failed', isConnected: false });
        });

        socket.on('receive_message', (message: Message) => {
          console.log('Received message:', message);
          set(state => ({
            messages: [...state.messages, message]
          }));
        });

        socket.on('message_sent', (message: Message) => {
          console.log('Message sent confirmation:', message);
          set(state => ({
            messages: [...state.messages, message]
          }));
        });

        socket.on('messages_read', ({ readBy }) => {
          set(state => ({
            messages: state.messages.map(msg =>
              msg.receiverId === readBy ? { ...msg, read: true } : msg
            )
          }));
        });

        set({ socket });
      },

      disconnect: () => {
        const { socket } = get();
        if (socket) {
          socket.disconnect();
          set({ socket: null, isConnected: false });
        }
      },

      loadMessages: async (userId: string) => {
        try {
          set({ isLoading: true });
          const response = await fetch(`/api/messages/${userId}`);
          if (!response.ok) throw new Error('Failed to load messages');
          
          const messages = await response.json();
          console.log('Loaded messages:', messages);
          set({ messages, isLoading: false, error: null });
        } catch (error) {
          console.error('Load messages error:', error);
          set({ error: 'Failed to load messages', isLoading: false });
        }
      },

      sendMessage: async (content: string, receiverId: string, attachments?: File[]) => {
        try {
          const { socket } = get();
          if (!socket) throw new Error('Not connected');

          set({ isLoading: true });
          console.log('Sending message:', { content, receiverId });

          // Handle file uploads if any
          let uploadedAttachments = [];
          if (attachments?.length) {
            const formData = new FormData();
            attachments.forEach(file => formData.append('attachments', file));
            
            const response = await fetch('/api/messages/upload', {
              method: 'POST',
              body: formData
            });
            
            if (!response.ok) throw new Error('Failed to upload attachments');
            uploadedAttachments = await response.json();
          }

          // Emit message through socket
          socket.emit('send_message', {
            content,
            receiverId,
            attachments: uploadedAttachments
          });

          set({ isLoading: false, error: null });
        } catch (error) {
          console.error('Send message error:', error);
          set({ error: 'Failed to send message', isLoading: false });
          throw error;
        }
      },

      markAsRead: async (senderId: string) => {
        try {
          const { socket } = get();
          if (!socket) throw new Error('Not connected');

          socket.emit('mark_read', { senderId });
        } catch (error) {
          console.error('Failed to mark messages as read:', error);
        }
      },
    }),
    {
      name: 'chat-storage',
      partialize: (state) => ({
        messages: state.messages,
      }),
    }
  )
);