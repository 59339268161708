import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { Search, MessageCircle, User, Clock } from 'lucide-react';
import { useMentorshipStore } from '../../store/mentorshipStore';
import { useSearchParams } from 'react-router-dom';
import { useAdminStore } from '../../store/adminStore';
import ChatInput from '../../components/mentorship/ChatInput';
import { useChatStore } from '../../store/chatStore';

function MentorshipAdminPage() {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId');
  const [selectedChat, setSelectedChat] = useState<string | null>(userId);
  const [searchQuery, setSearchQuery] = useState('');
  const { users } = useAdminStore();
  const { initialize, disconnect, sendMessage, messages, isLoading, loadMessages } = useChatStore();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    initialize();
    return () => disconnect();
  }, []);

  // Update selected chat when userId param changes
  useEffect(() => {
    if (userId) {
      setSelectedChat(userId);
      loadMessages(userId);
    }
  }, [userId]);

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async (content: string, attachments?: File[]) => {
    if (selectedChat) {
      console.log('Sending message to:', selectedChat);
      await sendMessage(content, selectedChat, attachments);
    }
  };

  const getUserInfo = (userId: string) => {
    return users.find(u => u.id === userId);
  };

  // Filter chats based on search query
  const filteredUsers = users.filter(user => {
    return (
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div className="h-[calc(100vh-2rem)] flex">
      {/* Rest of the component remains the same */}
      <div className="flex-1 flex flex-col">
        {selectedChat ? (
          <>
            <div className="p-4 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {getUserInfo(selectedChat)?.avatar ? (
                    <img
                      src={getUserInfo(selectedChat)?.avatar}
                      alt={getUserInfo(selectedChat)?.name}
                      className="h-10 w-10 rounded-full"
                    />
                  ) : (
                    <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
                      <User className="h-6 w-6 text-gray-500 dark:text-gray-400" />
                    </div>
                  )}
                  <div className="ml-3">
                    <h2 className="text-lg font-medium">{getUserInfo(selectedChat)?.name}</h2>
                    <p className="text-sm text-gray-500">{getUserInfo(selectedChat)?.email}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto p-4 space-y-4">
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`flex ${
                    message.senderId === 'admin' ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <div
                    className={`max-w-md rounded-lg px-4 py-2 ${
                      message.senderId === 'admin'
                        ? 'bg-primary-400 text-white'
                        : 'bg-gray-100 dark:bg-gray-700'
                    }`}
                  >
                    <p className="text-sm">{message.content}</p>
                    <p className="text-xs mt-1 opacity-75">
                      {format(new Date(message.timestamp), 'h:mm a')}
                    </p>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>

            <ChatInput onSend={handleSendMessage} isLoading={isLoading} />
          </>
        ) : (
          <div className="flex-1 flex items-center justify-center">
            <div className="text-center">
              <MessageCircle className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500">Select a conversation to view messages</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MentorshipAdminPage;