import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";
import AuthLayout from "./AuthLayout";
import { registerWithEmail } from "../../utils/firebase-auth";

const registerSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters"),
  email: z.string().email("Invalid email address"),
  password: z.string().min(6, "Password must be at least 6 characters"),
});

type RegisterFormData = z.infer<typeof registerSchema>;

function RegisterForm() {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    error,
    clearError,
    register: registerUser,
  } = useAuthStore();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RegisterFormData>({
    resolver: zodResolver(registerSchema),
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    return () => clearError();
  }, [isAuthenticated, navigate, clearError]);

  const onSubmit = async (data: RegisterFormData) => {
    try {
      await registerUser(data.email, data.password, data.name);
    } catch (error) {
      console.log(error);

      // Error is handled by the store
    }
  };

  return (
    <AuthLayout
      title="Create an account"
      subtitle="Already have an account?"
      linkText="Sign in"
      linkTo="/login"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
            {error}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Full name
          </label>
          <input
            type="text"
            {...register("name")}
            className="mt-1.5 sm:mt-2 block w-full rounded-xl border border-gray-200 shadow-sm focus:ring-2 focus:ring-[#B100FF] focus:ring-opacity-30 focus:border-[#B100FF] focus:outline-none text-gray-900 text-sm sm:text-base px-3 py-2.5 sm:px-4 sm:py-3.5 bg-white"
          />
          {errors.name && (
            <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <input
            type="email"
            {...register("email")}
            className="mt-1.5 sm:mt-2 block w-full rounded-xl border border-gray-200 shadow-sm focus:ring-2 focus:ring-[#B100FF] focus:ring-opacity-30 focus:border-[#B100FF] focus:outline-none text-gray-900 text-sm sm:text-base px-3 py-2.5 sm:px-4 sm:py-3.5 bg-white"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <input
            type="password"
            {...register("password")}
            className="mt-1.5 sm:mt-2 block w-full rounded-xl border border-gray-200 shadow-sm focus:ring-2 focus:ring-[#B100FF] focus:ring-opacity-30 focus:border-[#B100FF] focus:outline-none text-gray-900 text-sm sm:text-base px-3 py-2.5 sm:px-4 sm:py-3.5 bg-white"
          />
          {errors.password && (
            <p className="mt-1 text-sm text-red-600">
              {errors.password.message}
            </p>
          )}
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting ? "Creating account..." : "Create account"}
        </button>
      </form>
    </AuthLayout>
  );
}

export default RegisterForm;
