import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuthStore } from "./store/authStore";
import { useThemeStore } from "./store/themeStore";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/firebase";

// Auth Components
import LoginForm from "./components/auth/LoginForm";
import RegisterForm from "./components/auth/RegisterForm";

// Layout Components
import AdminLayout from "./components/layout/AdminLayout";
import AppLayout from "./components/layout/AppLayout";

// Pages
import Dashboard from "./pages/Dashboard";
import ReportsPage from "./pages/ReportsPage";
import BudgetPage from "./pages/BudgetPage";
import ToolsPage from "./pages/ToolsPage";
import CoursesPage from "./pages/CoursesPage";
import CourseDetailsPage from "./pages/CourseDetailsPage";
import MentorshipPage from "./pages/MentorshipPage";
import CommunityPage from "./pages/CommunityPage";
import ProfilePage from "./pages/ProfilePage";
import SettingsPage from "./pages/SettingsPage";

// Account Pages
import AccountsOverview from "./pages/accounts/AccountsOverview";
import BankAccountsPage from "./pages/accounts/BankAccountsPage";
import CreditCardsPage from "./pages/accounts/CreditCardsPage";
import LoansPage from "./pages/accounts/LoansPage";
import RetirementAccountsPage from "./pages/accounts/RetirementAccountsPage";
import OtherAccountsPage from "./pages/accounts/OtherAccountsPage";

// Investment Pages
import InvestmentsLayout from "./pages/investments/InvestmentsLayout";
import InvestmentsOverview from "./pages/investments/InvestmentsOverview";
import StocksPage from "./pages/investments/StocksPage";
import BondsPage from "./pages/investments/BondsPage";
import CommoditiesPage from "./pages/investments/CommoditiesPage";
import RealEstatePage from "./pages/investments/RealEstatePage";
import CryptoPage from "./pages/investments/CryptoPage";
import OtherInvestmentsPage from "./pages/investments/OtherInvestmentsPage";

// Admin Pages
import UsersPage from "./pages/admin/UsersPage";
import ClientPortalPage from "./pages/admin/ClientPortalPage";
import CourseManagementPage from "./pages/admin/CourseManagementPage";
import MentorshipAdminPage from "./pages/admin/MentorshipAdminPage";
import CommunityModerationPage from "./pages/admin/CommunityModerationPage";
import UserViewPage from "./pages/admin/UserViewPage";

function App() {
  const { isDarkMode } = useThemeStore();
  const { isAuthenticated, user } = useAuthStore();
  const isAdmin = user?.role === "admin";

  if (!isAuthenticated) {
    return (
      <div className={isDarkMode ? "dark" : ""}>
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    );
  }

  return (
    <div className={isDarkMode ? "dark" : ""}>
      <Routes>
        {isAdmin ? (
          <Route path="/*" element={<AdminLayout />}>
            <Route index element={<Navigate to="/admin/users" />} />
            <Route path="admin/users" element={<UsersPage />} />
            <Route path="admin/users/:userId/*" element={<UserViewPage />} />
            <Route path="admin/clients" element={<ClientPortalPage />} />
            <Route path="admin/courses" element={<CourseManagementPage />} />
            <Route path="admin/mentorship" element={<MentorshipAdminPage />} />
            <Route
              path="admin/community"
              element={<CommunityModerationPage />}
            />
            <Route path="*" element={<Navigate to="/admin/users" replace />} />
          </Route>
        ) : (
          <Route path="/*" element={<AppLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="accounts">
              <Route index element={<AccountsOverview />} />
              <Route path="bank" element={<BankAccountsPage />} />
              <Route path="credit" element={<CreditCardsPage />} />
              <Route path="loans" element={<LoansPage />} />
              <Route path="retirement" element={<RetirementAccountsPage />} />
              <Route path="other" element={<OtherAccountsPage />} />
            </Route>
            <Route path="budget" element={<BudgetPage />} />
            <Route path="investments" element={<InvestmentsLayout />}>
              <Route index element={<InvestmentsOverview />} />
              <Route path="stocks" element={<StocksPage />} />
              <Route path="bonds" element={<BondsPage />} />
              <Route path="commodities" element={<CommoditiesPage />} />
              <Route path="real-estate" element={<RealEstatePage />} />
              <Route path="crypto" element={<CryptoPage />} />
              <Route path="other" element={<OtherInvestmentsPage />} />
            </Route>
            <Route path="tools" element={<ToolsPage />} />
            <Route path="courses" element={<CoursesPage />} />
            <Route path="courses/:courseId" element={<CourseDetailsPage />} />
            <Route path="mentorship" element={<MentorshipPage />} />
            <Route path="community" element={<CommunityPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        )}
      </Routes>
    </div>
  );
}

export default App;
