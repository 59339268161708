import React, { useState } from 'react';
import { Calculator, DollarSign, Percent, Plus, Trash2, ArrowDown, ArrowUp } from 'lucide-react';

interface Debt {
  id: string;
  name: string;
  balance: number;
  interestRate: number;
  minimumPayment: number;
}

function DebtPayoffCalculator() {
  const [debts, setDebts] = useState<Debt[]>([]);
  const [monthlyPayment, setMonthlyPayment] = useState<number>(0);
  const [strategy, setStrategy] = useState<'avalanche' | 'snowball'>('avalanche');
  const [newDebt, setNewDebt] = useState<Omit<Debt, 'id'>>({
    name: '',
    balance: 0,
    interestRate: 0,
    minimumPayment: 0,
  });

  const addDebt = () => {
    if (newDebt.name && newDebt.balance > 0) {
      setDebts([...debts, { ...newDebt, id: Math.random().toString(36).substr(2, 9) }]);
      setNewDebt({ name: '', balance: 0, interestRate: 0, minimumPayment: 0 });
    }
  };

  const removeDebt = (id: string) => {
    setDebts(debts.filter(debt => debt.id !== id));
  };

  const calculatePayoff = () => {
    if (debts.length === 0) return null;

    const totalMinPayment = debts.reduce((sum, debt) => sum + debt.minimumPayment, 0);
    if (monthlyPayment < totalMinPayment) return null;

    const extraPayment = monthlyPayment - totalMinPayment;
    let remainingDebts = [...debts].map(debt => ({ ...debt }));
    let months = 0;
    let totalInterestPaid = 0;
    const paymentSchedule: Array<{
      month: number;
      payments: Array<{
        debtId: string;
        payment: number;
        interestPaid: number;
        remainingBalance: number;
      }>;
    }> = [];

    while (remainingDebts.length > 0) {
      months++;
      const monthlyPayments: Array<{
        debtId: string;
        payment: number;
        interestPaid: number;
        remainingBalance: number;
      }> = [];

      // Sort debts according to strategy
      remainingDebts.sort((a, b) => {
        if (strategy === 'avalanche') {
          return b.interestRate - a.interestRate;
        } else {
          return a.balance - b.balance;
        }
      });

      // Calculate interest and make minimum payments
      for (const debt of remainingDebts) {
        const monthlyInterest = (debt.interestRate / 100 / 12) * debt.balance;
        totalInterestPaid += monthlyInterest;
        debt.balance += monthlyInterest;

        const payment = debt.minimumPayment;
        debt.balance -= payment;

        monthlyPayments.push({
          debtId: debt.id,
          payment,
          interestPaid: monthlyInterest,
          remainingBalance: debt.balance,
        });
      }

      // Apply extra payment to first debt
      if (remainingDebts.length > 0 && extraPayment > 0) {
        const targetDebt = remainingDebts[0];
        const payment = Math.min(extraPayment, targetDebt.balance);
        targetDebt.balance -= payment;
        monthlyPayments[0].payment += payment;
        monthlyPayments[0].remainingBalance = targetDebt.balance;
      }

      paymentSchedule.push({
        month: months,
        payments: monthlyPayments,
      });

      // Remove paid off debts
      remainingDebts = remainingDebts.filter(debt => debt.balance > 0.01);
    }

    return {
      months,
      totalInterestPaid,
      paymentSchedule,
    };
  };

  const results = monthlyPayment > 0 ? calculatePayoff() : null;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Monthly Payment Available
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={monthlyPayment}
                onChange={(e) => setMonthlyPayment(Number(e.target.value))}
                className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                placeholder="Enter monthly payment"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Payoff Strategy
            </label>
            <select
              value={strategy}
              onChange={(e) => setStrategy(e.target.value as 'avalanche' | 'snowball')}
              className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
            >
              <option value="avalanche">Debt Avalanche (Highest Interest First)</option>
              <option value="snowball">Debt Snowball (Lowest Balance First)</option>
            </select>
          </div>

          <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
            <h3 className="text-lg font-medium mb-4">Add Debt</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Debt Name
                </label>
                <input
                  type="text"
                  value={newDebt.name}
                  onChange={(e) => setNewDebt({ ...newDebt, name: e.target.value })}
                  className="block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                  placeholder="e.g., Credit Card 1"
                />
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Balance
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <DollarSign className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={newDebt.balance}
                      onChange={(e) => setNewDebt({ ...newDebt, balance: Number(e.target.value) })}
                      className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                      placeholder="Balance"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Interest Rate
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Percent className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={newDebt.interestRate}
                      onChange={(e) => setNewDebt({ ...newDebt, interestRate: Number(e.target.value) })}
                      step="0.1"
                      className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                      placeholder="Rate"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Min Payment
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <DollarSign className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="number"
                      value={newDebt.minimumPayment}
                      onChange={(e) => setNewDebt({ ...newDebt, minimumPayment: Number(e.target.value) })}
                      className="block w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
                      placeholder="Min payment"
                    />
                  </div>
                </div>
              </div>

              <button
                onClick={addDebt}
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500"
              >
                <Plus className="h-5 w-5 mr-2" />
                Add Debt
              </button>
            </div>
          </div>

          {debts.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Current Debts</h3>
              {debts.map((debt) => (
                <div
                  key={debt.id}
                  className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
                >
                  <div>
                    <p className="font-medium">{debt.name}</p>
                    <p className="text-sm text-gray-500">
                      ${debt.balance.toLocaleString()} at {debt.interestRate}%
                    </p>
                  </div>
                  <button
                    onClick={() => removeDebt(debt.id)}
                    className="p-2 text-gray-400 hover:text-red-500"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
          <h3 className="text-lg font-medium mb-6">Payoff Summary</h3>
          
          {results && debts.length > 0 ? (
            <div className="space-y-6">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Time to Debt Freedom
                </p>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {results.months} months ({(results.months / 12).toFixed(1)} years)
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  Total Interest Paid
                </p>
                <p className="text-2xl font-bold text-red-500">
                  ${results.totalInterestPaid.toFixed(2)}
                </p>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-600">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                  Payoff Order
                </h4>
                <div className="space-y-3">
                  {debts
                    .sort((a, b) => 
                      strategy === 'avalanche' 
                        ? b.interestRate - a.interestRate
                        : a.balance - b.balance
                    )
                    .map((debt, index) => (
                      <div
                        key={debt.id}
                        className="flex items-center justify-between p-3 bg-white dark:bg-gray-800 rounded-lg"
                      >
                        <div className="flex items-center">
                          <span className="w-6 h-6 flex items-center justify-center bg-primary-100 dark:bg-primary-900 text-primary-600 dark:text-primary-400 rounded-full text-sm font-medium mr-3">
                            {index + 1}
                          </span>
                          <div>
                            <p className="font-medium">{debt.name}</p>
                            <p className="text-sm text-gray-500">
                              ${debt.balance.toLocaleString()} at {debt.interestRate}%
                            </p>
                          </div>
                        </div>
                        {strategy === 'avalanche' ? (
                          <div className="flex items-center text-red-500">
                            <ArrowUp className="h-4 w-4 mr-1" />
                            <span className="text-sm">{debt.interestRate}%</span>
                          </div>
                        ) : (
                          <div className="flex items-center text-green-500">
                            <ArrowDown className="h-4 w-4 mr-1" />
                            <span className="text-sm">${debt.balance.toLocaleString()}</span>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <Calculator className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500 dark:text-gray-400">
                Add your debts and monthly payment to see your payoff plan
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DebtPayoffCalculator;