import React, { useState } from 'react';
import { Download } from 'lucide-react';
import { generatePDF } from '../../utils/pdfGenerator';

interface DownloadButtonProps {
  reportId: string;
  fileName: string;
}

function DownloadButton({ reportId, fileName }: DownloadButtonProps) {
  const [isGenerating, setIsGenerating] = useState(false);

  const handleDownload = async () => {
    try {
      setIsGenerating(true);
      await generatePDF(reportId, fileName);
    } catch (error) {
      console.error('Failed to download report:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <button
      onClick={handleDownload}
      disabled={isGenerating}
      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 disabled:opacity-50 disabled:cursor-not-allowed"
    >
      <Download className="h-4 w-4 mr-2" />
      {isGenerating ? 'Generating...' : 'Download PDF'}
    </button>
  );
}

export default DownloadButton;