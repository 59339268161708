import React from 'react';
import { useCommunityStore } from '../store/communityStore';
import CreatePost from '../components/community/CreatePost';
import StoriesList from '../components/community/StoriesList';
import PostsList from '../components/community/PostsList';

function CommunityPage() {
  return (
    <div className="max-w-2xl mx-auto space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Community</h1>
      <StoriesList />
      <CreatePost />
      <PostsList />
    </div>
  );
}

export default CommunityPage;