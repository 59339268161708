import React, { useState, useEffect } from 'react';
import { Wrench, Calculator, TrendingUp, FileText, Building2, Target, Coins, DollarSign } from 'lucide-react';
import LoanCalculator from '../components/tools/LoanCalculator';
import InvestmentCalculator from '../components/tools/InvestmentCalculator';
import TaxPlanner from '../components/tools/TaxPlanner';
import PropertyCalculator from '../components/tools/PropertyCalculator';
import RetirementCalculator from '../components/tools/RetirementCalculator';
import DebtPayoffCalculator from '../components/tools/DebtPayoffCalculator';
import AffordabilityCalculator from '../components/tools/AffordabilityCalculator';

type ToolType = 'loan' | 'investment' | 'tax' | 'property' | 'retirement' | 'debt' | 'affordability';

function ToolsPage() {
  const [activeTool, setActiveTool] = useState<ToolType | null>(null);

  useEffect(() => {
    const storedToolId = sessionStorage.getItem('activeToolId');
    if (storedToolId) {
      setActiveTool(storedToolId as ToolType);
      sessionStorage.removeItem('activeToolId');
    }
  }, []);

  const tools = [
    {
      id: 'affordability',
      name: 'Affordability Calculator',
      description: 'Analyze if you can afford a purchase and its financial impact',
      icon: DollarSign,
      component: AffordabilityCalculator,
    },
    {
      id: 'loan',
      name: 'Loan Calculator',
      description: 'Calculate loan payments and amortization schedules',
      icon: Calculator,
      component: LoanCalculator,
    },
    {
      id: 'investment',
      name: 'Investment Calculator',
      description: 'Project investment growth and returns over time',
      icon: TrendingUp,
      component: InvestmentCalculator,
    },
    {
      id: 'tax',
      name: 'Tax Planner',
      description: 'Estimate tax liability and plan deductions',
      icon: FileText,
      component: TaxPlanner,
    },
    {
      id: 'property',
      name: 'Property Calculator',
      description: 'Analyze potential real estate investments',
      icon: Building2,
      component: PropertyCalculator,
    },
    {
      id: 'retirement',
      name: 'Retirement Calculator',
      description: 'Plan your retirement savings and income',
      icon: Target,
      component: RetirementCalculator,
    },
    {
      id: 'debt',
      name: 'Debt Payoff Calculator',
      description: 'Create a debt payoff plan using snowball or avalanche method',
      icon: Coins,
      component: DebtPayoffCalculator,
    },
  ];

  if (activeTool) {
    const Tool = tools.find(t => t.id === activeTool)?.component;
    if (!Tool) return null;

    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
              {tools.find(t => t.id === activeTool)?.name}
            </h1>
          </div>
          <button
            onClick={() => setActiveTool(null)}
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            Back to Tools
          </button>
        </div>
        <Tool />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Financial Tools</h1>
          <Wrench className="h-8 w-8 text-primary-400" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {tools.map((tool) => (
          <div
            key={tool.id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow flex flex-col"
          >
            <div className="flex items-center space-x-4 mb-4">
              <div className="p-3 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                <tool.icon className="h-6 w-6 text-primary-400" />
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">{tool.name}</h3>
              </div>
            </div>
            <p className="text-gray-600 dark:text-gray-300 flex-grow">{tool.description}</p>
            <button
              onClick={() => setActiveTool(tool.id as ToolType)}
              className="mt-6 w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500 dark:hover:bg-primary-600 transition-colors duration-200"
            >
              Launch Tool
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ToolsPage;