import React from 'react';
import { format } from 'date-fns';
import { Landmark, ExternalLink, Trash2, TrendingUp, DollarSign, PieChart } from 'lucide-react';
import { RetirementAccount } from '../../types/retirement';

interface RetirementAccountCardProps {
  account: RetirementAccount;
  onDelete: (id: string) => void;
}

function RetirementAccountCard({ account, onDelete }: RetirementAccountCardProps) {
  const contributionProgress = 
    (account.contributions.ytd / account.contributions.limits.annual) * 100;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transition-all hover:shadow-xl">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
            <Landmark className="h-6 w-6 text-primary-400" />
          </div>
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">
              {account.type.toUpperCase().replace('_', ' ')}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">{account.provider}</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onDelete(account.id)}
            className="p-2 text-gray-400 hover:text-red-500 rounded-lg transition-colors"
          >
            <Trash2 className="h-5 w-5" />
          </button>
          <button className="p-2 text-gray-400 hover:text-primary-500 rounded-lg transition-colors">
            <ExternalLink className="h-5 w-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <p className="text-sm text-gray-500 dark:text-gray-400">Current Balance</p>
          <p className="text-xl font-bold text-gray-900 dark:text-white">
            ${account.balance.toLocaleString()}
          </p>
        </div>
        <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <p className="text-sm text-gray-500 dark:text-gray-400">YTD Contributions</p>
          <p className="text-xl font-bold text-gray-900 dark:text-white">
            ${account.contributions.ytd.toLocaleString()}
          </p>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <div className="flex justify-between items-center mb-2">
            <p className="text-sm text-gray-500 dark:text-gray-400">Contribution Progress</p>
            <p className="text-sm font-medium text-primary-400">
              ${account.contributions.ytd.toLocaleString()} of $
              {account.contributions.limits.annual.toLocaleString()}
            </p>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
            <div
              className="bg-primary-400 h-2 rounded-full"
              style={{ width: `${contributionProgress}%` }}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <div className="flex items-center mb-2">
              <TrendingUp className="h-4 w-4 text-gray-400 mr-2" />
              <p className="text-sm text-gray-500 dark:text-gray-400">Performance</p>
            </div>
            <div className="space-y-1">
              <div className="flex justify-between text-sm">
                <span className="text-gray-900 dark:text-white">YTD</span>
                <span className={`font-medium ${
                  account.performance.year >= 0 ? 'text-green-500' : 'text-red-500'
                }`}>
                  {account.performance.year >= 0 ? '+' : ''}
                  {account.performance.year}%
                </span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-900 dark:text-white">All Time</span>
                <span className={`font-medium ${
                  account.performance.allTime >= 0 ? 'text-green-500' : 'text-red-500'
                }`}>
                  {account.performance.allTime >= 0 ? '+' : ''}
                  {account.performance.allTime}%
                </span>
              </div>
            </div>
          </div>

          <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <div className="flex items-center mb-2">
              <PieChart className="h-4 w-4 text-gray-400 mr-2" />
              <p className="text-sm text-gray-500 dark:text-gray-400">Allocation</p>
            </div>
            <div className="space-y-1">
              <div className="flex justify-between text-sm">
                <span className="text-gray-900 dark:text-white">Stocks</span>
                <span className="font-medium text-gray-900 dark:text-white">
                  {account.allocation.stocks}%
                </span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-900 dark:text-white">Bonds</span>
                <span className="font-medium text-gray-900 dark:text-white">
                  {account.allocation.bonds}%
                </span>
              </div>
            </div>
          </div>
        </div>

        {account.investments.length > 0 && (
          <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
            <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
              Top Holdings
            </h4>
            <div className="space-y-2">
              {account.investments.slice(0, 3).map((investment) => (
                <div
                  key={investment.id}
                  className="flex justify-between items-center p-2 bg-gray-50 dark:bg-gray-700 rounded-lg"
                >
                  <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {investment.name}
                    </p>
                    <p className="text-xs text-gray-500">
                      {investment.symbol} • {investment.allocation.toFixed(1)}%
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      ${investment.value.toLocaleString()}
                    </p>
                    <p className={`text-xs ${
                      investment.performance.year >= 0
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}>
                      {investment.performance.year >= 0 ? '+' : ''}
                      {investment.performance.year}% YTD
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RetirementAccountCard;