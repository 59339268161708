import React from 'react';
import { TrendingUp, TrendingDown, AlertTriangle, CheckCircle } from 'lucide-react';

interface Insight {
  type: 'positive' | 'negative' | 'warning' | 'action';
  title: string;
  description: string;
}

interface QualitativeInsightsProps {
  insights: Insight[];
}

function QualitativeInsights({ insights }: QualitativeInsightsProps) {
  const getIcon = (type: Insight['type']) => {
    switch (type) {
      case 'positive':
        return <TrendingUp className="h-5 w-5 text-green-500" />;
      case 'negative':
        return <TrendingDown className="h-5 w-5 text-red-500" />;
      case 'warning':
        return <AlertTriangle className="h-5 w-5 text-yellow-500" />;
      case 'action':
        return <CheckCircle className="h-5 w-5 text-primary-400" />;
    }
  };

  const getBackgroundColor = (type: Insight['type']) => {
    switch (type) {
      case 'positive':
        return 'bg-green-50 dark:bg-green-900/20';
      case 'negative':
        return 'bg-red-50 dark:bg-red-900/20';
      case 'warning':
        return 'bg-yellow-50 dark:bg-yellow-900/20';
      case 'action':
        return 'bg-primary-50 dark:bg-primary-900/20';
    }
  };

  return (
    <div className="space-y-4">
      {insights.map((insight, index) => (
        <div
          key={index}
          className={`${getBackgroundColor(insight.type)} rounded-lg p-4`}
        >
          <div className="flex items-start space-x-3">
            {getIcon(insight.type)}
            <div>
              <h4 className="font-medium text-gray-900 dark:text-white">
                {insight.title}
              </h4>
              <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                {insight.description}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default QualitativeInsights;