import { create } from 'zustand';
import { BankState, BankAccount } from '../types/bank';

const mockAccounts: BankAccount[] = [
  {
    id: '1',
    bankName: 'Chase Bank',
    accountName: 'Primary Checking',
    accountNumber: '****4567',
    type: 'checking',
    balance: 5420.50,
    currency: 'USD',
    lastUpdated: new Date().toISOString(),
    status: 'active',
    accountHolder: 'Sarah Johnson',
    routingNumber: '****1234',
    interestRate: 0.01,
  },
  {
    id: '2',
    bankName: 'Bank of America',
    accountName: 'High-Yield Savings',
    accountNumber: '****7890',
    type: 'savings',
    balance: 25750.75,
    currency: 'USD',
    lastUpdated: new Date().toISOString(),
    status: 'active',
    accountHolder: 'Sarah Johnson',
    routingNumber: '****5678',
    interestRate: 3.5,
  }
];

export const useBankStore = create<BankState>((set) => ({
  accounts: mockAccounts,
  isLoading: false,
  error: null,
  isLinking: false,

  setIsLinking: (isLinking: boolean) => set({ isLinking }),
  setError: (error: string | null) => set({ error }),

  addAccount: (account) => {
    const newAccount = {
      ...account,
      id: Math.random().toString(36).substr(2, 9),
    };
    set((state) => ({
      accounts: [...state.accounts, newAccount],
    }));
  },

  removeAccount: (id) => {
    set((state) => ({
      accounts: state.accounts.filter((account) => account.id !== id),
    }));
  },

  updateAccount: (id, data) => {
    set((state) => ({
      accounts: state.accounts.map((account) =>
        account.id === id ? { ...account, ...data } : account
      ),
    }));
  },
}));