import React, { useEffect, useRef } from 'react';
import { useMentorshipStore } from '../store/mentorshipStore';
import ChatMessage from '../components/mentorship/ChatMessage';
import ChatInput from '../components/mentorship/ChatInput';
import { Star, Calendar } from 'lucide-react';

function MentorshipPage() {
  const { messages, coach, isLoading, sendMessage } = useMentorshipStore();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  if (!coach) return null;

  return (
    <div className="flex h-[calc(100vh-4rem)]">
      <div className="flex-1 flex flex-col bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="flex-shrink-0 border-b px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src={coach.avatar}
                alt={coach.name}
                className="h-12 w-12 rounded-full"
              />
              <div className="ml-4">
                <h2 className="text-lg font-semibold">{coach.name}</h2>
                <div className="flex items-center text-sm text-gray-500">
                  <span>{coach.title}</span>
                  <span className="mx-2">•</span>
                  <div className="flex items-center">
                    <Star className="h-4 w-4 text-yellow-400 mr-1" />
                    <span>{coach.rating}</span>
                  </div>
                </div>
              </div>
            </div>
            <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
              <Calendar className="h-5 w-5 mr-2" />
              Schedule Call
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto px-6 py-4">
          {messages.map((message) => (
            <ChatMessage
              key={message.id}
              message={message}
              isUser={message.senderId === 'user1'}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>

        <ChatInput onSend={sendMessage} isLoading={isLoading} />
      </div>

      <div className="hidden lg:block w-80 border-l bg-gray-50 p-6">
        <h3 className="text-lg font-semibold mb-4">About Your Coach</h3>
        <p className="text-gray-600 text-sm mb-6">{coach.bio}</p>

        <h4 className="font-medium mb-2">Specialties</h4>
        <div className="flex flex-wrap gap-2 mb-6">
          {coach.specialties.map((specialty, index) => (
            <span
              key={index}
              className="bg-indigo-50 text-indigo-700 text-sm px-3 py-1 rounded-full"
            >
              {specialty}
            </span>
          ))}
        </div>

        <div className="bg-white rounded-lg p-4 shadow-sm">
          <h4 className="font-medium mb-2">Next Available</h4>
          <p className="text-sm text-gray-600">
            {new Date(coach.availability.nextAvailable).toLocaleDateString()} at{' '}
            {new Date(coach.availability.nextAvailable).toLocaleTimeString()}
          </p>
          <p className="text-xs text-gray-500">{coach.availability.timezone}</p>
        </div>
      </div>
    </div>
  );
}

export default MentorshipPage;