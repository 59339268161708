import React from 'react';
import { useBankStore } from '../../store/bankStore';
import { useCreditStore } from '../../store/creditStore';
import { useLoanStore } from '../../store/loanStore';
import { useRetirementStore } from '../../store/retirementStore';
import { useDigitalPaymentStore } from '../../store/digitalPaymentStore';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { Wallet, CreditCard, Building2, Landmark, DollarSign } from 'lucide-react';

function AccountsOverview() {
  const { accounts: bankAccounts } = useBankStore();
  const { cards: creditCards } = useCreditStore();
  const { loans } = useLoanStore();
  const { accounts: retirementAccounts } = useRetirementStore();
  const { accounts: digitalAccounts } = useDigitalPaymentStore();

  // Calculate total assets
  const totalBankBalance = bankAccounts.reduce((sum, acc) => sum + acc.balance, 0);
  const totalRetirementBalance = retirementAccounts.reduce((sum, acc) => sum + acc.balance, 0);
  const totalDigitalBalance = digitalAccounts.reduce((sum, acc) => sum + acc.balance, 0);
  const totalAssets = totalBankBalance + totalRetirementBalance + totalDigitalBalance;

  // Calculate total liabilities
  const totalCreditDebt = creditCards.reduce((sum, card) => sum + card.currentBalance, 0);
  const totalLoanDebt = loans.reduce((sum, loan) => sum + loan.currentBalance, 0);
  const totalLiabilities = totalCreditDebt + totalLoanDebt;

  // Calculate net worth
  const netWorth = totalAssets - totalLiabilities;

  const assetData = [
    { name: 'Bank Accounts', value: totalBankBalance, icon: Wallet },
    { name: 'Retirement', value: totalRetirementBalance, icon: Landmark },
    { name: 'Digital Payments', value: totalDigitalBalance, icon: DollarSign },
  ];

  const liabilityData = [
    { name: 'Credit Cards', value: totalCreditDebt, icon: CreditCard },
    { name: 'Loans', value: totalLoanDebt, icon: Building2 },
  ];

  const COLORS = ['#B100FF', '#10B981', '#F59E0B', '#6366F1', '#EC4899'];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Accounts Overview</h1>

      {/* Net Worth Summary */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-sm text-gray-500 dark:text-gray-400">Total Assets</h3>
          <p className="text-2xl font-bold text-green-500">
            ${totalAssets.toLocaleString()}
          </p>
        </div>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-sm text-gray-500 dark:text-gray-400">Total Liabilities</h3>
          <p className="text-2xl font-bold text-red-500">
            ${totalLiabilities.toLocaleString()}
          </p>
        </div>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-sm text-gray-500 dark:text-gray-400">Net Worth</h3>
          <p className={`text-2xl font-bold ${netWorth >= 0 ? 'text-green-500' : 'text-red-500'}`}>
            ${netWorth.toLocaleString()}
          </p>
        </div>
      </div>

      {/* Assets and Liabilities Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Assets Chart */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">Assets</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={assetData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {assetData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value: number) => [`$${value.toLocaleString()}`, 'Value']}
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-6 space-y-2">
            {assetData.map((item, index) => (
              <div
                key={item.name}
                className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <div className="flex items-center space-x-3">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  />
                  <div className="flex items-center space-x-2">
                    <item.icon className="h-4 w-4 text-gray-400" />
                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                      {item.name}
                    </span>
                  </div>
                </div>
                <div className="text-right">
                  <p className="text-sm font-semibold text-gray-900 dark:text-white">
                    ${item.value.toLocaleString()}
                  </p>
                  <p className="text-xs text-gray-500">
                    {((item.value / totalAssets) * 100).toFixed(1)}%
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Liabilities Chart */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">Liabilities</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={liabilityData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {liabilityData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value: number) => [`$${value.toLocaleString()}`, 'Value']}
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-6 space-y-2">
            {liabilityData.map((item, index) => (
              <div
                key={item.name}
                className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <div className="flex items-center space-x-3">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  />
                  <div className="flex items-center space-x-2">
                    <item.icon className="h-4 w-4 text-gray-400" />
                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                      {item.name}
                    </span>
                  </div>
                </div>
                <div className="text-right">
                  <p className="text-sm font-semibold text-gray-900 dark:text-white">
                    ${item.value.toLocaleString()}
                  </p>
                  <p className="text-xs text-gray-500">
                    {((item.value / totalLiabilities) * 100).toFixed(1)}%
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Account Quick Links */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
        <button
          onClick={() => window.location.href = '/accounts/bank'}
          className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
        >
          <Wallet className="h-8 w-8 text-primary-400 mb-2" />
          <h3 className="font-medium text-gray-900 dark:text-white">Bank Accounts</h3>
          <p className="text-sm text-gray-500">{bankAccounts.length} accounts</p>
        </button>
        <button
          onClick={() => window.location.href = '/accounts/credit'}
          className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
        >
          <CreditCard className="h-8 w-8 text-primary-400 mb-2" />
          <h3 className="font-medium text-gray-900 dark:text-white">Credit Cards</h3>
          <p className="text-sm text-gray-500">{creditCards.length} cards</p>
        </button>
        <button
          onClick={() => window.location.href = '/accounts/loans'}
          className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
        >
          <Building2 className="h-8 w-8 text-primary-400 mb-2" />
          <h3 className="font-medium text-gray-900 dark:text-white">Loans</h3>
          <p className="text-sm text-gray-500">{loans.length} loans</p>
        </button>
        <button
          onClick={() => window.location.href = '/accounts/retirement'}
          className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
        >
          <Landmark className="h-8 w-8 text-primary-400 mb-2" />
          <h3 className="font-medium text-gray-900 dark:text-white">Retirement</h3>
          <p className="text-sm text-gray-500">{retirementAccounts.length} accounts</p>
        </button>
        <button
          onClick={() => window.location.href = '/accounts/other'}
          className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
        >
          <DollarSign className="h-8 w-8 text-primary-400 mb-2" />
          <h3 className="font-medium text-gray-900 dark:text-white">Digital Payments</h3>
          <p className="text-sm text-gray-500">{digitalAccounts.length} accounts</p>
        </button>
      </div>
    </div>
  );
}

export default AccountsOverview;