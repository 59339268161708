import { create } from 'zustand';
import { RetirementState, RetirementAccount, Contribution } from '../types/retirement';
import { addDays } from 'date-fns';

const mockAccounts: RetirementAccount[] = [
  {
    id: '1',
    type: '401k',
    provider: 'Fidelity',
    accountNumber: '****7890',
    balance: 285000,
    vestingPercentage: 100,
    investments: [
      {
        id: 'inv1',
        name: 'Vanguard Target Retirement 2050',
        category: 'target_date',
        symbol: 'VFIFX',
        shares: 1250.45,
        price: 145.25,
        value: 181627.86,
        allocation: 63.73,
        performance: {
          day: 0.5,
          week: 1.2,
          month: 2.8,
          year: 12.5,
          allTime: 45.8,
        },
      },
      {
        id: 'inv2',
        name: 'Vanguard Total Bond Market',
        category: 'bonds',
        symbol: 'VBTLX',
        shares: 850.75,
        price: 85.50,
        value: 72739.13,
        allocation: 25.52,
        performance: {
          day: 0.1,
          week: 0.3,
          month: 0.8,
          year: 3.5,
          allTime: 15.2,
        },
      },
    ],
    contributions: {
      ytd: 12500,
      lifetime: 175000,
      employerMatch: 6250,
      lastContribution: {
        id: 'cont1',
        date: addDays(new Date(), -15).toISOString(),
        amount: 750,
        type: 'employee',
        category: 'pre_tax',
      },
      history: [],
      limits: {
        annual: 22500,
        catchUp: 7500,
        remaining: 10000,
      },
    },
    performance: {
      day: 0.4,
      week: 1.1,
      month: 2.5,
      year: 11.8,
      allTime: 42.5,
    },
    allocation: {
      stocks: 70,
      bonds: 25,
      cash: 3,
      other: 2,
    },
    status: 'active',
    lastUpdated: new Date().toISOString(),
  },
  {
    id: '2',
    type: 'roth_ira',
    provider: 'Vanguard',
    accountNumber: '****4567',
    balance: 85000,
    vestingPercentage: 100,
    investments: [
      {
        id: 'inv3',
        name: 'Vanguard Total Stock Market ETF',
        category: 'etfs',
        symbol: 'VTI',
        shares: 250.35,
        price: 220.75,
        value: 55264.76,
        allocation: 65.02,
        performance: {
          day: 0.6,
          week: 1.5,
          month: 3.2,
          year: 15.8,
          allTime: 52.3,
        },
      },
    ],
    contributions: {
      ytd: 4500,
      lifetime: 45000,
      employerMatch: 0,
      lastContribution: {
        id: 'cont2',
        date: addDays(new Date(), -7).toISOString(),
        amount: 500,
        type: 'employee',
        category: 'post_tax',
      },
      history: [],
      limits: {
        annual: 6500,
        catchUp: 1000,
        remaining: 2000,
      },
    },
    performance: {
      day: 0.5,
      week: 1.3,
      month: 2.9,
      year: 14.2,
      allTime: 48.7,
    },
    allocation: {
      stocks: 85,
      bonds: 10,
      cash: 5,
      other: 0,
    },
    status: 'active',
    lastUpdated: new Date().toISOString(),
  },
];

export const useRetirementStore = create<RetirementState>((set) => ({
  accounts: mockAccounts,
  isLoading: false,
  error: null,

  addAccount: (account) => {
    const newAccount = {
      ...account,
      id: Math.random().toString(36).substr(2, 9),
    };
    set((state) => ({
      accounts: [...state.accounts, newAccount],
    }));
  },

  removeAccount: (id) => {
    set((state) => ({
      accounts: state.accounts.filter((account) => account.id !== id),
    }));
  },

  updateAccount: (id, data) => {
    set((state) => ({
      accounts: state.accounts.map((account) =>
        account.id === id ? { ...account, ...data } : account
      ),
    }));
  },

  addContribution: (accountId, contribution) => {
    const newContribution = {
      ...contribution,
      id: Math.random().toString(36).substr(2, 9),
    };

    set((state) => ({
      accounts: state.accounts.map((account) =>
        account.id === accountId
          ? {
              ...account,
              balance: account.balance + contribution.amount,
              contributions: {
                ...account.contributions,
                ytd: account.contributions.ytd + contribution.amount,
                lifetime: account.contributions.lifetime + contribution.amount,
                lastContribution: newContribution,
                history: [newContribution, ...account.contributions.history],
              },
            }
          : account
      ),
    }));
  },
}));