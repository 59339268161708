import React from 'react';
import { TrendingUp, TrendingDown, Search, Filter, ArrowUpDown, DollarSign, Scale, Warehouse } from 'lucide-react';

function CommoditiesPage() {
  const commodityHoldings = [
    {
      id: '1',
      name: 'Gold',
      type: 'Precious Metals',
      quantity: 10, // in ounces
      unit: 'oz',
      purchasePrice: 1850.75,
      currentPrice: 2175.50,
      location: 'Brinks Vault - NYC',
      custodian: 'Brinks Security',
      totalValue: 21755.00,
      gain: 3247.50,
      gainPercent: 17.55,
      lastValuationDate: '2024-03-15',
      insuranceStatus: 'Insured',
      form: 'Physical Bullion',
      purity: '99.99%',
    },
    {
      id: '2',
      name: 'Silver',
      type: 'Precious Metals',
      quantity: 500,
      unit: 'oz',
      purchasePrice: 22.50,
      currentPrice: 25.75,
      location: 'Delaware Depository',
      custodian: 'Delaware Depository Services',
      totalValue: 12875.00,
      gain: 1625.00,
      gainPercent: 14.44,
      lastValuationDate: '2024-03-15',
      insuranceStatus: 'Insured',
      form: 'Physical Bars',
      purity: '99.9%',
    },
    {
      id: '3',
      name: 'Platinum',
      type: 'Precious Metals',
      quantity: 25,
      unit: 'oz',
      purchasePrice: 915.25,
      currentPrice: 975.50,
      location: 'Brinks Vault - London',
      custodian: 'Brinks Security',
      totalValue: 24387.50,
      gain: 1506.25,
      gainPercent: 6.58,
      lastValuationDate: '2024-03-15',
      insuranceStatus: 'Insured',
      form: 'Physical Bars',
      purity: '99.95%',
    },
  ];

  const totalValue = commodityHoldings.reduce((sum, holding) => sum + holding.totalValue, 0);
  const totalGain = commodityHoldings.reduce((sum, holding) => sum + holding.gain, 0);
  const averageGainPercent = commodityHoldings.reduce((sum, holding) => sum + holding.gainPercent, 0) / commodityHoldings.length;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-lg">
          <input
            type="search"
            placeholder="Search commodities..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700 dark:text-white"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <Filter className="h-5 w-5 mr-2" />
            Filter
          </button>
          <button className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
            <ArrowUpDown className="h-5 w-5 mr-2" />
            Sort
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Total Value</h3>
            <DollarSign className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">${totalValue.toLocaleString()}</p>
          <div className="flex items-center mt-2 text-green-500">
            <TrendingUp className="h-4 w-4 mr-1" />
            <span>+${totalGain.toLocaleString()} Total Return</span>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Average Return</h3>
            <Scale className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold text-green-500">+{averageGainPercent.toFixed(2)}%</p>
          <p className="text-sm text-gray-500 mt-2">Across all holdings</p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium">Storage Status</h3>
            <Warehouse className="h-5 w-5 text-primary-400" />
          </div>
          <p className="text-2xl font-bold">Secured</p>
          <p className="text-sm text-gray-500 mt-2">All holdings insured</p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium">Commodity Holdings</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Commodity/Type
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Purchase Price
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Current Price
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Storage Location
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Total Value
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Gain/Loss
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {commodityHoldings.map((holding) => (
                <tr key={holding.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="font-medium text-gray-900 dark:text-white">{holding.name}</div>
                      <div className="text-sm text-gray-500">{holding.type}</div>
                      <div className="text-xs text-gray-500">Form: {holding.form}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {holding.quantity} {holding.unit}
                    </span>
                    <div className="text-xs text-gray-500">Purity: {holding.purity}</div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${holding.purchasePrice.toLocaleString()}
                    </span>
                    <div className="text-xs text-gray-500">per {holding.unit}</div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${holding.currentPrice.toLocaleString()}
                    </span>
                    <div className="text-xs text-gray-500">per {holding.unit}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm text-gray-900 dark:text-white">{holding.location}</div>
                      <div className="text-xs text-gray-500">{holding.custodian}</div>
                      <div className="text-xs text-green-500">{holding.insuranceStatus}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      ${holding.totalValue.toLocaleString()}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <div className="flex flex-col items-end">
                      <div className="flex items-center text-green-500">
                        <TrendingUp className="h-4 w-4 mr-1" />
                        <span>${holding.gain.toLocaleString()}</span>
                      </div>
                      <span className="text-sm text-green-500">
                        +{holding.gainPercent.toFixed(2)}%
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CommoditiesPage;