import React from 'react';
import { Target, TrendingUp } from 'lucide-react';

const goals = [
  {
    id: '1',
    name: 'Emergency Fund',
    target: 25000,
    current: 15000,
    category: 'Savings',
  },
  {
    id: '2',
    name: 'Retirement',
    target: 1000000,
    current: 450000,
    category: 'Investment',
  },
  {
    id: '3',
    name: 'Home Down Payment',
    target: 100000,
    current: 65000,
    category: 'Savings',
  },
];

function GoalsProgress() {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium">Financial Goals</h3>
        <Target className="h-5 w-5 text-indigo-600" />
      </div>
      <div className="space-y-6">
        {goals.map((goal) => (
          <div key={goal.id}>
            <div className="flex justify-between mb-1">
              <div>
                <span className="text-sm font-medium text-gray-900">
                  {goal.name}
                </span>
                <span className="ml-2 text-sm text-gray-500">
                  {goal.category}
                </span>
              </div>
              <span className="text-sm font-medium text-gray-900">
                ${goal.current.toLocaleString()} / ${goal.target.toLocaleString()}
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-indigo-600 rounded-full h-2"
                style={{
                  width: `${(goal.current / goal.target) * 100}%`,
                }}
              />
            </div>
            <div className="mt-1 text-xs text-gray-500">
              {((goal.current / goal.target) * 100).toFixed(0)}% Complete
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GoalsProgress;