import { create } from 'zustand';

interface SearchResult {
  id: string;
  title: string;
  type: 'account' | 'transaction' | 'investment' | 'report' | 'goal' | 'tool' | 'property';
  description: string;
  link: string;
  toolId?: string;
}

interface SearchState {
  query: string;
  results: SearchResult[];
  isSearching: boolean;
  error: string | null;
  setQuery: (query: string) => void;
  search: (query: string) => void;
  clearSearch: () => void;
}

export const useSearchStore = create<SearchState>((set, get) => ({
  query: '',
  results: [],
  isSearching: false,
  error: null,

  setQuery: (query) => {
    set({ query });
    get().search(query);
  },

  search: async (query) => {
    if (!query.trim()) {
      set({ results: [], isSearching: false });
      return;
    }

    set({ isSearching: true });

    // Simulate API call with comprehensive mock data
    await new Promise(resolve => setTimeout(resolve, 300));

    const mockResults: SearchResult[] = [
      // Tools with specific routes
      {
        id: 'tool1',
        title: 'Loan Calculator',
        type: 'tool',
        description: 'Calculate loan payments and amortization',
        link: '/tools',
        toolId: 'loan'
      },
      {
        id: 'tool2',
        title: 'Investment Calculator',
        type: 'tool',
        description: 'Project investment growth and returns',
        link: '/tools',
        toolId: 'investment'
      },
      {
        id: 'tool3',
        title: 'Property Calculator',
        type: 'tool',
        description: 'Analyze real estate investments',
        link: '/tools',
        toolId: 'property'
      },
      {
        id: 'tool4',
        title: 'Tax Planner',
        type: 'tool',
        description: 'Estimate tax liability and plan deductions',
        link: '/tools',
        toolId: 'tax'
      },
      {
        id: 'tool5',
        title: 'Retirement Calculator',
        type: 'tool',
        description: 'Plan retirement savings and income',
        link: '/tools',
        toolId: 'retirement'
      },
      {
        id: 'tool6',
        title: 'Debt Payoff Calculator',
        type: 'tool',
        description: 'Create debt payoff strategy',
        link: '/tools',
        toolId: 'debt'
      },

      // Other results...
      {
        id: 'bank1',
        title: 'Chase Checking Account',
        type: 'account',
        description: 'Primary checking account with recent transactions',
        link: '/accounts/bank'
      },
      // ... rest of the mock data
    ].filter(result => 
      result.title.toLowerCase().includes(query.toLowerCase()) ||
      result.description.toLowerCase().includes(query.toLowerCase())
    );

    set({ results: mockResults, isSearching: false });
  },

  clearSearch: () => set({ query: '', results: [], isSearching: false, error: null }),
}));