import React from 'react';
import { useAdminStore } from '../../store/adminStore';
import { AlertTriangle, CheckCircle, XCircle } from 'lucide-react';

function CommunityModerationPage() {
  const { contentReports, reviewContent } = useAdminStore();

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Community Moderation</h1>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
        <div className="p-6">
          <div className="space-y-4">
            {contentReports.map((report) => (
              <div
                key={report.id}
                className="border border-gray-200 dark:border-gray-700 rounded-lg p-4"
              >
                <div className="flex items-start justify-between">
                  <div>
                    <div className="flex items-center">
                      <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2" />
                      <span className="font-medium">
                        {report.contentType.charAt(0).toUpperCase() + report.contentType.slice(1)} Report
                      </span>
                    </div>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                      {report.reason}
                    </p>
                    <p className="mt-2 text-xs text-gray-500">
                      Reported {new Date(report.timestamp).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => reviewContent(report.id, 'approve')}
                      className="p-2 text-green-600 hover:bg-green-50 rounded-full"
                    >
                      <CheckCircle className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => reviewContent(report.id, 'reject')}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-full"
                    >
                      <XCircle className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityModerationPage;