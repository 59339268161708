import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAdminStore } from '../../store/adminStore';
import { User, ArrowLeft } from 'lucide-react';
import Dashboard from '../Dashboard';
import AssetsPage from '../AssetsPage';
import BudgetPage from '../BudgetPage';
import InvestmentsPage from '../InvestmentsPage';
import RealEstatePage from '../RealEstatePage';
import GoalsPage from '../GoalsPage';
import EstatePlanningPage from '../EstatePlanningPage';
import CoursesPage from '../CoursesPage';
import MentorshipPage from '../MentorshipPage';
import CommunityPage from '../CommunityPage';
import { Routes, Route } from 'react-router-dom';

function UserViewPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { users } = useAdminStore();
  const user = users.find(u => u.id === userId);

  if (!user) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-center">
          <User className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <p className="text-gray-500">User not found</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Admin Control Bar */}
      <div className="sticky top-0 z-50 bg-primary-400 text-white px-4 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/admin/users')}
            className="flex items-center hover:bg-primary-500 rounded-lg px-3 py-1"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to Admin
          </button>
          <span className="ml-4">Viewing {user.name}'s Account</span>
        </div>
      </div>

      {/* User's Full Layout with Nested Routes */}
      <div className="flex">
        <div className="hidden lg:flex lg:flex-col lg:w-72 lg:fixed lg:inset-y-0 pt-16">
          <div className="flex flex-col flex-grow bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 pt-5 pb-4 overflow-y-auto">
            <nav className="mt-8 flex-1 px-3 space-y-2">
              <button
                onClick={() => navigate(`/admin/users/${userId}`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Dashboard
              </button>
              <button
                onClick={() => navigate(`/admin/users/${userId}/assets`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Assets
              </button>
              <button
                onClick={() => navigate(`/admin/users/${userId}/budget`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Budget
              </button>
              <button
                onClick={() => navigate(`/admin/users/${userId}/investments`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Investments
              </button>
              <button
                onClick={() => navigate(`/admin/users/${userId}/real-estate`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Real Estate
              </button>
              <button
                onClick={() => navigate(`/admin/users/${userId}/goals`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Goals
              </button>
              <button
                onClick={() => navigate(`/admin/users/${userId}/estate`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Estate Planning
              </button>
              <button
                onClick={() => navigate(`/admin/users/${userId}/courses`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Courses
              </button>
              <button
                onClick={() => navigate(`/admin/users/${userId}/mentorship`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Mentorship
              </button>
              <button
                onClick={() => navigate(`/admin/users/${userId}/community`)}
                className="w-full text-left px-4 py-3 text-sm font-medium rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Community
              </button>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72 flex-1">
          <main className="py-8 px-4 sm:px-6 lg:px-8">
            <Routes>
              <Route index element={<Dashboard viewingUserId={userId} />} />
              <Route path="assets" element={<AssetsPage viewingUserId={userId} />} />
              <Route path="budget" element={<BudgetPage viewingUserId={userId} />} />
              <Route path="investments" element={<InvestmentsPage viewingUserId={userId} />} />
              <Route path="real-estate" element={<RealEstatePage viewingUserId={userId} />} />
              <Route path="goals" element={<GoalsPage viewingUserId={userId} />} />
              <Route path="estate" element={<EstatePlanningPage viewingUserId={userId} />} />
              <Route path="courses" element={<CoursesPage viewingUserId={userId} />} />
              <Route path="mentorship" element={<MentorshipPage viewingUserId={userId} />} />
              <Route path="community" element={<CommunityPage viewingUserId={userId} />} />
            </Routes>
          </main>
        </div>
      </div>
    </div>
  );
}

export default UserViewPage;