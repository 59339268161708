import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Shield, QrCode, Smartphone } from "lucide-react";
import axios from "axios";
import { useAuthStore } from "../../store/authStore";
import { twoFactorVerifySchema, TwoFactorVerifyData } from "../../types/auth";

function TwoFactorSetup() {
  const [isSettingUp, setIsSettingUp] = useState(false);
  const {
    twoFactorSetup,
    setupTwoFactor,
    verifyTwoFactor,
    disableTwoFactor,
    user,
  } = useAuthStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TwoFactorVerifyData>({
    resolver: zodResolver(twoFactorVerifySchema),
  });

  const handleSetup = async () => {
    try {
      setIsSettingUp(true);

      const response = await fetch("/api/send-sms", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid: user.id, phoneNumber: user.phoneNumber }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to send SMS");
      }

      console.log("SMS sent successfully:", data);
    } catch (error) {
      console.error("Error sending SMS:", error.message);
    } finally {
      setIsSettingUp(false);
    }
  };

  const onVerify = async (data: TwoFactorVerifyData) => {
    try {
      await verifyTwoFactor(data.code);
      reset();
    } catch (error) {
      console.error("Failed to verify 2FA:", error);
    }
  };

  const handleDisable = async () => {
    try {
      await disableTwoFactor();
    } catch (error) {
      console.error("Failed to disable 2FA:", error);
    }
  };

  if (!twoFactorSetup) {
    return (
      <div>
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-sm font-medium text-gray-900 dark:text-white">
              Two-Factor Authentication
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Add an extra layer of security to your account
            </p>
          </div>
          <button
            onClick={handleSetup}
            disabled={isSettingUp}
            className="px-4 py-2 bg-primary-400 text-white rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2 disabled:opacity-50"
          >
            {isSettingUp ? "Setting up..." : "Enable 2FA"}
          </button>
        </div>
        <div id="recaptcha-container"></div>
      </div>
    );
  }

  if (!twoFactorSetup.verified) {
    return (
      <div className="space-y-6">
        <div>
          <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
            Set up Two-Factor Authentication
          </h3>
        </div>

        <form onSubmit={handleSubmit(onVerify)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Verification Code
            </label>
            <input
              type="text"
              {...register("code")}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-400 focus:ring-opacity-50"
              placeholder="Enter 6-digit code"
            />
            {errors.code && (
              <p className="mt-1 text-sm text-red-600">{errors.code.message}</p>
            )}
          </div>
          <div className="flex space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-primary-400 text-white rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
            >
              Verify
            </button>
            <button
              type="button"
              onClick={handleDisable}
              className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        <h3 className="text-sm font-medium text-gray-900 dark:text-white">
          Two-Factor Authentication
        </h3>
        <p className="text-sm text-green-600 dark:text-green-400">
          2FA is enabled and verified
        </p>
      </div>
      <button
        onClick={handleDisable}
        className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
      >
        Disable 2FA
      </button>
    </div>
  );
}

export default TwoFactorSetup;
