import React from 'react';
import { Building2, TrendingUp, MapPin, DollarSign, Home } from 'lucide-react';
import { useWealthStore } from '../../store/wealthStore';
import { format } from 'date-fns';

function RealEstateTracker() {
  const { realEstate } = useWealthStore();

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold">Real Estate Portfolio</h2>
        <Building2 className="h-6 w-6 text-indigo-600" />
      </div>

      <div className="space-y-6">
        {realEstate.length === 0 ? (
          <div className="text-center py-8">
            <Home className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">No properties added yet.</p>
            <button className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
              Add Property
            </button>
          </div>
        ) : (
          realEstate.map((property) => (
            <div key={property.id} className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-start justify-between mb-4">
                <div>
                  <h3 className="font-medium text-gray-900">{property.type === 'primary' ? 'Primary Residence' : property.type}</h3>
                  <div className="flex items-center text-gray-500 mt-1">
                    <MapPin className="h-4 w-4 mr-1" />
                    <span className="text-sm">{property.address}</span>
                  </div>
                </div>
                <div className="text-right">
                  <div className="flex items-center text-green-600">
                    <TrendingUp className="h-4 w-4 mr-1" />
                    <span className="text-sm font-medium">
                      {((property.currentValue - property.purchasePrice) / property.purchasePrice * 100).toFixed(1)}% gain
                    </span>
                  </div>
                  <p className="text-sm text-gray-500">since purchase</p>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <p className="text-sm text-gray-500">Current Value</p>
                  <p className="text-lg font-semibold text-gray-900">
                    ${property.currentValue.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Equity</p>
                  <p className="text-lg font-semibold text-gray-900">
                    ${property.equity.toLocaleString()}
                  </p>
                </div>
              </div>

              {property.type === 'investment' && (
                <div className="grid grid-cols-2 gap-4 pt-4 border-t border-gray-200">
                  <div>
                    <p className="text-sm text-gray-500">Monthly Income</p>
                    <p className="text-lg font-semibold text-green-600">
                      ${property.monthlyIncome?.toLocaleString() || 0}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Monthly Expenses</p>
                    <p className="text-lg font-semibold text-red-600">
                      ${property.monthlyExpenses?.toLocaleString() || 0}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default RealEstateTracker;