import React from 'react';
import GoalsProgress from '../components/dashboard/GoalsProgress';

function GoalsPage() {
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Financial Goals</h1>
      <GoalsProgress />
    </div>
  );
}

export default GoalsPage;