import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Building2, TrendingUp, DollarSign, Home } from 'lucide-react';
import DownloadButton from './DownloadButton';
import QualitativeInsights from './QualitativeInsights';

interface RealEstateReportProps {
  timeFrame: string;
}

function RealEstateReport({ timeFrame }: RealEstateReportProps) {
  const propertyValueData = [
    { month: 'Jan', value: 850000 },
    { month: 'Feb', value: 875000 },
    { month: 'Mar', value: 890000 },
    { month: 'Apr', value: 925000 },
  ];

  const portfolioBreakdown = [
    { name: 'Primary Residence', value: 550000 },
    { name: 'Rental Property 1', value: 250000 },
    { name: 'Rental Property 2', value: 125000 },
  ];

  const COLORS = ['#B100FF', '#10B981', '#F59E0B'];

  const insights = [
    {
      type: 'positive' as const,
      title: 'Strong Property Appreciation',
      description: 'Your real estate portfolio has appreciated by 8.8% YTD, outpacing the market average.',
    },
    {
      type: 'warning' as const,
      title: 'Rental Income Concentration',
      description: 'Consider diversifying rental income streams as 70% comes from a single property.',
    },
    {
      type: 'action' as const,
      title: 'Refinancing Opportunity',
      description: 'Current interest rates suggest potential savings through refinancing Property 1.',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Real Estate Analysis</h3>
        <DownloadButton reportId="real-estate-report" fileName="real-estate-analysis" />
      </div>
      
      <div id="real-estate-report" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Total Property Value</h4>
            <p className="text-2xl font-bold">$925,000</p>
            <div className="flex items-center mt-2 text-green-500">
              <TrendingUp className="h-4 w-4 mr-1" />
              <span>+8.8% YTD</span>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Monthly Rental Income</h4>
            <p className="text-2xl font-bold">$5,500</p>
            <p className="text-sm text-gray-500 mt-2">7.2% Cap Rate</p>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Total Equity</h4>
            <p className="text-2xl font-bold">$425,000</p>
            <p className="text-sm text-gray-500 mt-2">46% of portfolio value</p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Property Value Trend</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={propertyValueData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#B100FF" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Portfolio Breakdown</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={portfolioBreakdown}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {portfolioBreakdown.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="mt-4 space-y-2">
              {portfolioBreakdown.map((property, index) => (
                <div key={property.name} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      className="w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: COLORS[index % COLORS.length] }}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-300">{property.name}</span>
                  </div>
                  <span className="text-sm font-medium">${property.value.toLocaleString()}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Insights & Recommendations</h4>
          <QualitativeInsights insights={insights} />
        </div>
      </div>
    </div>
  );
}

export default RealEstateReport;