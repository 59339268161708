import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreditCard, TrendingUp, FileText, Target, Wrench } from 'lucide-react';

interface SearchResultsProps {
  results: Array<{
    id: string;
    title: string;
    type: 'account' | 'transaction' | 'investment' | 'report' | 'goal' | 'tool' | 'property';
    description: string;
    link: string;
    toolId?: string;
  }>;
  onResultClick: () => void;
}

function SearchResults({ results, onResultClick }: SearchResultsProps) {
  const navigate = useNavigate();

  const getIcon = (type: string) => {
    switch (type) {
      case 'account':
        return <CreditCard className="h-5 w-5 text-primary-400" />;
      case 'investment':
        return <TrendingUp className="h-5 w-5 text-green-500" />;
      case 'report':
        return <FileText className="h-5 w-5 text-blue-500" />;
      case 'goal':
        return <Target className="h-5 w-5 text-yellow-500" />;
      case 'tool':
        return <Wrench className="h-5 w-5 text-purple-500" />;
      default:
        return <FileText className="h-5 w-5 text-gray-400" />;
    }
  };

  const handleClick = (result: SearchResultsProps['results'][0]) => {
    // For tools, we need to set the active tool in the tools page
    if (result.type === 'tool' && result.toolId) {
      // Store the tool ID in session storage so the tools page knows which tool to show
      sessionStorage.setItem('activeToolId', result.toolId);
    }
    navigate(result.link);
    onResultClick();
  };

  return (
    <div className="absolute top-full left-0 right-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden z-50">
      {results.length === 0 ? (
        <div className="p-4 text-center text-gray-500 dark:text-gray-400">
          No results found
        </div>
      ) : (
        <div className="max-h-96 overflow-y-auto">
          {results.map((result) => (
            <button
              key={result.id}
              onClick={() => handleClick(result)}
              className="w-full text-left p-4 hover:bg-gray-50 dark:hover:bg-gray-700 border-b border-gray-200 dark:border-gray-700 last:border-0"
            >
              <div className="flex items-start">
                <div className="flex-shrink-0 mt-1">
                  {getIcon(result.type)}
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {result.title}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {result.description}
                  </p>
                </div>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchResults;