import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {
  Users,
  BookOpen,
  MessageCircle,
  Shield,
  LogOut,
  ClipboardList,
} from 'lucide-react';
import { useAuthStore } from '../../store/authStore';

function AdminLayout({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { logout } = useAuthStore();

  const navigation = [
    { name: 'Users', href: '/admin/users', icon: Users },
    { name: 'Client Portal', href: '/admin/clients', icon: ClipboardList },
    { name: 'Courses', href: '/admin/courses', icon: BookOpen },
    { name: 'Mentorship', href: '/admin/mentorship', icon: MessageCircle },
    { name: 'Community', href: '/admin/community', icon: Shield },
  ];

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="flex">
        {/* Sidebar */}
        <div className="hidden lg:flex lg:flex-col lg:w-72 lg:fixed lg:inset-y-0">
          <div className="flex flex-col flex-grow bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-6">
              <span className="text-xl font-bold text-primary-400">Admin Panel</span>
            </div>
            <nav className="mt-8 flex-1 px-3 space-y-2">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    `group flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors ${
                      isActive
                        ? 'bg-primary-50 dark:bg-primary-900 text-primary-400'
                        : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
                    }`
                  }
                >
                  <item.icon className="mr-3 h-5 w-5" />
                  {item.name}
                </NavLink>
              ))}
            </nav>
            <div className="mt-auto px-3">
              <button
                onClick={handleLogout}
                className="w-full group flex items-center px-4 py-2 text-sm font-medium rounded-lg text-red-600 hover:bg-red-50 dark:hover:bg-red-900/20"
              >
                <LogOut className="mr-3 h-5 w-5" />
                Logout
              </button>
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="lg:pl-72 flex-1">
          <main className="py-8 px-4 sm:px-6 lg:px-8">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;