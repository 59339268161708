import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { X, Plus, Trash2 } from 'lucide-react';
import { useCourseStore } from '../../store/courseStore';
import { Course } from '../../types/courses';

const courseSchema = z.object({
  title: z.string().min(2, 'Title must be at least 2 characters'),
  description: z.string().min(10, 'Description must be at least 10 characters'),
  thumbnail: z.string().url('Must be a valid URL'),
  duration: z.string(),
  category: z.enum(['investing', 'budgeting', 'real-estate', 'retirement', 'tax-planning']),
  instructor: z.object({
    name: z.string().min(2, 'Instructor name must be at least 2 characters'),
    title: z.string(),
    avatar: z.string().url('Must be a valid URL'),
    bio: z.string(),
  }),
  learningObjectives: z.array(z.string()).min(1, 'At least one learning objective is required'),
  requirements: z.array(z.string()),
  modules: z.array(z.object({
    title: z.string().min(2, 'Module title is required'),
    description: z.string(),
    lessons: z.array(z.object({
      title: z.string().min(2, 'Lesson title is required'),
      duration: z.string(),
      description: z.string(),
    })).min(1, 'At least one lesson is required'),
  })).min(1, 'At least one module is required'),
});

type CourseFormData = z.infer<typeof courseSchema>;

interface AddCourseModalProps {
  isOpen: boolean;
  onClose: () => void;
  editingCourse?: Course | null;
  onEdit?: () => void;
}

function AddCourseModal({ isOpen, onClose, editingCourse, onEdit }: AddCourseModalProps) {
  const { addCourse, updateCourse } = useCourseStore();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CourseFormData>({
    resolver: zodResolver(courseSchema),
    defaultValues: editingCourse || {
      learningObjectives: [''],
      requirements: [''],
      modules: [{
        title: '',
        description: '',
        lessons: [{
          title: '',
          duration: '',
          description: '',
        }],
      }],
    },
  });

  const {
    fields: objectiveFields,
    append: appendObjective,
    remove: removeObjective,
  } = useFieldArray({
    control,
    name: 'learningObjectives',
  });

  const {
    fields: requirementFields,
    append: appendRequirement,
    remove: removeRequirement,
  } = useFieldArray({
    control,
    name: 'requirements',
  });

  const {
    fields: moduleFields,
    append: appendModule,
    remove: removeModule,
  } = useFieldArray({
    control,
    name: 'modules',
  });

  const onSubmit = async (data: CourseFormData) => {
    try {
      if (editingCourse) {
        await updateCourse(editingCourse.id, {
          ...data,
          modules: data.modules.map((module, moduleIndex) => ({
            ...module,
            id: `m${moduleIndex + 1}`,
            lessons: module.lessons.map((lesson, lessonIndex) => ({
              ...lesson,
              id: `l${moduleIndex + 1}_${lessonIndex + 1}`,
              completed: false,
            })),
          })),
        });
        onEdit?.();
      } else {
        await addCourse({
          ...data,
          modules: data.modules.map((module, moduleIndex) => ({
            ...module,
            id: `m${moduleIndex + 1}`,
            lessons: module.lessons.map((lesson, lessonIndex) => ({
              ...lesson,
              id: `l${moduleIndex + 1}_${lessonIndex + 1}`,
              completed: false,
            })),
          })),
        });
      }
      reset();
      onClose();
    } catch (error) {
      console.error('Failed to handle course:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose} />
        
        <div className="relative w-full max-w-4xl rounded-lg bg-white dark:bg-gray-800 p-6 shadow-xl">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              {editingCourse ? 'Edit Course' : 'Add New Course'}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {/* Basic Information */}
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Title
                </label>
                <input
                  type="text"
                  {...register('title')}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
                {errors.title && (
                  <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Category
                </label>
                <select
                  {...register('category')}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                >
                  <option value="investing">Investing</option>
                  <option value="budgeting">Budgeting</option>
                  <option value="real-estate">Real Estate</option>
                  <option value="retirement">Retirement</option>
                  <option value="tax-planning">Tax Planning</option>
                </select>
                {errors.category && (
                  <p className="mt-1 text-sm text-red-600">{errors.category.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Description
              </label>
              <textarea
                {...register('description')}
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
              />
              {errors.description && (
                <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Thumbnail URL
                </label>
                <input
                  type="text"
                  {...register('thumbnail')}
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
                {errors.thumbnail && (
                  <p className="mt-1 text-sm text-red-600">{errors.thumbnail.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Duration
                </label>
                <input
                  type="text"
                  {...register('duration')}
                  placeholder="e.g., 2 hours"
                  className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                />
                {errors.duration && (
                  <p className="mt-1 text-sm text-red-600">{errors.duration.message}</p>
                )}
              </div>
            </div>

            {/* Instructor Information */}
            <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
              <h3 className="text-lg font-medium mb-4">Instructor Information</h3>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Name
                  </label>
                  <input
                    type="text"
                    {...register('instructor.name')}
                    className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Title
                  </label>
                  <input
                    type="text"
                    {...register('instructor.title')}
                    className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Avatar URL
                  </label>
                  <input
                    type="text"
                    {...register('instructor.avatar')}
                    className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Bio
                  </label>
                  <textarea
                    {...register('instructor.bio')}
                    rows={2}
                    className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                </div>
              </div>
            </div>

            {/* Learning Objectives */}
            <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Learning Objectives</h3>
                <button
                  type="button"
                  onClick={() => appendObjective('')}
                  className="text-primary-400 hover:text-primary-500"
                >
                  <Plus className="h-5 w-5" />
                </button>
              </div>
              {objectiveFields.map((field, index) => (
                <div key={field.id} className="flex items-center space-x-2 mb-2">
                  <input
                    type="text"
                    {...register(`learningObjectives.${index}`)}
                    className="flex-1 rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                  <button
                    type="button"
                    onClick={() => removeObjective(index)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>

            {/* Requirements */}
            <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Requirements</h3>
                <button
                  type="button"
                  onClick={() => appendRequirement('')}
                  className="text-primary-400 hover:text-primary-500"
                >
                  <Plus className="h-5 w-5" />
                </button>
              </div>
              {requirementFields.map((field, index) => (
                <div key={field.id} className="flex items-center space-x-2 mb-2">
                  <input
                    type="text"
                    {...register(`requirements.${index}`)}
                    className="flex-1 rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                  />
                  <button
                    type="button"
                    onClick={() => removeRequirement(index)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>

            {/* Modules */}
            <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Course Modules</h3>
                <button
                  type="button"
                  onClick={() => appendModule({
                    title: '',
                    description: '',
                    lessons: [{
                      title: '',
                      duration: '',
                      description: '',
                    }],
                  })}
                  className="text-primary-400 hover:text-primary-500"
                >
                  <Plus className="h-5 w-5" />
                </button>
              </div>
              {moduleFields.map((moduleField, moduleIndex) => (
                <div
                  key={moduleField.id}
                  className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 mb-4"
                >
                  <div className="flex items-center justify-between mb-4">
                    <h4 className="font-medium">Module {moduleIndex + 1}</h4>
                    <button
                      type="button"
                      onClick={() => removeModule(moduleIndex)}
                      className="text-red-500 hover:text-red-600"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Title
                      </label>
                      <input
                        type="text"
                        {...register(`modules.${moduleIndex}.title`)}
                        className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Description
                      </label>
                      <textarea
                        {...register(`modules.${moduleIndex}.description`)}
                        rows={2}
                        className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                      />
                    </div>

                    {/* Lessons */}
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <h5 className="text-sm font-medium">Lessons</h5>
                        <button
                          type="button"
                          onClick={() => {
                            const lessons = moduleFields[moduleIndex].lessons || [];
                            lessons.push({
                              title: '',
                              duration: '',
                              description: '',
                            });
                          }}
                          className="text-primary-400 hover:text-primary-500"
                        >
                          <Plus className="h-4 w-4" />
                        </button>
                      </div>

                      {moduleFields[moduleIndex].lessons?.map((lesson, lessonIndex) => (
                        <div
                          key={lessonIndex}
                          className="border border-gray-200 dark:border-gray-700 rounded p-3 mb-2"
                        >
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Lesson Title
                              </label>
                              <input
                                type="text"
                                {...register(`modules.${moduleIndex}.lessons.${lessonIndex}.title`)}
                                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                              />
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Duration
                              </label>
                              <input
                                type="text"
                                {...register(`modules.${moduleIndex}.lessons.${lessonIndex}.duration`)}
                                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                              />
                            </div>
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                              Description
                            </label>
                            <textarea
                              {...register(`modules.${moduleIndex}.lessons.${lessonIndex}.description`)}
                              rows={2}
                              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-gray-900 dark:text-white focus:border-primary-400 focus:ring-primary-400 dark:bg-gray-700"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md disabled:opacity-50"
              >
                {isSubmitting ? (editingCourse ? 'Updating...' : 'Adding...') : (editingCourse ? 'Update Course' : 'Add Course')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCourseModal;