import { z } from 'zod';

export interface TwoFactorSetup {
  secret: string;
  qrCode: string;
  verified: boolean;
}

export const twoFactorVerifySchema = z.object({
  code: z.string().length(6, 'Code must be 6 digits'),
});

export type TwoFactorVerifyData = z.infer<typeof twoFactorVerifySchema>;